<template>
    <div>
        <div>
          <van-nav-bar
              title="修改密码"
              @click-left="$router.go(-1)"
              fixed
              :placeholder="true"
              left-arrow/>
          <div>
            <van-form>
              <van-field
                  v-model="oldPwd"
                  type="password"
                  name="原密码"
                  label="原密码"
                  placeholder="请输入6-12位密码"
                  maxlength="12"
                  :rules="[{ pattern, required: true, message: '密码为6-12位' }]"/>
              <van-field
                  v-model="newPwd"
                  type="password"
                  name="新密码"
                  label="新密码"
                  placeholder="请输入6-12位密码"
                  maxlength="12"
                  :rules="[{ pattern, required: true, message: '密码为6-12位' }]"/>
              <van-field
                  v-model="newPwd2"
                  type="password"
                  name="确认新密码"
                  label="确认新密码"
                  placeholder="请输入6-12位密码"
                  maxlength="12"
                  :rules="[
                      { pattern, required: true, message: '请确认两次密码一致' },
                      { validator: verifyPwd, required: true, message: '请确认两次密码一致' }
                      ]"/>
              <div style="margin: 16px;">
                <van-button block color="#667eea" type="default" @click="editPwd">确认修改</van-button>
              </div>
            </van-form>
          </div>
        </div>
    </div>
</template>

<script>
import {changePwd} from "@/api/request";
import crypto from "@/utils/crypto";

export default {
  name: "mobileModPassword",
  data(){
    return{
      userId: '',
      oldPwd: '',
      newPwd: '',
      newPwd2: '',
      pattern: /^.{6,12}$/,
    }
  },
  mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.userId = user.userId;
  },
  methods:{
    editPwd(){
      if(this.newPwd2 === this.newPwd){
        let params = {
          userId: this.userId,
          userPwd: crypto.encrypt(this.oldPwd),
          newPwd: crypto.encrypt(this.newPwd)
        }
        changePwd(params).then( res => {
          if(res.data.success){
            this.$toast.success(res.data.data)
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            this.$router.replace("/mobile/account")
          }else {
            this.$toast.fail(res.data.error.message);
          }
        })
      }
    },
    verifyPwd(){
      if(this.newPwd2 !== this.newPwd){
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>