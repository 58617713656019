<template>
    <div>
        <div class="max-w-screen-xl mx-auto my-0">
            <div class="w-44 bg-white float-left rounded-md mt-6">
                <ul class="px-10 pt-6">
                    <li v-for="(item,index) in newsMenu" class="w-64 h-15">
                        <span class="block hover:text-indigo-500 cursor-pointer" :class="{ sideMenu: index === current }" @click="clickNewsMenu(index)">
                            <i :class="item.icon"/>
                            <span v-text="item.menu" class="px-3" ></span>
                        </span>
                    </li>
                </ul>
            </div>
            <div style="width: 1064px" class="bg-white float-left rounded-md ml-10 mt-6">
              <!-- 新闻公告 -->
              <div v-show="Section1" class="news-box">
                <ul>
                  <li v-for="item in newsData" class="h-40 mb-4 relative">
                    <span class="cursor-pointer">
                        <span @click="toDetails(item.content_id)">
                            <img class="w-64 h-40 float-left" :src="item.view_img_url">
                        </span>
                        <table class="relative left-8">
                            <tr>
                                <th class="hover:text-indigo-500 text-xl">
                                    <span @click="toDetails(item.content_id)">{{ item.content_name }}</span>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <p style="width: 680px;" class="text-justify text-sm text-gray-700">{{ item.content_des }}</p>
                                </td>
                            </tr>
                        </table>
                        <span class="text-gray-500 text-sm absolute bottom-1 right-1">
                            <span>发表于：</span>
                            <span>{{ item.add_time.substring(0,10) }}</span>
                        </span>
                    </span>
                  </li>
                </ul>
                <!--分页区-->
                <div class="paging">
                  <div class="mx-auto my-7 w-1/3">
                    <el-pagination
                        @current-change="newsNotice"
                        background
                        :page-size= "news.pageSize"
                        :current-page="news.currentIndex"
                        layout="total, prev, pager, next"
                        :total="news.total">
                    </el-pagination>
                  </div>
                </div>
              </div>
              <!-- 精彩活动 -->
              <div v-show="Section2" class="news-box">
                <ul>
                  <li v-for="(item,index) in activityData" :key="index" class="h-40 mb-4 relative">
                    <span class="cursor-pointer">
                        <span @click="toDetails(item.content_id)">
                            <img class="w-64 h-40 float-left" :src="item.view_img_url">
                        </span>
                        <table class="relative left-8">
                            <tr>
                                <th class="hover:text-indigo-500 text-xl">
                                    <span @click="toDetails(item.content_id)">{{ item.content_name }}</span>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <p style="width: 680px;" class="text-justify text-sm text-gray-700">
                                        {{ item.content_des }}
                                    </p>
                                </td>
                            </tr>
                        </table>
                        <span class="text-gray-500 text-sm absolute bottom-1 right-1">
                            <span>发表于：</span>
                            <span>{{ item.add_time.substring(0,10) }}</span>
                        </span>
                    </span>
                  </li>
                </ul>
                <!--分页区-->
                <div class="paging">
                  <div class="mx-auto my-7 w-1/3">
                    <el-pagination
                        @current-change="getActivity"
                        background
                        :page-size= "activity.pageSize"
                        :current-page="activity.currentIndex"
                        layout="total, prev, pager, next"
                        :total="activity.total">
                    </el-pagination>
                  </div>
                </div>
              </div>
              <!-- 游戏攻略 -->
              <div v-show="Section3" class="news-box">
                <ul>
                  <li v-for="(item,index) in guideDate" :key="index" class="h-40 mb-4 relative">
                    <span class="cursor-pointer">
                      <span @click="toDetails(item.content_id)">
                         <img class="w-64 h-40 float-left" :src="item.view_img_url">
                      </span>
                      <table class="relative left-8">
                          <tr>
                              <th class="hover:text-indigo-500 text-xl">
                                <span @click="toDetails(item.content_id)">
                                  <span>{{ item.content_name }}</span>
                                </span>
                              </th>
                          </tr>
                          <tr>
                              <td>
                                  <p style="width: 680px;" class="text-justify text-sm text-gray-700">
                                      {{ item.content_des }}
                                  </p>
                              </td>
                          </tr>
                      </table>
                      <span class="text-gray-500 text-sm absolute bottom-1 right-1">
                          <span>发表于：</span>
                          <span>{{ item.add_time.substring(0,10) }}</span>
                      </span>
                    </span>
                  </li>
                </ul>
                <!--分页区-->
                <div class="paging">
                  <div class="mx-auto my-7 w-1/3">
                    <el-pagination
                        @current-change="change"
                        background
                        :page-size= "strategy.pageSize"
                        :current-page="strategy.currentIndex"
                        layout="total, prev, pager, next"
                        :total="strategy.total">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getGameGuide, getActivity, getNewsNotice} from '@/api/request'

export default {
    name: "news",
    data(){
        return{
            current: 0,
            Section1: false,
            Section2: true,
            Section3: false,
            newsMenu:[
                // {menu: '新闻公告',path: ''},
                {menu: '精彩活动',path: ''},
                {menu: '游戏攻略',path: ''},
            ],
            newsData:[],
            activityData:[],
            guideDate:[],
            news:{
              total: 0,
              pageSize: 4,
              currentIndex: 1,
            },
            activity:{
              total: 0,
              pageSize: 4,
              currentIndex: 1,
            },
            strategy:{
              total: 0,
              pageSize: 4,
              currentIndex: 1,
            },
            query:{
                contentId: ''
            }
        }
    },
  mounted() {
      this.loadData(1);
  },
  methods:{
      loadData(index){
          this.getActivity(index);
      },
      newsNotice(index){
        let params = {
          pageNo: index,
          pageSize: this.news.pageSize,
        }
        getNewsNotice(params).then( res => {
          this.newsData = res.data.list;
          this.news.currentIndex = index;
          this.news.total = res.data.total;
        })

      },
      change(index){
        let params = {
          pageNo: index,
          pageSize: this.strategy.pageSize,
        }
        getGameGuide(params).then( res => {
          this.guideDate = res.data.list;
          this.strategy.currentIndex = index;
          this.strategy.total = res.data.total;
        })
      },
      getActivity(index){
        let params = {
          pageNo: index,
          pageSize: this.news.pageSize,
        }
        getActivity(params).then( res => {
          this.activityData = res.data.list;
          this.activity.total = res.data.total;
          this.activity.currentIndex = index;
        })
      },
      toDetails(contentId){
        this.$router.push({name: 'NewsInfo',params:{contentId: contentId}})
      },
      clickNewsMenu(index){
          this.current = index;
          // if(index === 0){
          //   for(let i = 0;i <= 3;i++){
          //     this['Section' + i] = false
          //     this.Section1 = true;
          //   }
          //   this.newsNotice(1);
          // }else 
        if(index === 0){
            for(let i = 0;i <= 3;i++){
                this['Section' + i] = false
                this.Section2 = true;
            }
            this.getActivity(1);
          }else if(index === 1){

            for(let i = 0;i <= 3;i++){
              this['Section' + i] = false
              this.Section3 = true;
            }
            this.change(1);
          }
      },
    }
}
</script>

<style scoped>

</style>
<style>
  .change{
    @apply bg-gray-400 text-gray-600 rounded-b-md text-center;
    @apply hover:underline cursor-pointer;
  }
</style>