<template>
    <div>
        <div class="py-5 px-7 relative">
            <div class="text-lg text-gray-700 pb-3 border-b border-gray-500">关于我们</div>
            <div>
                <p class="text-justify mt-4" style="text-indent: 2em">
                  长沙七里互娱网络科技有限公司
                  于2019年成立（简称“七里网络”）， 公司以"聚焦玩家，重拾快乐"为理念，
                  依托七里网络自身强大的研发能力、丰富的运营经验和专业的服务能力三大核心竞争力。
                  主营游戏领域的技术开发、技术转让、技术服务以及广告发布等，服务于各类大型网游“斗罗大陆”“怒火一刀”等知名H5，
                  通过各大直播平台对新手玩家进行游戏指导与游戏攻略输出，同时本公司提供账号管理、充值、客服等服务，致力于网聚精品游戏的同时，
                  打造轻松和谐的在线娱乐王国。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "about"
}
</script>

<style scoped>

</style>