<template>
    <div>
        <div class="login-box">
            <span class="absolute left-6 top-4 cursor-pointer text-gray-600 hover:underline"
                  @click="$router.push('/home')"><i class="el-icon-back mr-1"/>返回首页</span>
            <div class=" py-14 px-36">
                <div class="text-center">
                    <span class="text-3xl font-bold">找回密码</span>
                </div>
                    <!-- 输入框 -->
                    <div style="width: 310px" class="mt-12 relative">
                        <input v-model="inputData.userName" type="text" class="input" placeholder="请输入账号,6-12位"/>
                        <p ref="userName" class="absolute text-red-600 text-sm"></p>

                        <input v-model="inputData.password"type="password" class="input mt-6"
                               placeholder="请输入密码,6-12位"/>
                        <p ref="pwd" class="absolute text-red-600 text-sm"></p>

                        <input v-model="inputData.confirmPwd" type="password" class="input mt-6"
                               placeholder="请再次输入密码,6-12位"/>
                        <p ref="pwd2" class="absolute text-red-600 text-sm"></p>

                        <input v-model="inputData.phone" type="text" maxlength="11" class="input mt-6" placeholder="请输入手机号"/>
                        <p ref="phone" class="absolute text-red-600 text-sm"></p>

                        <input v-model="inputData.verify" type="text" maxlength="6" class="input mt-6 mb-6"
                               placeholder="请输入6位验证码"/>
                        <button @click="sendVerifyCode" v-show="btnBool" type="button" class="absolute text-blue-500" style="bottom: 135px;right: 40px">获取验证码</button>
                        <span class="absolute right-28 text-blue-500" style="bottom: 135px;right: 40px" v-if="!btnBool">{{ countDown }}</span>
                        <div class="mt-6">
                            <button @click="toReset" type="button" class="login">确认重置</button>
                        </div>
                        <div class="text-center text-sm mt-2">
                            <span>如有疑问，请</span>
                            <a href="/service" class="text-blue-500">联系客服</a>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import crypto from "@/utils/crypto";
import {doReset, sendResetPwdVerifyCode} from "@/api/request";

export default {
    name: "register",
    data() {
        return {
            inputData: {
                userName: '',
                password: '',
                confirmPwd: '',
                phone: '',
                verify: ''
            },
            countDown: '',
            btnBool: true,
            regPhone: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
        }
    },
    methods: {
        toReset(){
            if(!/^\w{6,12}$/.test(this.inputData.userName.trim())){
                this.$refs.userName.style.display = 'block';
                this.$refs.userName.innerText = '账号为6-12位字母数字和下划线组成';
            }else if(!/^.{6,12}$/.test(this.inputData.password.trim())){
                this.$refs.pwd.style.display = 'block';
                this.$refs.pwd.innerText = '密码为6-12位';
            }else if(!/^.{6,12}$/.test(this.inputData.confirmPwd.trim())){
                this.$refs.pwd2.style.display = 'block';
                this.$refs.pwd2.innerText = '密码长度为6-12位';
            }else if(this.inputData.confirmPwd.trim() !== this.inputData.password.trim()){
                this.$refs.pwd2.innerText = '两次密码不一致';
            }else if(!this.regPhone.test(this.inputData.phone)){
                this.$refs.phone.style.display = 'block';
                this.$refs.phone.innerText = '请输入正确的手机号';
            } else {
                this.$refs.userName.style.display = 'none';
                this.$refs.pwd.style.display = 'none';
                this.$refs.pwd2.style.display = 'none';
                this.$refs.phone.style.display = 'none';
                let params = {
                    userName: this.inputData.userName,
                    userPwd: crypto.encrypt(this.inputData.password),
                    verifyCode: this.inputData.verify,
                    phone: this.inputData.phone
                }
                doReset(params).then( res => {
                    if(res.data.success){
                        alert("修改成功");
                        this.$router.push('/home')
                        this.inputData = {
                            userName: '',
                            pwd: '',
                            pwd2: '',
                            verify: '',
                            phone: ''
                        }
                    }else {
                        alert(res.data.error.message);
                    }
                })
            }
        },
        sendVerifyCode(){
            let phone = this.regPhone.test(this.inputData.phone);
            if(phone){
                let params = {
                    phone: this.inputData.phone,
                    userName: this.inputData.userName
                }
                sendResetPwdVerifyCode(params).then( res => {
                    if(res.data.success) {
                        let time = 120;
                        let timer = setInterval(() => {
                            this.btnBool = false;
                            this.countDown = time + "s";
                            time--;
                            if (time < 0) {
                                this.btnBool = true;
                                clearInterval(timer);
                            }
                        }, 1000);
                    }else {
                        alert(res.data.error.message)
                    }
                }).catch( () => {})
                this.$refs.phone.style.display = 'none';
            }else {
                this.$refs.phone.style.display = 'block';
            }
        },
    }
}
</script>

<style scoped>

</style>