<template>
    <div>
        <div class="py-5 px-7 relative">
            <div class="text-lg text-gray-700 pb-3 border-b border-gray-500">我的礼包</div>
            <div class="mt-5" id="gift-con">
              <div v-for="item in tableData" class="myGift-box p-5">
                  <div class="border-b border-gray-400 h-26" style="width: 278px;margin: 0">
                      <img :src="item.game_icon" class="w-24 h-24 float-left">
                      <ul class="relative left-4 top-3 text-xs">
                          <li>
                            <span>{{ item.game_name }}</span>&nbsp;
                            <span>{{ item.gift_name }}</span>
                          </li>
                          <li>
                            <span>兑换码：</span>
                            <span class="select-text">
                              {{ item.gift_code }}
                            </span>
                          </li>
                      </ul>
                  </div>
                  <div>
                      <span class="text-sm">【礼包内容】</span>
                      <p class="text-xs pl-2">{{ item.gift_content }}</p>
                  </div>
              </div>
            </div>
          <div v-if="tableData.length == 0 ? true : false" class="text-center text-xl text-indigo-500" style="line-height: 400px">还没有领取过礼包哦</div>
          <div class="clear"></div>
        </div>
        <!--分页区-->
        <div style="width: 728px;padding: 5px 0px 20px 0px;" class="h-24 bg-white my-0 mx-auto rounded-lg">
          <div class="mx-auto my-7 w-1/3">
            <el-pagination
                @current-change="loadData"
                background
                :page-size= "size"
                :current-page="currentIndex"
                layout="total, prev, pager, next"
                :total="total">
            </el-pagination>
          </div>
        </div>
    </div>
</template>

<script>
import { getMyGift } from '@/api/request'

export default {
  name: "myGift",
  data(){
      return{
        currentIndex: 1,
        size: 4,
        total:0,
        userId: '',
        tableData:[]
      }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.userId = user.userId;
    this.loadData(1);
  },
  methods:{
    loadData(index){
        let params = {
          pageNo: index,
          pageSize: this.size,
          params: {
            userId: this.userId,
          }
        }
        getMyGift(params).then( res => {
            this.tableData = res.data.list;
            this.currentIndex = index;
            this.total = res.data.total;
        })
      }
    }
}
</script>

<style>
    #gift-con div:nth-child(odd){
        @apply mr-8 mb-6;
    }
</style>