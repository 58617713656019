<template>
    <div>
        <div>
          <van-nav-bar
              title="BT游戏"
              fixed
              placeholder
              @click-left="$router.go(-1)"
              left-arrow/>
          <van-search v-model="value" show-action shape="round" placeholder="请输入搜索关键词" @search="onRefresh">
            <template #action>
              <div @click="onRefresh">搜索</div>
            </template>
          </van-search>
          <div class="px-3 bg-white relative">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="我也是有底线的"
                  @load="onLoad">
                <van-cell v-for="(item,index) in pageData" :key="index" @click="toDetails(item.game_id)">
                  <img :src="item.game_icon" class="w-16 h-16 float-left">
                  <div class="absolute left-20 leading-normal">
                    <div class="text-md truncate w-38">{{ item.game_name }}</div>
                    <div class="text-xs w-28 truncate text-gray-500">{{ item.game_desc }}</div>
                    <div class="hotTag w-30 truncate">
                      <span v-for="(tag,index) in item.tag.split(',')" :key="index" v-if="tag">{{ tag }}</span>
                    </div>
                  </div>
                  <div>
                    <van-button size="small" class="border-indigo-500 absolute top-5 right-2 text-indigo-500 rounded-md w-14" plain>下载</van-button>
                  </div>
                </van-cell>
              </van-list>
            </van-pull-refresh>
          </div>
        </div>
    </div>
</template>

<script>
import { queryBTGame } from "@/api/request";

export default {
  name: "shouYou",
  data(){
    return{
      value: '',
      pageData: [],
      pageNo: 1,
      pageSize: 10,
      loading: false, // 当loading为true时，转圈圈
      finished: false, // 数据是否请求结束，结束会先显示-我也是我底线的
      refreshing: false,
    }
  },
  methods: {
    loadData() {
      if (this.refreshing) {
        this.pageData = [];
        this.refreshing = false;
      }
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        params: {
          game_name: this.value,
        }
      }
      queryBTGame(params).then( res => {
        if(res.data.list.length != 0){
          this.loading = false;
          this.pageData = this.pageData.concat(res.data.list);
          this.pageNo++;
        }
        //如果加载完毕，显示没有更多
        if(res.data.list.length == 0){
          this.finished = true;
        }
      })
    },
    onLoad(){
      setTimeout(() => {
        this.loadData();
        this.loading = true;
      },500)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageData = [];
      this.pageNo = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    toDetails(gameId){
      sessionStorage.setItem('gameId',gameId);
      this.$router.push('/mobile/gameDetails');
    },
  }
}
</script>

<style scoped>

</style>