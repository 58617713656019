<template>
    <div>
        <div class="py-5 px-7 relative">
            <div class="text-lg text-gray-700 pb-3 border-b border-gray-500">我的游戏</div>
            <div class="mt-6">
                <table class="mx-5">
                    <tr v-for="item in tableData">
                        <td class="py-5">
                            <img class="w-24 h-24" :src="item.game_icon"/>
                        </td>
                        <td class="py-5" id="type">
                            <ul class="mx-4">
                                <li>
                                    <span class="font-bold" v-text="item.game_name"></span>
                                </li>
                                <li>
                                    <span class="bg-gray-400 text-black text-xs px-1 rounded-sm" v-if="type"
                                          v-for="type in item.tag.split(',')" v-text="type"></span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
                <div v-if="tableData.length == 0 ? true : false" class="text-center text-xl text-indigo-500"
                     style="line-height: 400px">还没有玩过游戏
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <!--分页区-->
        <div style="width: 728px;padding: 5px 0px 20px 0px;" class="h-24 bg-white my-0 mx-auto rounded-lg">
            <div class="mx-auto my-7 w-1/3">
                <el-pagination
                    @current-change="loadData"
                    background
                    :page-size="size"
                    :current-page="currentIndex"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {getMyGame} from '@/api/request'

export default {
    name: "myGame",
    data() {
        return {
            currentIndex: 1,
            size: 3,
            total: 0,
            userId: '',
            tableData: []
        }
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem("userInfo"));
        this.userId = user.userId;
        this.loadData(1);
    },
    methods: {
        loadData(index) {
            let params = {
                pageNo: index,
                pageSize: this.size,
                params: {
                    userId: this.userId
                }
            }
            getMyGame(params).then(res => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.currentIndex = index;
            })
        },
    }
}
</script>

<style>
#type ul li:nth-child(2) span:nth-child(2) {
    @apply mx-1;
}
</style>