<template>
    <div class="py-5 px-7 relative">
        <div class="text-lg text-gray-700 pb-3 border-b border-gray-500">用户协议</div>
        <div class="iframeBox">
            <iframe
                id="iframe"
                ref="iframe"
                src="https://game.7li.cn/static/sdk/user.html"
                scrolling="no"
                frameborder="0"
                width="100%"
                height="4800"
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "user",
}
</script>
<style scoped>

</style>
<style>
    .iframeBox{
        @apply mt-6;
    }
</style>