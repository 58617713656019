<template>
    <div>
        <div class="">
          <van-nav-bar
              title="意见反馈"
              @click-left="$router.go(-1)"
              left-arrow/>
          <div class="px-3">
              <p>请填写您的意见：</p>
              <van-field
                  v-model="msg"
                  rows="5"
                  autosize
                  type="textarea"
                  maxlength="200"
                  placeholder="请输入您的意见"
                  show-word-limit
                  style="background-color: #f5f5f5;border-radius: 0.5rem"/>
            <p>请输入您的联系方式：</p>
            <van-field v-model="value" style="background-color: #f5f5f5;border-radius: 4px" placeholder="请输入手机号、QQ、邮箱等" />
            <van-button block round color="#667eea" class="mt-6">提交</van-button>
          </div>

        </div>
    </div>
</template>

<script>
export default {
  name: "opinion",
  data(){
    return{
      msg: '',
      value: '',
    }
  },
}
</script>

<style scoped>

</style>
<style>
    .van-field__control{

    }
</style>