import { render, staticRenderFns } from "./security.vue?vue&type=template&id=4ed4c0db&scoped=true&"
import script from "./security.vue?vue&type=script&lang=js&"
export * from "./security.vue?vue&type=script&lang=js&"
import style0 from "./security.vue?vue&type=style&index=0&id=4ed4c0db&prod&scoped=true&lang=css&"
import style1 from "./security.vue?vue&type=style&index=1&id=4ed4c0db&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed4c0db",
  null
  
)

export default component.exports