<template>
    <div>
      <div class="bg-white">
        <van-nav-bar
            title="热门游戏"
            fixed
            placeholder
            @click-left="$router.go(-1)"
            left-arrow/>
        <div class="text-gray-700 text-xl font-bold pt-3 px-7">
          <span>热游推荐</span>
        </div>
        <div class="px-3 bg-white relative">
            <van-cell v-for="(item,index) in pageData" :key="index" @click="toDetails(item.game_id)">
              <img :src="item.game_icon" class="w-16 h-16 float-left">
              <div class="absolute left-20 leading-normal">
                <div class="text-base">{{ item.game_name }}</div>
                <div class="text-xs w-38 truncate text-gray-500">{{ item.game_desc }}</div>
                <div class="hotTag">
                  <span v-for="(tag,index) in item.tag.split(',')" :key="index" v-if="tag">{{ tag }}</span>
                </div>
              </div>
              <div>
                <van-button size="small" class="border-indigo-500 absolute top-5 right-2 text-indigo-500 rounded-md w-14" plain>下载</van-button>
              </div>
            </van-cell>
        </div>
      </div>
    </div>
</template>

<script>
import {getHotGameList} from "@/api/request";

export default {
  name: "played",
  data(){
    return{
      pageData: [],
    }
  },
  mounted() {
      this.loadData();
  },
  methods: {
    loadData() {
      getHotGameList().then( res => {
        if(res.data.success){
          this.pageData = res.data.data;
        }else {
          this.$toast.fail(res.data.error)
        }
      })
    },
    toDetails(gameId){
      sessionStorage.setItem('gameId',gameId);
      this.$router.push('/mobile/gameDetails');
    },
  }
}
</script>

<style scoped>

</style>
<style>


</style>