<template>
    <div>
        <div>
            <van-nav-bar
                title="APP下载"
                placeholder
                fixed
                @click-left="$router.go(-1)"
                left-arrow/>
                <div class="my-6">
                    <van-image src="https://qili-data.oss-cn-qingdao.aliyuncs.com/image/jyqzxxpg/logo.png" width="100" height="100" class="block" style="margin: 10px auto"/>
                </div>
                <div class="block" style="margin: 10px auto;width: 8.25rem;height: 7.125rem">
                    <van-button @click="download(1)" :icon="require('@/static/images/az.png')" color="#FF7043" class="iconSize">
                        <span style="display:inline-block;">下载安卓版</span>
                    </van-button>
                    <br/><br/>
                    <van-button @click="download(2)" :icon="require('@/static/images/pg.png')" color="#FF7043" class="iconSize">
                        <span>下载苹果版</span>
                    </van-button>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    name: "software",
    methods:{
        download(i){
            if(i === 1) {
                let apk = 'https://qili-data.oss-cn-qingdao.aliyuncs.com/jyqzxxpg/1/qiliyouxiapp.apk'
                location.href = apk;
            }else if(i === 2){
                let a = 'https://apps.apple.com/cn/app/%E4%B8%83%E9%87%8C%E6%B8%B8%E6%88%8F/id1620672705'
                window.open(a)
            }
        }
    }
}
</script>

<style scoped>

</style>
<style>
    .iconSize .van-button__icon{
        font-size: 26px;
    }
</style>