<template>
    <div v-cloak class="my-6">
        <!--导航栏-->
        <div class="nav">
            <div class="p-10 text-md">
                <div class="my-4">
                    <span>运营平台：</span>
                    <span class="pl-8" v-for="(item,index) in PhoneSystem">
                        <a href="javascript:" @click="clickPlatform(item,index)" class="condition"
                           :class="{ active:index == platformCurrent }" name="activePlatform">{{ item }}</a>
                    </span>
                </div>
                <div class="my-4">
                    <span>游戏类型：</span>
                    <span class="pl-8" v-for="(type,index) in gameType">
                        <a href="javascript:" @click="clickType(type,index)" class="condition"
                           :class="{ active:index == typeCurrent }" name="type">{{ type }}</a>
                    </span>
                </div>
                <div class="letter">
                    <span>游戏首字母：</span>
                    <span class="pl-4" v-for="(zm,index) in letter">
                        <a href="javascript:" @click="clickLetter(zm,index)" class="condition"
                           :class="{ active:index == letterCurrent }" name="letter">{{ zm }}</a>
                    </span>
                </div>
            </div>
            <div class="search top-6 right-16">
                <input type="text" v-model="query.gameName" v-on:keyup.enter="loadData(1)" placeholder="搜索游戏"/>
                <i @click="loadData(1)" class="el-icon-search cursor-pointer absolute right-2" style="top: 33px"/>
            </div>
        </div>
        <!--内容区-->
        <div class="phone">
            <div v-show="show" class="text-3xl font-bold text-center my-38">暂无游戏</div>
            <ul class="phone-games">
                <li v-for="item in pageData" class="phoneList">
                    <a href="javascript:" @click="toDetail(item.game_id)" class="float-left absolute">
                        <img :src="item.game_icon" class="w-30 h-30">
                    </a>
                    <a href="javascript:" @click="toDetail(item.game_id)"
                       class="ml-6 float-left absolute left-30">{{ item.game_name }}</a>
                    <table class="phone-table left-36 top-14">
                        <tr>
                            <td class="t1">版本：</td>
                            <td class="t2">
                                <img v-if="item.game_system === 1" src="../../../static/images/Android.png"
                                     class="w-5 h-5 inline-block">
                                <img v-if="item.game_system === 2" src="../../../static/images/IOS.png"
                                     class="w-5 h-5 inline-block">
                                <span v-if="item.game_system === 3">
                                  <img src="../../../static/images/Android.png" class="w-5 h-5 inline-block">
                                  <img src="../../../static/images/IOS.png" class="w-5 h-5 inline-block">
                              </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="t1">类型：</td>
                            <td class="t2">
                                <span v-for="(tag,index) in item.tag.split(',')" :key="index" v-if="tag"> {{tag}} </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" class="text-gray-700 ">
                                <p class="overflow-hidden truncate" style="width: 230px">{{ item.game_desc }}</p>
                            </td>
                        </tr>
                    </table>
                    <div class="w-33 h-26 float-left absolute right-11 top-12 z-30">
                        <a href="javascript:">
                            <span class="game-info" @click="toDetail(item.game_id)">游戏详情</span>
                        </a>
                        <div class="mt-4">
                            <button @click="toDetail(item.game_id)" class="download-game" type="button">下载游戏</button>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="clear"></div>
        </div>
        <!--分页区-->
        <div class="paging relative bottom-0">
            <div class="mx-auto my-7 w-1/3">
                <el-pagination
                    @current-change="loadData"
                    background
                    :page-size="16"
                    :current-page="currentIndex"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import {getGameList} from "@/api/request"

export default {
    name: "mobileGame",
    data() {
        return {
            show: false,
            master: true,
            platformCurrent: 0,
            typeCurrent: 0,
            letterCurrent: 0,
            currentIndex: 1,
            total: 0,
            PhoneSystem: ['不限', 'Android', 'IOS'],
            gameType: ['不限', '卡牌', '传奇', '武侠', '角色', '养成', '三国', '奇迹', 'BT'],
            letter: ['不限', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            pageData: [],
            query: {
                gameName: '',
                gameSystem: '',
                gameTopic: '',
                gameShortName: '',
            }
        }
    },
    mounted() {
        this.loadData(1)
    },
    methods: {
        loadData(index) {
            let param = {
                pageNo: index,
                pageSize: 16,
                params: {
                    game_name: this.query.gameName,
                    game_system: this.query.gameSystem,
                    game_topic: this.query.gameTopic,
                    game_short_name: this.query.gameShortName
                }
            }
            getGameList(param).then(res => {
                this.pageData = res.data.list;
                if(this.pageData.length == 0){
                    this.show = true;
                }else {
                    this.show = false;
                }
                this.total = res.data.total;
                this.currentIndex = index;
            })
        },
        clickPlatform(system, index) {
            this.platformCurrent = index;
            let ret = "";
            if (system === 'Android') {
                ret = 1;
            } else if (system === 'IOS') {
                ret = 2;
            } else if (system === '不限') {
                ret = '';
            }
            this.query.gameSystem = ret;
            this.loadData(1);
        },
        clickType(type, index) {
            this.typeCurrent = index;
            this.query.gameTopic = this.formatTopic(type);
            this.loadData(1);
        },
        clickLetter(zm, index) {
            if (zm == '不限') {
                zm = '';
            }
            this.letterCurrent = index;
            this.query.gameShortName = zm.toLowerCase();
            this.loadData(1);
        },
        download(link) {
            window.location.href = link;
        },
        toDetail(id) {
            this.$router.push({name: 'MgDetails', params: {id: id}})
            sessionStorage.setItem("gameId", id)
        },
        formatTopic(type) {
            let ret = "";
            if (type === '卡牌') {
                ret = 1;
            } else if (type === '传奇') {
                ret = 2;
            } else if (type === '武侠') {
                ret = 3;
            } else if (type === '角色') {
                ret = 4
            } else if (type === '养成') {
                ret = 5;
            } else if (type === '三国') {
                ret = 6;
            } else if (type === '奇迹') {
                ret = 7;
            } else if (type === 'BT') {
                ret = 8;
            } else if (type === '不限') {
                ret = '';
            }
            return ret;
        },
        format(topic) {
            let ret = "";
            if (topic === 1) {
                ret = '卡牌';
            } else if (topic === 2) {
                ret = '传奇';
            } else if (topic === 3) {
                ret = '武侠';
            } else if (topic === 4) {
                ret = '角色';
            } else if (topic === 5) {
                ret = '养成';
            } else if (topic === 6) {
                ret = '三国';
            } else if (topic === 7) {
                ret = '奇迹';
            } else if (topic === 8) {
                ret = 'BT';
            }
            return ret;
        },
    }
}
</script>

<style scoped>
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
    @apply bg-indigo-500 text-white !important;
}

.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    @apply rounded-md !important;
    @apply hover:text-indigo-500 !important;
}

[v-cloak] {
    display: none;
    height: 1300px;
}
</style>