<template>
    <div>
        <div class="bg-gray-800 h-48 px-5 relative">
            <div class="max-w-screen-xl mx-auto my-0 pt-30 relative">
                <p class="text-5xl text-white w-48 border-b-5 border-indigo-500">搜索结果</p>
            </div>
            <div class="max-w-screen-xl mx-auto my-0 relative" >
                <div class="search bottom-28 right-84">
                    <input type="text" v-model="gameName" v-on:keyup.enter="loadData(1)" placeholder="搜索游戏"/>
                    <i @click="loadData(1)" class="el-icon-search cursor-pointer absolute right-2" style="top: 33px"/>
                </div>
            </div>
        </div>
        <!-- 搜索内容区 -->
        <div class="max-w-screen-xl mx-auto my-0 mt-10 relative">
            <div class="bg-white float-left rounded-lg" style="width: 940px;padding: 0 25px 5px 25px;">
                <div v-for="item in tableData" class="h-48 leading-8 overflow-hidden border-b border-gray-300">
                    <a href="javascript:" @click="toDetails(item.game_id,item.game_type)" class="hover:text-indigo-500">
                        <img :src="item.game_icon" class="w-24 h-24 float-left mt-10 rounded-lg">
                        <span class="text-xl relative left-6 top-4">{{ item.game_name }}</span>
                    </a>
                    <table class="relative left-6 top-3">
                        <tr>
                            <td>
                                <p class="text-sm text-justify h-24 break-all overflow-ellipsis overflow-hidden"
                                   style="width: 500px"> {{ item.game_desc }}</p>
                            </td>
                        </tr>
                        <tr class="text-sm">
                            <td class="text-gray-400">
                                <span class="pr-1" v-for="tag in item.tag.split(',')">{{ tag }}</span>
                                <span class="px-2">{{ item.status }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button @click="toDetails(item.game_id,item.game_type)"
                                        class="gift-bag search-down absolute top-7 -right-64">下载游戏
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- 热游推荐-->
            <div class="relative bg-white float-left ml-6 rounded-lg" style="width: 310px">
                <div class="inline-block mt-4">
                    <i class="w-2 h-3 bg-red-700">&nbsp;</i>
                    <span class="text-lg m-4 font-bold">热游推荐</span>
                </div>
                <div class="mt-2">
                    <ul>
                        <li v-for="(item,index) in hotGameList" class="h-36 py-3.5 px-6">
                            <div class="h-8" style="line-height: 107px">
                                <span class="text-sm">{{ index + 1 }}</span>
                            </div>
                            <div class="relative left-4 float-left pr-3" style="top: -15px">
                                <img :src="item.game_icon" class="w-20 h-20 rounded-lg"/>
                            </div>
                            <table class="relative left-4 -top-2 leading-8">
                                <tr>
                                    <td>
                                        <span class="hover:text-indigo-500 cursor-pointer">{{ item.game_name }}</span>
                                    </td>
                                </tr>
                                <tr class="text-xs text-gray-900">
                                    <div class="w-30 truncate">
                                        <span class="pr-1" v-for="tag in item.tag.split(',')">{{ tag }}</span>
                                    </div>
                                </tr>
                            </table>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="clear"></div>
            <!--分页区-->
            <div class="h-24 bg-white mt-6 rounded-lg" style="width:940px;padding: 5px 25px 20px 25px">
                <div class="mx-auto my-7 w-1/3">
                    <el-pagination
                        @current-change="loadData"
                        background
                        :page-size="pageSize"
                        :current-page="currentIndex"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getSearchResult,getHotGameList} from '@/api/request'

export default {
    name: "searchResult",
    data() {
        return {
            currentIndex: 1,
            total: 0,
            pageSize: 20,
            gameName: '',
            tableData: [],
            hotGameList:[]
        }
    },
    mounted() {
        this.gameName = this.$route.params.val;
        this.loadData(1);
    },
    methods: {
        loadData(index) {
            let params = {
                pageNo: index,
                pageSize: this.pageSize,
                params: {
                    game_name: this.gameName
                }
            }
            getSearchResult(params).then(res => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.currentIndex = index;
            })
            this.hotList();
        },
        hotList(){
            getHotGameList().then(res => {
                this.hotGameList = res.data.data;
            })
        },
        toDetails(gameId, gameType) {
            sessionStorage.setItem("gameId", gameId)
            if (gameType === 1) {
                this.$router.push({name: 'H5Details'})
            } else if (gameType === 2) {
                this.$router.push({name: 'MgDetails'})
            }
        }
    }
}
</script>

<style scoped>

</style>