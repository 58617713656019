import axios from './axios'
/*登录*/
export async function doLogin(params = {}){
  return axios({
    url: `/user/login/toLogin`,
    method: 'post',
    data: params,
  })
}

/*退出登录*/
export async function logout(){
  return axios({
    url: `/user/login/logout`,
    method: 'post',
  })
}

/*注册*/
export async function doRegister(params = {}){
  return axios({
    url: `/user/register`,
    method: 'post',
    data: params
  })
}

/*忘记密码*/
export async function doReset(params = {}){
  return axios({
    url: `/user/doReset`,
    method: 'post',
    data: params
  })
}

/*发送找回密码验证码*/
export async function sendResetPwdVerifyCode(params = {}){
  return axios({
    url: `/user/sendResetPwdVerifyCode`,
    method: 'post',
    data: params
  })
}


export async function getUserInfo(userName){
  return axios({
    url: `/user/getUserInfo/${userName}`,
    method: 'get'
  })
}

export async function getMyGame(params = {}){
  return axios({
    url: `/user/getMyGame`,
    method: 'post',
    data: params
  })
}

/*编辑资料*/
export async function saveEdit(params = {}){
  return axios({
    url: `/user/saveEdit`,
    method: 'post',
    data: params,
  })
}

export async function saveHeaderIcon(params = {}){
    return axios({
        url: `/user/saveHeaderIcon`,
        method: 'post',
        data: params,
    })
}

/*发送绑定手机验证码*/
export async function sendBindPhoneVerifyCode(params = {}){
  return axios({
    url: `/user/sendVerifyCode/?phone=${params}`,
    method:'get',
    data: params
  })
}

/*绑定手机号*/
export async function bindPhone(params = {}){
  return axios({
    url: `/user/bindPhone`,
    method: 'post',
    data: params
  })
}

/*解绑手机号*/
export async function unBindPhone(params = {}){
  return axios({
    url: `/user/unBindPhone`,
    method: 'post',
    data: params
  })
}

/*发送解绑手机号验证码*/
export async function sendUnbindPhoneVerifyCode(params = {}){
  return axios({
    url: `/user/sendUnbindVerifyCode/?phone=${params}`,
    method: 'get',
    data: params
  })
}

/*更新实名认证*/
export async function updateAuth(params = {}){
  return axios({
    url: `/user/updateAuth`,
    method: 'post',
    data: params
  })
}

/*修改密码*/
export async function changePwd(params = {}){
  return axios({
    url: `/user/changePwd`,
    method: 'post',
    data: params
  })
}


/*我的礼包*/
export async function getMyGift(params = {}){
  return axios({
    url: `/user/getMyGift`,
    method: 'post',
    data: params
  })
}

export async function getGameList (params = {}) {
  return axios({
    url: `/game/getMobileGame`,
    method: 'post',
    data: params
  })
}

export async function queryGame(gameId){
  return axios({
    url: `/game/queryGame/${gameId}`,
    method: 'get',
  })
}

export async function queryList(){
    return axios({
        url: `/home/strategy`,
        method: 'post'
    })
}

export async function getHomePageData(){
    return axios({
        url: `/home/getAll`,
        method: 'post',
    })
}

export async function getH5List(params = {}){
  return axios({
    url: `/game/getH5Game`,
    method: 'post',
    data: params
  })
}

export async function queryH5Game(gameId){
  return axios({
    url: `/game/query/${gameId}`,
    method: 'get',
  })
}

export async function getNewsNotice(params = {}){
    return axios({
        url: `/content/newsNotice`,
        method: 'post',
        data: params
    })
}

export async function getGameGuide(params = {}){
  return axios({
    url: `/content/gameGuide`,
    method: 'post',
    data: params
  })
}

export async function getActivity(params = {}){
  return axios({
    url: `/content/activity`,
    method: 'post',
    data: params
  })
}

export async function getHomeStrategy(){
  return axios({
    url: `/news/strategy`,
    method: 'get'
  })
}

export async function getGift(params = {}){
  return axios({
    url: `/gift/getGift`,
    method: 'post',
    data: params
  })
}

export async function receiveGift(params = {}){
  return axios({
    url: `/gift/receiveGift`,
    method: 'post',
    data: params
  })
}

export async function getGameServer(){
    return axios({
        url: `/gameServer/getOpenServer`,
        method: 'post'
    })
}

export async function getSearchResult(params = {}){
    return axios({
        url: `/game/getSearchResult`,
        method: 'post',
        data: params
    })
}

export async function getStrategy(contentId){
    return axios({
        url: `/content/getStrategy/${contentId}`,
        method: 'get',
    })
}

export async function queryGameServer(params = {}){
    return axios({
        url: `/gameServer/list`,
        method: 'post',
        data: params
    })
}

/****************************手机端********************************/
export async function queryMobileHome(){
    return axios({
        url: `/home/queryMobile`,
        method: 'post',
    })
}

export async function queryGameClass(params = {}){
    return axios({
        url: `/game/queryGameClass`,
        method: 'post',
        data: params
    })
}

export async function queryBTGame(params = {}){
    return axios({
        url: `/game/getBT`,
        method: 'post',
        data: params
    })
}

export async function queryGameActivity(){
    return axios({
        url: `/content/gameActivity`,
        method: 'post',
    })
}

export async function queryMobileOpenServer(params = {}){
    return axios({
        url: `/gameServer/todayOpenServer`,
        method: 'post',
        data: params
    })
}

export async function queryTomorrowOpenServer(params = {}){
    return axios({
        url: `/gameServer/tomorrowOpenServer`,
        method: 'post',
        data: params
    })
}

export async function getHotGame(){
    return axios({
        url: `/home/hotGame`,
        method: 'post'
    })
}

export async function getGameDetails(params = {}){
    return axios({
        url: `/game/gameDetails`,
        method: 'post',
        data: params
    })
}

export async function getHotGameList(){
    return axios({
        url: `/game/hotGame`,
        method: 'post',
    })
}


/************************************selfPay*************************************/
//玩家自助充值  根据用户名查询游戏列表
export async function getPlayedGameList (params = {}) {
    return axios({
        url: `https://gameapi.7li.cn/open/order/getPlayedGameList`,
        method: 'post',
        data: params
    })
}

//玩家自助充值  充值
export async function qlOrder (params = {}) {
    return axios({
        url: `https://gameapi.7li.cn/open/order/qlOrder`,
        method: 'post',
        data: params
    })
}