<template>
    <div>
        <div class="relative">
<!--            <div class="w-full">
                <van-nav-bar fixed placeholder title="分类"/>
            </div>-->
            <!--左侧菜单-->
            <div class="relative">
                <van-sidebar @change="onChange" v-model="activeKey" class="element fixed overflow-scroll"
                             style="height: calc(100% - 5.7%);">
                    <van-sidebar-item v-for="(item,index) in gameType" :key="index" :title="item"
                                      style="padding-bottom: 20.8px;padding-top: 20.6px"/>
                </van-sidebar>
            </div>
            <!--右侧菜单-->
            <div style="width: 80vw" class="float-right overflow-hidden relative">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        :offset="150"
                        loading-text="拼命加载中..."
                        finished-text="没有更多了"
                        @load="onLoad">
                        <van-cell v-for="(item,index) in pageData" :key="index" @click="toDetails(item.game_id)">
                            <img :src="item.game_icon" class="w-16 h-16 float-left">
                            <div class="absolute left-20 leading-normal">
                                <div class="text-md w-28 truncate">{{ item.game_name }}</div>
                                <div class="text-xs w-28 truncate text-gray-500">{{ item.game_desc }}</div>
                                <div class="hotTag w-30 truncate">
                                    <span v-for="(tag,index) in item.tag.split(',')" :key="index" v-if="tag">{{tag}}</span>
                                </div>
                            </div>
                            <div>
                                <van-button size="small"
                                            class="border-indigo-500 absolute top-5 right-2 text-indigo-500 rounded-md w-14"
                                            plain>下载
                                </van-button>
                            </div>
                        </van-cell>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>

<script>
import {queryGameClass} from '@/api/request'

export default {
    name: "mobileGame",
    data() {
        return {
            activeKey: 0,
            pageData: [],
            pageNo: 1,
            pageSize: 10,
            index: 0,
            loading: false, // 当loading为true时，转圈圈
            finished: false, // 数据是否请求结束，结束会先显示-没有更多了
            refreshing: false,
            gameType: ['推荐', '卡牌', '传奇', '武侠', '角色', '养成', '三国', '奇迹', 'BT'],
        }
    },
    mounted() {
        this.index = this.$route.params.index;
        this.activeKey = this.index;
    },
    methods: {
        loadData() {
            if (this.refreshing) {
                this.pageData = [];
                this.refreshing = false;
            }
            let params = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                params: {
                    gameTopic: this.index,
                }
            }
            queryGameClass(params).then(res => {
                if (res.data.list.length !== 0) {
                    this.loading = false;
                    this.pageData = this.pageData.concat(res.data.list);
                    this.pageNo++;
                }
                //如果加载完毕，显示没有更多
                if (res.data.list.length === 0) {
                    this.finished = true;
                }
            })
        },
        onLoad() {
            setTimeout(() => {
                this.loadData();
                this.loading = true;
            }, 500)
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.pageData = [];
            this.pageNo = 1;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.loadData();
        },
        onChange(index) {
            this.index = index;
            this.onRefresh();
        },
        toDetails(gameId) {
            sessionStorage.setItem('gameId', gameId);
            this.$router.push('/mobile/gameDetails');
        },
        formatTopic(type) {
            let ret = "";
            if (type === '卡牌') {
                ret = 1;
            } else if (type === '传奇') {
                ret = 2;
            } else if (type === '武侠') {
                ret = 3;
            } else if (type === '角色') {
                ret = 4
            } else if (type === '养成') {
                ret = 5;
            } else if (type === '三国') {
                ret = 6;
            } else if (type === '奇迹') {
                ret = 7;
            } else if (type === 'BT') {
                ret = 8;
            } else if (type === '不限') {
                ret = '';
            }
            return ret;
        },
    },
}
</script>

<style scoped>

</style>
<style>
.element::-webkit-scrollbar {
    width: 0 !important;
}

.element {
    -ms-overflow-style: none;
}

.element {
    overflow: -moz-scrollbars-none;
}

.van-sidebar-item--select::before {
    @apply bg-indigo-500 !important;
}

.van-nav-bar .van-icon {
    @apply text-gray-500 !important;
}

.van-nav-bar__text {
    @apply text-gray-500 !important;
}
.van-nav-bar__left{
    padding: 0 !important;
}
</style>