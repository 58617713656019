<template>
    <div v-if="pageData.game">
        <div>
            <van-nav-bar left-arrow @click-left="$router.go(-1)" fixed placeholder title="游戏详情"/>
            <!--            <van-image src="https://img01.yzcdn.cn/vant/apple-1.jpg" height="150" width="100%"/>-->
            <div class="relative px-3 border-b border-solid py-4">
                <div>
                    <img :src="pageData.game.game_icon" class="w-18 h-18 float-left">
                    <ul>
                        <li class="absolute left-24 top-4">
                            <p>{{ pageData.game.game_name }}</p>
                            <div class="hotTag">
                                <span class="text-xs mr-1 rounded-sm px-1 py-0.25"
                                      v-for="(item,index) in pageData.game.tag.split(',')" v-if="item"
                                      :key="index">{{ item }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="clear"></div>
            </div>
            <div class="px-3 pt-4 relative">
                <div>
                    <ul class="text-gray-500">
                        <li class="float-left mr-6" :class="{act: index === current}" v-for="(item,index) in text"
                            @click="click(index)">{{ item }}
                        </li>
                    </ul>
                    <div class="clear"></div>
                </div>
                <!-- 详情 -->
                <div v-show="particulars">
                    <div class="text-justify h-50">
                        <p class="text-gray-800 my-2">游戏简介</p>
                        <p class="text-xs text-gray-700" style="text-indent: 2em">{{ pageData.game.game_desc }}</p>
                    </div>
                    <div class="my-2" v-if="pageData.detailVideo">
                        <p class="text-gray-800">游戏视频</p>
                        <div class="mt-2" style="width: 351px;height: 200px">
                            <video :src="pageData.detailVideo.url" :poster="pageData.detailVideo.view_img_url"
                                   style="width: 100%;height: 100%;object-fit: contain"
                                   controls disablePictureInPicture controlslist="noplaybackrate" id="video"></video>
                        </div>
                    </div>
                </div>
                <!-- 开服 -->
                <div v-show="service">
                    <div class="relative">
                        <ul>
                            <li v-if="pageData.gameServer.length != 0"
                                class="grid grid-cols-3 gap-5 text-center mt-3 text-xs"
                                v-for="(item,index) in pageData.gameServer" :key="index">
                                <span>{{ item.game_name }}</span>
                                <span>{{ item.server_name }}</span>
                                <span>{{ item.open_time.substring(5, 16) }}</span>
                            </li>
                            <li v-if="pageData.gameServer.length == 0">
                                <span class="absolute top-50 text-center text-gray-700 w-full">暂无开服信息</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 礼包 -->
                <div v-show="gift" class="mb-16">
                    <div class="relative">
                        <ul>
                            <li class="relative my-2" v-if="pageData.gift.length != 0"
                                v-for="(item,index) in pageData.gift" :key="index">
                                <img :src="item.game_icon" class="w-16 h-16">
                                <div class="absolute bottom-2 left-18">
                                    <p>{{ item.gift_name }}</p>
                                    <p class="w-46 truncate text-sm">{{ item.gift_content }}</p>
                                </div>
                                <button class="absolute right-0 bottom-2 bg-indigo-500 py-1 px-3 text-white rounded-md"
                                        @click="receive(item.gift_id)">领取
                                </button>
                            </li>
                            <li v-if="pageData.gift.length == 0">
                                <span class="absolute top-50 text-center text-gray-700 w-full">暂无礼包信息</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <!--底部-->
            <div class="h-14">
                <div class="fixed bottom-0 w-full bg-white h-14 ">
                    <div class=" border-t border-solid px-6">
                        <span class="float-left" @click="show = true">
                            <van-icon name="service-o" color="#667eea" size="1.25rem" class="mt-1"
                                      style="margin-left: 0.40rem"/>
                            <p>客服</p>
                        </span>
<!--                        <button class="float-right bg-indigo-500 mt-3 px-1 py-1 rounded-sm text-white"
                                @click="download(pageData.game.game_app_url)" v-if="pageData.game.game_type === 2">下载游戏
                        </button>-->
                        <a href="javascript:" v-if="showAndroid" @click="download" class="float-right bg-indigo-500 mt-3 px-1 py-1 rounded-sm text-white inline-block">
                            <div>
                                <img src="../../../static/images/az.png" class="w-5 h-5 inline-block mb-1 mr-2">
                                <span>下载</span>
                            </div>
                        </a>
                        <a href="javascript:" v-if="showIos" @click="download" :class="{aDisable: iosDisabled}" class="float-right bg-indigo-500 mt-3 mr-2 px-1 py-1 rounded-sm text-white inline-block">
                            <div>
                                <img src="../../../static/images/pg.png" class="w-5 h-5 inline-block mb-1 mr-2">
                                <span>下载</span>
                            </div>
                        </a>
                        <a href="javascript:" v-if="showInstall" @click="goGuide" class="float-right bg-orange-500 mt-3 mr-2 px-1 py-1 rounded-sm text-white inline-block">
                            <div>
                                <span>去安装证书</span>
                            </div>
                        </a>
                        <button class="float-right bg-indigo-500 mt-3 mr-2 px-1 py-1 rounded-sm text-white"
                                @click="toGame(pageData.game.game_url)" v-if="pageData.game.game_type === 1">开始游戏
                        </button>
                        <button class="float-right bg-indigo-500 mt-3 mr-2 px-1 py-1 rounded-sm text-white"
                                @click="toGame(pageData.game.game_url)"
                                v-if="pageData.game.game_id === 206 || pageData.game.game_id == 253">开始游戏
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <van-action-sheet v-model="show" title="客服" class="px-4">
            <div>
                <span>客服QQ：</span>
                <span class="select-text">3008618195</span>
            </div>
            <div class="content">
                <van-image lazy-load src="https://game.7li.cn/img/qili-qrcode.31a32cb1.png"
                           class="w-24 h-24 float-left"/>
                <div class="text-gray-500 pl-28 pt-6">
                    <p>
                        公众号搜索"七里网络"
                    </p>
                    <p>找在线客服</p>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import {getGameDetails, receiveGift} from "@/api/request";

export default {
    name: "gameDetails",
    data() {
        return {
            text: ['详情', '开服', '礼包'],
            current: 0,
            swipeCurrent: 0,
            particulars: true,
            service: false,
            gift: false,
            active: 0,
            show: false,
            pageData: [],
            gameId: '',
            showAndroid: false,
            showIos: false,
            showInstall: false,
            iosUrlToSet: 'https://game.7li.cn/static/download/toset.mobileprovision',
            androidUrl: "",
            iosUrl: "",
            iosDisabled: false,
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            let gameId = sessionStorage.getItem("gameId");
            this.gameId = gameId;
            let params = {
                game_id: gameId
            }
            getGameDetails(params).then(res => {
                if(res.data.success){
                    this.pageData = res.data.data;
                    this.androidUrl = res.data.data.game.androidUrl;
                    this.iosUrl = res.data.data.game.iosUrl;
                    this.judgmentSystem();
                }else {
                    this.$toast.fail(res.data.error.message);
                    this.$router.replace("/mobile/game");
                }
            }).catch(() => {
            })
        },
        receive(giftId) {
            let user = JSON.parse(localStorage.getItem("userInfo"));
            if (user != null) {
                let params = {
                    userId: user.userId,
                    giftId: giftId,
                    userName: user.userName,
                    gameId: this.gameId
                }
                receiveGift(params).then(res => {
                    if (res.data.data != null) {
                        this.$dialog.alert({
                            message: "礼包码：" + res.data.data,
                        }).then(() => {
                        });
                    } else {
                        this.$toast.fail(res.data.error.message + "");
                    }
                })
            } else {
                this.$toast.fail("请先登录")
            }
        },
        toGame(url) {
            let token = localStorage.getItem("token");
            if (token != null) {
                let username = localStorage.getItem("username");
                let password = localStorage.getItem("password");
                //let loginUrl = 'http://192.168.0.9:8081/#/play/1/138?from=qlWebsite&u=' + username + '&p=' + password
                let loginUrl = url + "?from=qlWebsite&u="+ username + "&p=" + password
                window.open(loginUrl, '_self')
            } else {
                this.$toast.fail("您还未登录，请先登录")
                this.$router.replace("/mobile/login")
            }
        },
        judgmentSystem(){
            switch (this.pageData.game.game_system){
                case 1: 
                    this.showAndroid = true;
                    break;
                case 2: 
                    this.showIos = true;
                    if(!this.iosUrl){
                        this.iosDisabled = true;
                    }
                    break;
                case 3:
                    this.showAndroid = true;
                    this.showIos = true;
                    if(!this.iosUrl){
                        this.iosDisabled = true;
                    }
                    break;
            }
        },
        download() {
            if (this.isMobile()) {
                let ua = navigator.userAgent.toLowerCase();
                if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                    if(this.pageData.game.game_type === 2){
                        window.location.href = this.iosUrl;
                        return;
                    }
                    this.$toast({
                        message: "点击允许后，再点击去安装证书，找到 对应游戏文件 点击安装，输入锁屏密码，安装之后，在桌面进行查看",
                        position: 'top',
                        duration: -1
                    });
                    window.location.href = this.iosUrl;
                    setTimeout(() => {
                        this.showIos = false;
                        this.showInstall = true;
                    }, 2000)
                } else {
                    window.location.href = this.androidUrl;
                }
            }else {
                window.location.href = this.androidUrl;
            }
        },
        click(index) {
            this.current = index;

            if (this.current === 0) {
                this.particulars = true;
                this.service = false;
                this.gift = false;
            } else if (this.current === 1) {
                this.service = true;
                this.particulars = false;
                this.gift = false;
            } else if (this.current === 2) {
                this.particulars = false;
                this.service = false;
                this.gift = true;
            }
        },
        onChange(index) {
            this.swipeCurrent = index;
        },
        goGuide(){
            window.location.href = this.iosUrlToSet;
        },
        isMobile() {
            let browser = {
                versions: function () {
                    let u = navigator.userAgent, app = navigator.appVersion;
                    return {
                        trident: u.indexOf('Trident') > -1, //IE内核
                        presto: u.indexOf('Presto') > -1, //opera内核
                        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
                        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
                        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                        iPad: u.indexOf('iPad') > -1, //是否iPad
                        webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
                        weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
                        qq: u.match(/\sQQ/i) == " qq" //是否QQ
                    };
                }(),
                language: (navigator.browserLanguage || navigator.language).toLowerCase()
            }
            return browser.versions.mobile || browser.versions.android || browser.versions.ios;
        },
    }
}
</script>

<style scoped>

</style>
<style>
.act {
    @apply transform scale-125 text-indigo-500;
}

.content {
    padding: 16px 0 50px;
}

.welfare-box {
    white-space: pre-line;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    @apply text-sm text-justify;
}
.aDisable{
    pointer-events: none;
    background-color: #7c7c7c !important;
}
</style>