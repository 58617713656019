<template>
    <div>
        <div class="relative">
            <van-nav-bar
                title="个人信息"
                @click-left="$router.go(-1)"
                left-arrow/>
            <div>
                <van-uploader :max-count="1"
                              accept="image/jpg, image/jpeg ,image/png"
                              :after-read="afterRead"
                              :before-read="beforeAvatarUpload"
                              style="margin-left: 41%;margin-top: 0.5rem;">
                    <template #default>
                        <van-image
                            width="4.375rem"
                            height="4.375rem"
                            fit="fill"
                            lazy-load
                            :src="userData.img_url"
                            round/>
                    </template>
                </van-uploader>
                <div>
                </div>
                <van-cell title="账号" :value="userData.user_name"/>
                <van-cell title="昵称" is-link>
                    <template #default>
                        <span>{{ userData.nick_name }}</span>
                        <span class="text-blue-500 pl-4" @click="revamp = true">修改</span>
                    </template>
                </van-cell>
                <van-cell-group>
                    <van-cell title="绑定手机" is-link @click="phone = true">
                        <template #default>
                            <span class="custom-title text-blue-500" v-if="isBind">未绑定</span>
                            <span class="custom-title text-blue-500" v-if="!isBind">已绑定</span>
                        </template>
                    </van-cell>
                    <van-cell title="实名认证" is-link @click="auth = true">
                        <template #default>
                            <span class="custom-title text-blue-500" v-if="isAuth">去认证</span>
                            <span class="custom-title text-blue-500" v-if="!isAuth">已认证</span>
                        </template>
                    </van-cell>
                </van-cell-group>
                <div class="relative -bottom-72">
                    <van-button type="default" @click="toLogout" size="large" :round="true">
                        <span class="text-red-600">退出登录</span>
                    </van-button>
                </div>
            </div>
        </div>
        <!-- 修改昵称 -->
        <van-action-sheet v-model="revamp" title="修改" class="pb-4 bg-white">
            <van-field v-model="nickName" placeholder="请输入新昵称"/>
            <van-button block round color="#667eea" @click="saveNickName">修改</van-button>
        </van-action-sheet>
        <!-- 绑定手机 -->
        <van-action-sheet v-model="phone" title="绑定手机" class="pb-4 bg-white">
            <span v-if="!cutFun" class="text-sm">如果当前手机号码不再使用，请及时解绑并绑定新手机号码</span>
            <van-form>
                <van-field
                    v-model="bind.tel"
                    label="手机号："
                    name="tel"
                    maxlength="11"
                    placeholder="请输入手机号"
                    :rules="[{ pattern, required: true, message: '请输入正确输入正确的手机号' }]"/>
                <van-field
                    v-model="bind.verifyCode"
                    type="text"
                    name="验证码"
                    label="验证码"
                    placeholder="请入验证码"
                    maxlength="6"
                    :rules="[{ required: true, message: '请填写验证码' }]">
                    <template #extra>
                        <span class="text-blue-500" v-if="btnBool"
                              @click="cutFun ? sendVerifyCode() : sendUnBindVerifyCode()">发送验证码</span>
                        <span class="absolute right-5 top-2 text-blue-500" v-if="!btnBool">{{ countDown }}</span>
                    </template>
                </van-field>
                <van-button block round color="#667eea" class="mt-3" @click="cutFun ? handleBind() : handleUnBind()">
                    确认
                </van-button>
            </van-form>
        </van-action-sheet>
        <!-- 实名认证 -->
        <van-action-sheet v-model="auth" title="实名认证" class="pb-4 bg-white">
            <van-form>
                <van-field
                    v-model="realName"
                    label="真实姓名："
                    name="name"
                    placeholder="请输入真实姓名"
                    :rules="[{ validator:verifyRealName ,required: true, message: '请输入真实姓名' }]"/>
                <van-field
                    v-model="cardNo"
                    name="sfz"
                    label="身份证号："
                    placeholder="请输入身份证号"
                    maxlength="18"
                    :rules="[{ validator, required: true, message: '请输入正确的身份证号' }]"/>
                <van-button round block color="#667eea" class="mt-3" @click="handleUpdateAuth" v-if="isShow">立即认证
                </van-button>
            </van-form>
        </van-action-sheet>
    </div>
</template>

<script>
import {
    bindPhone,
    getUserInfo,
    logout, saveEdit,
    sendBindPhoneVerifyCode,
    sendUnbindPhoneVerifyCode,
    unBindPhone, updateAuth,
    saveHeaderIcon
} from "@/api/request";
import {Notify} from "vant";

export default {
    name: "account",
    data() {
        return {
            phone: false,
            auth: false,
            revamp: false,
            btnBool: true,
            isBind: true,
            isAuth: true,
            isShow: false,
            cutFun: true,
            countDown: '',
            userId: '',
            userName: '',
            realName: '',
            cardNo: '',
            bind: {
                tel: '',
                verifyCode: ''
            },
            nickName: '',
            userData: [],
            pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
        }
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem("userInfo"));
        this.userName = user.userName;
        this.userId = user.userId;
        this.loadData();
    },
    methods: {
        loadData() {
            getUserInfo(this.userName).then(res => {
                this.userData = res.data;
                this.bind.tel = this.userData.phone;
                this.nickName = this.userData.nick_name;
                this.cardNo = this.userData.card_no;
                if (this.bind.tel != null && this.bind.tel != "") {
                    this.isBind = false;
                    this.cutFun = false;
                    this.bind.tel = this.bind.tel.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2");
                }
                if (this.cardNo != null && this.cardNo != "") {
                    this.isAuth = false;
                    this.isShow = false;
                    this.realName = this.firstName(this.userData.real_name);
                    const idCard = (data) => {
                        return data.replace(/(.{6}).*(.{4})/, "$1********$2")
                    }
                    this.cardNo = idCard(this.cardNo);
                } else {
                    this.isAuth = true;
                    this.isShow = true;
                }
            })
        },
        toLogout() {
            logout().then(() => {
                localStorage.removeItem("token")
                localStorage.removeItem("userInfo")
                localStorage.removeItem("username")
                localStorage.removeItem("password")
                setTimeout(() => {
                    this.$router.replace('/mobile/account')
                },50);
            }).catch(err => err)
        },
        //发送绑定手机号验证码
        sendVerifyCode() {
            sendBindPhoneVerifyCode(this.bind.tel).then(res => {
                let time = 120;
                let timer = setInterval(() => {
                    this.btnBool = false;
                    this.countDown = time + "s";
                    time--;
                    if (time < 0) {
                        this.btnBool = true;
                        clearInterval(timer);
                    }
                }, 1000);
                if (!res.data.success) {
                    alert(res.data.error.message)
                }
            })
        },
        //绑定手机号
        handleBind() {
            let params = {
                userId: this.userId,
                phone: this.bind.tel,
                verifyCode: this.bind.verifyCode
            }
            bindPhone(params).then(res => {
                if (res.data.success) {
                    this.phone = false;
                    this.$toast.success("绑定成功")
                    this.bindVisible = false
                    this.phone = this.userData.phone.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2");
                    this.verifyCode = '';
                    this.btnBool = true;
                    location.reload();
                } else {
                    alert(res.data.error.message);
                }
            })
        },
        //发送解绑手机号验证码
        sendUnBindVerifyCode() {
            if (this.pattern.test(this.bind.tel)) {
                let time = 120;
                let timer = setInterval(() => {
                    this.btnBool = false;
                    this.countDown = time + "s";
                    time--;
                    if (time < 0) {
                        this.btnBool = true;
                        clearInterval(timer);
                    }
                }, 1000);

                sendUnbindPhoneVerifyCode(this.bind.tel).then(res => {
                    if (!res.data.success) {
                        this.$toast.fail(res.data.error.message);
                    }
                }).catch(() => {
                })
            } else {
                this.$toast.fail("请输入正确的手机号")
            }
        },
        //解绑手机号
        handleUnBind() {
            let params = {
                userId: this.userId,
                phone: this.bind.tel,
                verifyCode: this.bind.verifyCode
            }
            unBindPhone(params).then(res => {
                if (res.data.success) {
                    this.$toast.success("解绑成功");
                    this.phone = false;
                    this.bind.verifyCode = '';
                    this.bind.tel = '';
                } else {
                    this.$toast.fail(res.data.error.message);
                }
            })
        },
        //实名认证
        handleUpdateAuth() {
            let params = {
                userId: this.userId,
                realName: this.realName,
                cardNo: this.cardNo,
            }
            updateAuth(params).then(res => {
                if (res.data.success) {
                    this.$toast.success(res.data.data);
                    this.auth = false;
                    //location.reload();
                } else {
                    this.$toast.fail(res.data.error.message);
                }
                this.authVisible = false;
            })
        },
        //修改昵称
        saveNickName() {
            let params = {
                userId: this.userData.user_id,
                nickName: this.nickName,
            }
            saveEdit(params).then(res => {
                if (res.data.success) {
                    this.revamp = false;
                    this.$toast.success(res.data.data);
                    //window.location.reload();
                } else {
                    this.$toast.fail(res.data.error.message);
                }
            })
        },
        //函数验证身份证
        validator() {
            return /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[0-9Xx]$/.test(this.cardNo);
        },
        //函数验证姓名
        verifyRealName() {
            return /^[\u4E00-\u9FA5·]{2,16}$/.test(this.realName);
        },
        beforeAvatarUpload(file) {
            //const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isJPG = /image\/(png|jpg|jpeg)/
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG.test(file.type)) {
                this.$toast.fail('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$toast.fail('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        afterRead(file){
            console.log(file.file);
            const fd = new FormData();
            fd.append('file',file.file);
            saveHeaderIcon(fd).then( res => {
                if(res.data.success){
                    location.reload();
                }
            })
        },
        //如果实名认证了就把姓名后几位变成*
        firstName(name) {
            let newStr;
            if (name.length === 2) {
                newStr = name.substr(0, 1) + '*';
            } else if (name.length > 2) {
                let char = '';
                for (let i = 0, len = name.length - 1; i < len; i++) {
                    char += '*';
                }
                newStr = name.substr(0, 1) + char;
            } else {
                newStr = name;
            }
            return newStr;
        },
    }
}
</script>

<style scoped>

</style>
<style>
</style>