<template>
    <div class="my-6">
        <div class="max-w-screen-xl mx-auto my-0 relative">
            <div class="bg-white float-left rounded-lg" style="padding: 5px 25px 40px 25px;width: 940px">
                <div class="search -top-3 right-92">
                    <input type="text" v-model="query.gameName" v-on:keyup.enter="loadData(1)" placeholder="搜索游戏"/>
                    <i @click="loadData(1)" class="el-icon-search cursor-pointer absolute right-2" style="top: 33px"/>
                </div>
                <ul class="mt-10">
                    <li v-for="(item,index) in giftInfo" :key="index" class="gift-box">
                        <img :src="item.game_icon" class="w-36 h-36 absolute top-10"/>
                        <div class="absolute left-44 top-8 leading-15">
                            <div>
                                <span class="text-xl font-bold text-indigo-500">{{ item.game_name }}</span>
                                <span class="ml-4 font-bold text-gray-700">{{ item.gift_name }}</span>
                                <p></p>
                            </div>
                            <div class="gift-content">礼包内容</div>
                            <p class="text-sm text-gray-700 truncate" style="width: 364px">{{ item.gift_content }}</p>
                        </div>
                        <button @click="receive(item.gift_id)" class="gift-bag absolute top-22 right-20">领取礼包</button>
                        <p></p>
                    </li>
                </ul>
            </div>
            <!--侧边开服列表-->
            <div class="ml-6 float-left bg-white rounded-lg" style="width: 310px">
                <div>
                    <!--开服列表菜单-->
                    <div class="w-full h-12 leading-12 border-b border-gray-300 border-dashed ">
                        <div class="inline-block">
                            <i class="w-2 h-3 bg-red-700">&nbsp;</i>
                            <span class="text-lg m-4 font-bold">开服列表</span>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <a @click="newServer"><span class="hover billboard" style="background-color: #8B5CF6" ref="recommend">新服推荐</span></a>
                    <a @click="newServer"><span class="hover billboard" ref="foreshow">新服预告</span></a>
                </div>
                <!--开服列表内容-->
                <div style="width: 310px">
                    <!--新服推荐-->
                    <div v-show="recommend">
                        <ul class="px-3">
                            <li class="py-6 border-b border-gray-300 border-dashed"
                                v-for="(item,index) in gameServer.today" :key="index">
                                <img :src="item.game_icon" class="w-12 h-12 float-left">
                                <p style="width: 195px" class="text-sm truncate pl-2">{{ item.game_name }}</p>
                                <span class="text-sm pl-2 text-red-500">{{ item.server_name }}</span>
                                <span class="float-right text-right text-sm w-24">{{item.open_time.substring(5, 16)}}</span>
                            </li>
                        </ul>
                    </div>
                    <!--新服预告-->
                    <div v-show="foreshow">
                        <ul class="px-3">
                            <li class="py-6 border-b border-gray-300 border-dashed"
                                v-for="(item,index) in gameServer.tomorrow" :key="index">
                                <img :src="item.game_icon" class="w-12 h-12 float-left">
                                <p style="width: 195px" class="text-sm truncate pl-2">{{ item.game_name }}</p>
                                <span class="text-sm pl-2 text-red-500">{{ item.server_name }}</span>
                                <span class="float-right text-right text-sm w-24">{{item.open_time.substring(5, 16)}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <!--分页区-->
        <div class="paging">
            <div class="mx-auto my-7 w-1/3">
                <el-pagination
                    @current-change="loadData"
                    background
                    :page-size="pageSize"
                    :current-page="currentIndex"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {getGift, receiveGift, getGameServer} from '@/api/request'

export default {
    name: "gift",
    data() {
        return {
            recommend: true,
            foreshow: false,
            currentIndex: 1,
            total: 0,
            pageSize: 6,
            giftInfo: [],
            gameServer: [],
            query: {
                gameName: '',
            }
        }
    },
    mounted() {
        this.loadData(1);
        this.loadOpenServer();
    },
    methods: {
        loadData(index) {
            let params = {
                params: this.query,
                pageNo: index,
                pageSize: this.pageSize,
            }
            getGift(params).then(res => {
                this.giftInfo = res.data.list;
                this.currentIndex = index;
                this.total = res.data.total;
            })
        },
        receive(giftId) {
            let user = JSON.parse(localStorage.getItem("userInfo"));
            if (user != null) {
                let params = {
                    userId: user.userId,
                    giftId: giftId,
                    userName: user.userName,
                    gameId: this.giftInfo.gameId
                }
                receiveGift(params).then(res => {
                    if (res.data.data != null) {
                        this.$alert("礼包码：" + res.data.data);
                    } else {
                        this.$alert(res.data.error.message + "");
                    }
                })
            } else {
                alert("请先登录")
            }
        },
        loadOpenServer() {
            getGameServer().then(res => {
                this.gameServer = res.data.data;
            })
        },
        newServer(e) {
            let text = e.target.innerText;
            let recommend = this.recommend;
            let foreshow = this.foreshow;
            let recommend2 = this.$refs.recommend;
            let foreshow2 = this.$refs.foreshow;

            if (text === '新服推荐') {
                if (foreshow == true && recommend == false) {
                    recommend2.style.backgroundColor = '#8B5CF6';
                    foreshow2.style.backgroundColor = '#D1D5DB';
                    this.recommend = true;
                    this.foreshow = false;
                }
            }
            if (text === '新服预告') {
                if (recommend == true && foreshow == false) {
                    foreshow2.style.backgroundColor = '#8B5CF6';
                    recommend2.style.backgroundColor = '#D1D5DB';
                    this.foreshow = true;
                    this.recommend = false;
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
    @apply bg-indigo-500 text-white !important;
}

.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    @apply rounded-md !important;
    @apply hover:text-indigo-500 !important;
}

.el-button--primary {
    @apply bg-indigo-500 border-indigo-500 !important;
}
</style>