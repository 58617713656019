<template>
    <div class="py-5 px-7 relative">
        <div class="text-lg text-gray-700 pb-3 border-b border-gray-500">隐私协议</div>
        <div class="mt-6">
            <iframe src="https://game.7li.cn/static/download/privacy.html" scrolling="no" frameborder="0" class="w-full" style="height: 8700px"></iframe>
        </div>
    </div>
</template>

<script>
export default {
  name: "privacy"
}
</script>

<style scoped>

</style>