<template>
    <div>
        <div>
            <van-nav-bar fixed placeholder @click-left="$router.go(-1)" left-arrow/>
        </div>
        <div class="px-3 mt-3 relative">
            <van-image lazy-load src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" width="70"
                       height="70"/>
            <div class="absolute top-4 left-24">
                <p>LuckyMan</p>
                <p>2022-08-23</p>
            </div>
        </div>
        <div class="px-3">
            <div class="px-3 py-3 w-full border border-gray-200 border-solid rounded-md mt-3">
                <ul>
                    <li>
                        <p class="text-indigo-500 text-xl">￥1200.00</p>
                        <p>
                            账号简介…………
                        </p>
                        <p>
                            <span>游戏区服：</span>
                            <span>100服</span>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="mt-6">
            <van-swipe @change="onChange" :loop="false" :width="200">
                <van-swipe-item class="border h-72" v-for="(item,index) in images" :key="index">
                    <van-image
                        lazy-load
                        :src="item"
                        width="192"
                        height="287"
                        @click="getIMG(images,index)">
                        <template v-slot:loading>
                            <van-loading type="spinner" class="left-0 top-0" size="20"/>
                        </template>
                    </van-image>
                </van-swipe-item>
                <template #indicator>
                    <div></div>
                </template>
            </van-swipe>
        </div>
        <footer class="fixed ">
            <div class="fixed bottom-0 w-full bg-white h-14">
                <div class=" border-t border-solid px-6">
                <span class="float-left" @click="show = true">
                    <van-icon name="service-o" color="#667eea" size="1.25rem" class="mt-1"
                              style="margin-left: 0.40rem"/>
                    <p>客服</p>
                </span>
                    <button class="float-right bg-indigo-500 mt-3 px-1 py-1 rounded-md text-white">立即购买</button>
                </div>
            </div>
        </footer>
        <!-- 客服弹窗 -->
        <van-action-sheet v-model="show" title="客服" class="px-4">
            <div>
                <span>客服QQ：</span>
                <span class="select-text">3008618195</span>
            </div>
            <div class="content">
                <van-image lazy-load src="https://game.7li.cn/img/qili-qrcode.31a32cb1.png"
                           class="w-24 h-24 float-left"/>
                <div class="text-gray-500 pl-28 pt-6">
                    <p>
                        公众号搜索"七里网络"
                    </p>
                    <p>找在线客服</p>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>

import {ImagePreview} from "vant";

export default {
    name: "GoodsDetails",
    data() {
        return {
            current: 0,
            showShare: false,
            show: false,
            showImg: false,
            options: [
                [
                    {name: '微信', icon: 'wechat'},
                    {name: '朋友圈', icon: 'wechat-moments'},
                    {name: 'QQ', icon: 'qq'},
                    {name: '复制链接', icon: 'link'},
                ],
            ],
            images: [
                'https://qili-data.oss-cn-qingdao.aliyuncs.com/transaction/1012/0.png',
                'https://qili-data.oss-cn-qingdao.aliyuncs.com/transaction/1006/0.jpeg',
                'https://qili-data.oss-cn-qingdao.aliyuncs.com/transaction/1006/0.jpeg',
                'https://qili-data.oss-cn-qingdao.aliyuncs.com/transaction/1013/5.jpeg',
                'https://qili-data.oss-cn-qingdao.aliyuncs.com/transaction/1008/0.jpeg'
            ],
        }
    },
    methods: {
        onChange(index) {
            this.current = index;
        },
        getIMG(img, index) {
            ImagePreview({
                images: img,
                closeable: true,
                loop: false,
                startPosition: index
            })
        }
    }
}
</script>
<style>
.custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
}
</style>