<template>
    <div>
        <div class="relative">
<!--            <div class="">
                <van-nav-bar :border="false" fixed placeholder safe-area-inset-top title="福利"/>
            </div>-->
            <div class="px-3 bg-white pt-3">
                <van-grid :column-num="3" :border="true">
                  <van-grid-item v-for="(item,index) in a" :key="index" :icon="item.icon" :text="item.text" :to="item.path"/>
                </van-grid>
            </div>
            <div class="px-3 bg-white pt-6">
                <div class="text-gray-700 text-xl font-bold">
                  <span>活动</span>
                </div>
                <div>
                  <div v-for="(item,index) in pageData" :key="index" class="activity-box mt-4" @click="toDetail(item.content_id)">
                    <van-empty
                        class="custom-image"
                        :image="item.view_img_url"/>
                    <div class="py-4 px-3">{{ item.content_name }}</div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryGameActivity } from "@/api/request";

export default {
  name: "mobileWeifare",
  data(){
      return{
        a:[
            {text: '游戏礼包',path: '/mobile/gift',icon: 'point-gift'},
            {text: '开服表',path: '/mobile/kaiFu',icon: 'coupon'},
            {text: 'app下载',path: '/mobile/software',icon: 'down'}
        ],
        pageData: [],
      }
  },
  mounted() {
      this.loadData();
  },
  methods:{
      loadData(){
          queryGameActivity().then(res => {
            this.pageData = res.data;
          })
      },
      toDetail(contentId){
          this.$router.push({name: 'MobileNews',params: { contentId: contentId }})
      },
  }
}
</script>

<style scoped>

</style>
<style>
    .van-grid{
      border-color: #dedede;
      background: #f5f5f5;
      padding: 1.41rem 0 0.21rem;
      @apply w-full rounded-xl border border-solid;
      @apply overflow-hidden;
    }
    .van-grid-item__content{
        background-color: #f5f5f5 !important;
    }
    .custom-image .van-empty__image {
        width: 100%;
        height: 25%;
    }
    .van-empty{
        @apply p-0 !important;
    }
    .van-empty__image img{
        @apply rounded-t-xl;
    }
    .van-empty__description{
        @apply my-4;
    }
    .activity-box{
        background-color: #f5f5f5;
        @apply rounded-xl;
    }

</style>