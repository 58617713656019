<template>
    <div>
        <div>
          <van-nav-bar
              title="我的游戏"
              @click-left="$router.go(-1)"
              fixed
              :placeholder="true"
              left-arrow/>
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad">
              <van-cell v-for="(item,index) in pageData" :key="index">
                <img :src="item.game_icon" class="w-16 h-16 float-left">
                <div class="absolute left-20 leading-normal">
                  <div class="text-md w-38 truncate">{{ item.game_name }}</div>
                  <div class="text-xs w-50 text-gray-500">
                    <span class="bg-gray-400 text-gray-100 text-xs mr-1 px-1 rounded-sm" v-for="(tag,index) in item.tag.split(',')" v-if="tag" v-text="tag" :key="index"></span>
                  </div>
                </div>
              </van-cell>
            </van-list>
          </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import { getMyGame } from "@/api/request";

export default {
  name: "mobileMyGame",
  data(){
    return{
      userId: '',
      pageData: [],
      pageNo: 1,
      pageSize: 10,
      loading: false, // 当loading为true时，转圈圈
      finished: false, // 数据是否请求结束，结束会先显示-我也是我底线的
      refreshing: false,
      type:['仙侠','角色扮演','Q版']
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.userId = user.userId;
  },
  methods:{
    loadData() {
      if (this.refreshing) {
        this.pageData = [];
        this.refreshing = false;
      }
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        params: {
          userId: this.userId
        }
      }
      getMyGame(params).then( res => {
        if(res.data.list.length != 0){
          this.loading = false;
          this.pageData = this.pageData.concat(res.data.list);
          this.pageNo++;
        }
        //如果加载完毕，显示没有更多
        if(res.data.list.length == 0){
          this.finished = true;
        }
      })
    },
    onLoad(){
      setTimeout(() => {
        this.loadData();
        this.loading = true;
      },500)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageData = [];
      this.pageNo = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
  }
}
</script>

<style scoped>

</style>