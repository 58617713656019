<template>
    <div>
        <div>
            <van-nav-bar
                title="资讯"
                fixed
                placeholder
                @click-left="$router.go(-1)"
                left-arrow/>
            <div class="bg-white px-4">
                <p class="text-lg font-bold">{{ pageData.content_name }}</p>
                <van-tag color="#ffe1e1" text-color="#ad0000">活动</van-tag>
                <span class="pl-1 text-xs text-gray-500">{{ pageData.add_time }}</span>
            </div>
        </div>
        <div v-html="pageData.content_text">

        </div>
<!--        <pre class="px-4 text-justify text-sm" style="white-space: pre-line">
        </pre>-->
    </div>
</template>

<script>
import { getStrategy } from "@/api/request";

export default {
  name: "mobileNews",
  data(){
    return{
        contentId: '',
        pageData: [],
    }
  },
  mounted() {
      this.loadData();
  },
  methods:{
      loadData(){
          this.contentId = this.$route.params.contentId;
          getStrategy(this.contentId).then( res => {
              this.pageData = res.data.data;
          })
      }
  }
}
</script>

<style scoped>

</style>