<template>
    <div v-if="pageData.game" class="mt-6">
        <div class="relative max-w-screen-xl mx-auto my-0">
            <div class="bg-white rounded-lg float-left mb-6" style="padding: 40px 25px 40px 25px;width: 940px">
                <div><img :src="pageData.game.game_icon" class="w-36 h-36"></div>
                <table class="float-left absolute top-14 left-48 leading-8 text-sm w-96">
                    <tr>
                        <td colspan="4" class="text-2xl">{{ pageData.game.game_name }}</td>
                    </tr>
                    <tr>
                        <td class="t4">版本：</td>
                        <td class="t2">{{ formatGameSystem(pageData.game.game_system) }}</td>
                    </tr>
                    <tr>
                        <td class="t4">类型：</td>
                        <td class="t2">
                            <span v-for="(tag,index) in pageData.game.tag.split(',')" :key="index" v-if="tag"> {{tag}} </span>
                        </td>
                    </tr>
                </table>
                <!--手游下载-->
                <div class="float-right absolute top-16 left" style="left: 789px">
                    <a href="javascript:" :style="{pointerEvents: isDisablePointer}" @click="androidDownload(pageData.game.game_app_url,1)">
                        <div class="download-box" :class="{ download: pageData.game.game_system === 1 || pageData.game.game_system === 3 }">
                            <img src="../../../static/images/az.png" class="w-5 h-5 inline-block mb-1 mr-2">
                            <span>下载</span>
                        </div>
                    </a>
                    <a href="javascript:" class="inline-block mt-4" :style="{pointerEvents: isDisablePointer2}" @click="androidDownload(null,2)">
                        <div class="download-box" :class="{ download: pageData.game.game_system === 2 || pageData.game.game_system === 3}">
                            <img src="../../../static/images/pg.png" class="w-5 h-5 inline-block mb-1 mr-2">
                            <span>下载</span>
                        </div>
                    </a>
                    <br>
                    <a href="javascript:" @click="playGame(pageData.game.game_url)" class="inline-block mt-4"
                       v-if="pageData.game.game_url">
                        <div class="download-box download">
                            <span>开始游戏</span>
                        </div>
                    </a>
                </div>
                <!--游戏介绍-->
                <p class="title-box">游戏介绍</p>
                <div>
                    <p class="mt-8 text-justify leading-8" :class="{ open: isOpen }" style="text-indent: 2em">
                        {{ pageData.game.game_desc }}
                    </p>
                    <button class="float-right text-blue-400 hover:text-blue-500" @click="showText">
                        {{ isOpen ? '展开' : '收起' }}
                    </button>
                </div>
                <!--游戏视频-->
                <div v-if="pageData.detailVideo">
                    <p class="title-box">游戏视频</p>
                    <div class="mt-2" style="width: 890px;height: 506px">
                        <video :src="pageData.detailVideo.url" :poster="pageData.detailVideo.view_img_url"
                               style="width: 100%;height: 100%;object-fit: contain"
                               controls disablePictureInPicture controlslist="noplaybackrate" id="video"></video>
                    </div>
                </div>
                <!--游戏礼包-->
                <p class="title-box">游戏礼包</p>
                <div>
                    <ul>
                        <li v-for="(item,index) in pageData.gift" :key="index"
                            class="mt-10 border border-gray-300 border-dashed float-left mx-4 relative"
                            style="width: 410px">
                            <div class="leading-12 px-4">
                                <div>
                                    <span class="text-indigo-500 text-xl font-bold">{{ item.game_name }}</span>
                                    <span class="ml-4">{{ item.gift_name }}</span>
                                </div>
                                <div class="text-sm">
                                    【礼包内容】
                                </div>
                                <p class="text-xs">{{ item.gift_content }}</p>
                            </div>
                            <button @click="$router.replace('/gift')" style="padding: 0 22px 0 22px;"
                                    class="gift-bag text-md absolute top-13 right-4">领取礼包
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="ml-6 float-left bg-white rounded-lg" style="width: 310px">
            <div class="px-4 py-4">
                <table>
                    <tr>
                        <td class="w-24 h-24">
                            <img class="w-24 h-24" src="https://game.7li.cn/img/qili-qrcode.31a32cb1.png">
                        </td>
                        <td>
                            <div class="ml-4">
                    <span class="text-gray-500">
                      公众号搜索"七里网络"&nbsp;
                      更多福利等着你
                    </span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {queryGame} from '@/api/request'

export default {
    name: "MgDetails",
    data() {
        return {
            isOpen: true,
            pageData: [],
            isDisablePointer: "none",
            isDisablePointer2: "none"
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            //let game_name = this.$route.params.game;
            let gameId = sessionStorage.getItem("gameId");
            queryGame(gameId).then(res => {
                if(res.data.success){
                    this.pageData = res.data.data;
                    this.setPointerEvents(this.pageData.game.game_system);
                }else {
                    this.$toast.fail(res.data.error.message);
                    this.$router.replace("/mobileGame")
                }
            })
        },
        showText() {
            if (this.isOpen == true) {
                this.isOpen = false;
            } else if (this.isOpen == false) {
                this.isOpen = true;
            }
        },
        format(topic) {
            let ret = "";
            if (topic === 1) {
                ret = '卡牌';
            } else if (topic === 2) {
                ret = '传奇';
            } else if (topic === 3) {
                ret = '武侠';
            } else if (topic === 4) {
                ret = '角色';
            } else if (topic === 5) {
                ret = '养成';
            } else if (topic === 6) {
                ret = '三国';
            } else if (topic === 7) {
                ret = '奇迹';
            } else if (topic === 8) {
                ret = 'BT';
            }
            return ret;
        },
        playGame(link) {
            let token = localStorage.getItem("token");
            if (token != null) {
                let username = localStorage.getItem("username");
                let password = localStorage.getItem("password");
                //let loginUrl = 'http://192.168.0.9:8081/#/play/1/138?from=qlWebsite&u=' + username + '&p=' + password
                let loginUrl = link + "?from=qlWebsite&u="+ username + "&p=" + password
                window.open(loginUrl, '_blank')
                //localStorage.setItem("loginUrl",link);
            } else {
                this.$root.$emit("not_log_in");
            }
        },
        androidDownload(link,index) {
            if(index === 1){
                location.href = link;
            }else if(index ===2){
                let ios = 'https://apps.apple.com/cn/app/%E4%B8%83%E9%87%8C%E6%B8%B8%E6%88%8F/id1620672705'
                window.open(ios)
            }
        },
        setPointerEvents(gameSystem){
            switch (gameSystem){
                case 1: this.isDisablePointer = "auto"; break;
                case 2: this.isDisablePointer2 = "auto"; break;
                case 3: this.isDisablePointer = "auto";
                        this.isDisablePointer2 = "auto";
                        break;
            }
        },
        formatGameSystem(system){
            let str = ''
            if(system === 1){
                str = 'Android'
            }else if(system === 2){
                str = 'IOS'
            }else if(system === 3){
                str = '双端'
            }
            return str;
        }
    },
}
</script>

<style scoped>
.open {
    @apply h-32 overflow-hidden;
}
</style>