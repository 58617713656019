<template>
    <div>
        <div v-show="login">
          <van-nav-bar
              title="登录"
              right-text="忘记密码"
              @click-right="toRetrieve"
              left-arrow
              @click-left="$router.go(-1)"
              :border="false"/>
          <van-form>
            <van-field
                v-model="user.userName"
                name="账号"
                label="账号"
                placeholder="请输入账号"
                maxlength="12"
                :rules="[{ pattern, required: true, message: '账号长度为6-12位字母数字或下划线组成' }]"
            />
            <van-field
                v-model="user.userPwd"
                type="password"
                name="密码"
                label="密码"
                placeholder="请输入密码"
                maxlength="12"
                :rules="[{ required: true, message: '密码为6-12位' }]"
            />
            <div style="margin: 16px;">
              <van-button style="width: 47.5%" type="default" @click="toRegister" native-type="button">注册</van-button>
              <van-button color="#667eea" style="width: 47.5%" class="ml-4" type="info" @click="submit" >登录</van-button>
            </div>
            <van-field name="checkboxGroup">
              <template #input>
                <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
                  <van-checkbox name="checkbox" checked-color="#667eea">
                    <span class="ml-2" style="font-size: 0.75rem">
                      勾选即表示同意七里网络
                      <a target="_blank" href="https://game.7li.cn/static/sdk/user.html" class="text-indigo-500 focus:underline">《用户协议》</a>&
                      <a target="_blank" href="https://game.7li.cn/static/download/privacy.html" class="text-indigo-500 focus:underline">《隐私政策》</a>
                    </span>
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
          </van-form>
        </div>
        <div v-show="register">
          <van-nav-bar
              title="注册"
              right-text="忘记密码"
              @click-right="toRetrieve"
              left-arrow
              @click-left="toLogin"
              :border="false"/>
          <van-form>
            <van-field
                v-model="registerUser.userName"
                name="账号"
                label="账号"
                placeholder="请输入6-12位字母数字或下划线组合"
                maxlength="12"
                :rules="[{ pattern, required: true, message: '账号为6-12位字母数字或下划线组成' }]"
            />
            <van-field
                v-model="registerUser.userPwd"
                type="password"
                name="密码"
                label="密码"
                placeholder="请输入6-12位密码"
                maxlength="12"
                :rules="[{ validator, required: true, message: '密码为6-12位' }]"
            />
            <van-field
                v-model="registerUser.userPwd2"
                type="password"
                name="确认密码"
                label="确认密码"
                placeholder="请确认密码"
                maxlength="12"
                :rules="[
                    { validator, required: true, message: '密码为6-12位' },
                    { validator:verifyPwd, required: true, message: '请确认两次密码一致' }
                    ]"/>
            <div style="margin: 16px;">
              <van-button block color="#667eea" type="default" native-type="submit" @click="regSubmit">注册</van-button>
            </div>
            <van-field name="checkboxGroup">
              <template #input>
                <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
                  <van-checkbox name="checkbox" checked-color="#667eea">
                    <span class="ml-2" style="font-size: 12px">
                      勾选即表示同意七里网络
                      <a target="_blank" href="https://game.7li.cn/static/sdk/user.html" class="text-indigo-500 focus:underline">《用户协议》</a>&
                      <a target="_blank" href="https://game.7li.cn/static/download/privacy.html" class="text-indigo-500 focus:underline">《隐私政策》</a>
                    </span>
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
          </van-form>
        </div>
        <div v-show="retrieve">
          <van-nav-bar
              title="找回密码"
              left-arrow
              @click-left="toLogin"
              :border="false"/>
          <van-form>
            <van-field
                v-model="resetUser.userName"
                name="账号"
                label="账号"
                placeholder="请输入6-12位字母数字或下划线组合"
                maxlength="12"
                :rules="[{ pattern,required: true, message: '账号为6-12位字母数字或下划线组成' }]"/>
            <van-field
                v-model="resetUser.userPwd"
                type="password"
                name="新密码"
                label="新密码"
                placeholder="请输入6-12位密码"
                maxlength="12"
                :rules="[{ validator, required: true, message: '密码为6-12位' }]"/>
            <van-field
                v-model="resetUser.userPwd2"
                type="password"
                name="确认新密码"
                label="确认新密码"
                placeholder="请输入6-12位密码"
                maxlength="12"
                :rules="[
                    { validator, required: true, message: '密码为6-12位' },
                    { validator:verifyResetPwd, required: true, message: '两次密码不一致' }
                    ]"/>
            <van-field
                v-model="resetUser.phone"
                type="text"
                name="手机号"
                label="手机号"
                placeholder="请输入手机号"
                maxlength="11"
                :rules="[{ validator:verifyPhone, required: true, message: '请输入正确的手机号' }]"/>
            <van-field
                v-model="resetUser.verifyCode"
                type="text"
                name="验证码"
                label="验证码"
                placeholder="请入验证码"
                maxlength="6"
                :rules="[{  required: true, message: '请输入六位验证码' }]">
                <template #extra>
                  <span v-if="btnBool" class="text-blue-500" @click="sendResetVerifyCode">发送验证码</span>
                  <span class="text-blue-500" v-if="!btnBool">{{ countDown }}</span>
                </template>
            </van-field>
            <div style="margin: 16px;">
              <van-button block color="#667eea" type="default" native-type="submit" @click="resetSubmit">确认</van-button>
            </div>
          </van-form>
        </div>
    </div>
</template>

<script>
import {doLogin, doRegister, doReset, sendResetPwdVerifyCode} from "@/api/request";
import crypto from "@/utils/crypto";

export default {
  name: "mobileLogin",
  data(){
    return{
        checkboxGroup: [],
        login: true,
        register: false,
        retrieve: false,
        btnBool: true,
        countDown: '',
        user:{
          userName: '',
          userPwd: ''
        },
        registerUser:{
            userName: '',
            userPwd: '',
            userPwd2: '',
        },
        resetUser:{
            userName: '',
            userPwd: '',
            userPwd2: '',
            phone: '',
            verifyCode: ''
        },
        pattern: /^[\w]{6,12}$/,
    }
  },
  methods:{
    toLogin(){
        this.login = true;
        this.retrieve = false;
        this.register = false;
        this.user= {
          nikeName: '',
          password: ''
        }
    },
    toRetrieve(){
        this.login = false;
        this.register = false;
        this.retrieve = true;
        this.user= {
          nikeName: '',
          password: ''
        }
    },
    toRegister(){
        this.login = false;
        this.retrieve = false;
        this.register = true;
        this.user= {
          nikeName: '',
          password: ''
        }
    },
    submit(){
      if(this.checkboxGroup.length != 0){
        let params = {
          userName: this.user.userName,
          userPwd: crypto.encrypt(this.user.userPwd)
        }
        doLogin(params).then( res => {
          if(res.data.success){
            localStorage.setItem("token",res.data.data.token);
            localStorage.setItem("userInfo",JSON.stringify(res.data.data));
            localStorage.setItem("username",this.user.userName);
            localStorage.setItem("password",crypto.encrypt(this.user.userPwd));
            this.userData = res.data.data;
            this.$router.push('/mobile/account')
            this.user = {
              userName: '',
              userPwd: '',
            }
          }else{
            alert(res.data.error.message)
          }
        })
      }else {
        this.user.userName = this.user.userName
        this.user.userPwd = this.user.userPwd
        this.$toast.fail("请阅读并勾选底部的协议")
      }
    },
    regSubmit(){
        if(this.registerUser.userPwd2 === this.registerUser.userPwd){
            if(this.checkboxGroup.length != 0){
                let params = {
                    userName: this.registerUser.userName,
                    userPwd: crypto.encrypt(this.registerUser.userPwd),
                }
                doRegister(params).then(res => {
                    console.log(res);
                    if(res.data.success){
                      this.$toast.success(res.data.data);
                      this.$router.replace('/mobile/account')
                      this.registerUser = {
                        userName: '',
                        userPwd: '',
                        userPwd2: '',
                      }
                    }else {
                      this.$toast.fail(res.data.error.message);
                    }
                })
            }else {
              this.$toast.fail('请阅读并勾选底部协议')
            }
       }
    },
    resetSubmit(){
        if(this.resetUser.userPwd2 === this.resetUser.userPwd){
            this.resetUser.userPwd = crypto.encrypt(this.resetUser.userPwd)
            this.resetUser.userPwd2 = crypto.encrypt(this.resetUser.userPwd2)
            doReset(this.resetUser).then( res => {
                if(res.data.success){
                    this.$toast.success("修改成功");
                    this.$router.replace('/mobile/account');
                    this.resetUser = {
                      userName: '',
                      userPwd: '',
                      userPwd2: '',
                      verifyCode: '',
                      phone: ''
                  }
                }else {
                    this.$toast.fail(res.data.error.message);
                }
            })
        }
    },
    sendResetVerifyCode(){
        if(this.verifyPhone()){
            let params = {
                phone: this.resetUser.phone,
                userName: this.resetUser.userName
            }
            sendResetPwdVerifyCode(params).then( res => {
                if(res.data.success) {
                    let time = 120;
                    let timer = setInterval(() => {
                        this.btnBool = false;
                        this.countDown = time + "s";
                        time--;
                        if (time < 0) {
                            this.btnBool = true;
                            clearInterval(timer);
                        }
                    }, 1000);
                }else {
                  this.$toast.fail(res.data.error.message)
                }
            }).catch( () => {
            })
        }else {
            this.$toast.fail('请输入正确的手机号')
        }
    },
    validator(val){
      return /^.{6,12}$/.test(val)
    },
    verifyPwd(){
        if(this.registerUser.userPwd2 !== this.registerUser.userPwd){
            return false;
        }
    },
    verifyResetPwd(){
        if(this.resetUser.userPwd2 !== this.resetUser.userPwd){
            return false;
        }
    },
    verifyPhone(){
        return /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(this.resetUser.phone);
    },
    verifyCode(){
        if(this.resetUser.verifyCode.length !== 6){
            return false;
        }
    }
  }
}
</script>

<style scoped>

</style>