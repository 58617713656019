<template>
    <div>
        <div>
          <van-nav-bar
              title="游戏礼包"
              fixed
              placeholder
              @click-left="$router.go(-1)"
              left-arrow/>
          <van-search v-model="value" show-action shape="round" placeholder="请输入搜索关键词" @search="onRefresh">
            <template #action>
              <div @click="onRefresh">搜索</div>
            </template>
          </van-search>
          <div class="relative">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad">
              <van-cell v-for="(item,index) in pageData" :key="index">
                  <van-image lazy-load width="70" height="70" :src="item.game_icon" />
                  <div class="absolute top-px left-20">
                      <div class="text-md truncate w-50">
                      <span>{{ item.game_name }}</span>
                      <span class="ml-1 text-indigo-500">{{ item.gift_name }}</span>
                      </div>
                      <van-tag color="#f56565" plain>礼包内容</van-tag>
                      <div class="text-xs w-40 truncate">{{ item.gift_content }}</div>
                  </div>
                  <div class="absolute top-5 right-1">
                      <button @click="receive(item.gift_id)" class="px-4 py-1 float-right border border-indigo-500 text-indigo-500 rounded-full">领取</button>
                  </div>
              </van-cell>
            </van-list>
          </div>
        </div>
    </div>
</template>

<script>
import { getGift,receiveGift } from "@/api/request";

export default {
  name: "gameGift",
  data(){
    return{
      value: '',
      pageData: [],
      pageNo: 1,
      pageSize: 10,
      loading: false, // 当loading为true时，转圈圈
      finished: false, // 数据是否请求结束，结束会先显示-我也是我底线的
      refreshing: false,
    }
  },
  methods: {
    loadData() {
      if (this.refreshing) {
        this.pageData = [];
        this.refreshing = false;
      }
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        params: {
          gameName: this.value,
        }
      }
      getGift(params).then( res => {
        if(res.data.list.length != 0){
          this.loading = false;
          this.pageData = this.pageData.concat(res.data.list);
          this.pageNo++;
        }
        //如果加载完毕，显示没有更多
        if(res.data.list.length == 0){
          this.finished = true;
        }
      })
    },
    onLoad(){
      setTimeout(() => {
        this.loadData();
        this.loading = true;
      },500)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageData = [];
      this.pageNo = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
      receive(giftId){
          let user = JSON.parse(localStorage.getItem("userInfo"));
          if(user != null){
              let params = {
                  userId: user.userId,
                  giftId: giftId,
                  userName: user.userName,
                  gameId: this.pageData.gameId
              }
              receiveGift(params).then( res => {
                  if(res.data.data != null){
                      this.$dialog.alert({
                        message: "礼包码：" + res.data.data,
                      }).then(() => {
                      });
                  }else {
                      this.$toast.fail(res.data.error.message + "");
                  }
              })
          }else {
              this.$toast.fail("请先登录")
          }
      }
  }
}
</script>

<style scoped>

</style>