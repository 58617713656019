<template>
    <div>
        <el-container>
            <el-main class="p-0 m-0 border-0">
                <div class="bg"> <!--class="bg-blue-400 bg-opacity-70"-->
                    <div class="max-w-screen-xl mx-auto my-0 relative">
                        <img src="../../../static/images/app.jpg" width="300" height="450" class="rounded-xl absolute top-20" style="object-fit: cover;"/>
                        <div class="text-white font-bold absolute" style="left: 33rem;top: 10rem; font-size: 4.8em;letter-spacing: 5px">7Li游戏</div>
                        <div class="grid grid-cols-2 grid-rows-2 gap-8 relative" style="width: 20rem;left: 33rem;top: 20rem;">
                            <span>
                                <img src="../../../static/images/AndroidQR.png" width="150" height="150"/>
                            </span>
                            <span class="downloadApp my-10">
                                <a href="javascript:" @click="download(1)">
                                    <img src="../../../static/images/Android.png" width="35" height="35" class="inline-block mr-1"/>
                                    <span class="text-lg">安卓下载</span>
                                </a>
                            </span>
                            <span>
                                <img src="../../../static/images/IosQR.png" width="150" height="150"/>
                            </span>
                            <span class="downloadApp my-10">
                                <a href="javascript:" @click="download(2)">
                                    <img src="../../../static/images/IOS.png" width="35" height="35" class="inline-block mr-1"/>
                                    <span class="text-lg">苹果下载</span>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    export default {
        name: "app",
        data(){
            return{

            }
        },
        methods:{
            download(i){
              if(i === 1) {
                 let apk = 'https://cdn.7li.club/jyqzxxpg/1/qiliyouxiapp.apk'
                  location.href = apk;
              }else if(i === 2){
                  let a = 'https://apps.apple.com/cn/app/%E4%B8%83%E9%87%8C%E6%B8%B8%E6%88%8F/id1620672705'
                  window.open(a)
              }
            }
        }
    }
</script>

<style scoped>

</style>
<style>
    .downloadApp a{
      @apply inline-block bg-white px-6 py-2 rounded-full shadow-lg my-3 w-40;
    }
    .scan img{
        @apply absolute left-2/4;
    }
    .iconStyle{
        @apply float-right py-4 text-white hover:text-gray-300 cursor-pointer;
    }
    .bg{
        height: 90.7vh;width: 100%; overflow: hidden;
        background-image: url("../../../static/images/bg.png");
    }
</style>