<template>
    <div>
        <div class="relative max-w-screen-xl mx-auto my-0">
            <div style="width: 940px;padding: 5px 25px 40px 25px;" class="bg-white float-left rounded-lg">
                <div class="text-center">
                  <div class="text-2xl text-indigo-500 font-bold">{{ pageData.content_name }}</div>
                  <div class="text-xs text-gray-500 border-b border-gray-500 border-dashed pb-4"> {{ pageData.add_time }}</div>
                </div>
                <div v-html="pageData.content_text"></div>
            </div>
          <!-- 热游推荐-->
          <div class="relative bg-white float-left ml-6 rounded-lg" style="width: 310px">
            <div class="inline-block mt-4">
              <i class="w-2 h-3 bg-red-700">&nbsp;</i>
              <span class="text-lg m-4 font-bold">热游推荐</span>
            </div>
            <div class="mt-2">
              <ul>
                <li v-for="(item,index) in hotData" class="h-36 py-3.5 px-6">
                  <div class="h-8" style="line-height: 107px">
                    <span class="">{{index+1}}</span>
                  </div>
                  <div class="relative left-5 float-left pr-4" style="top: -15px">
                    <img :src="item.game_icon" class="w-20 h-20 rounded-lg"/>
                  </div>
                  <table class="relative left-4 -top-2 leading-8">
                    <tr>
                      <td>
                        <span class="hover:text-indigo-500 cursor-pointer">{{item.game_name}}</span>
                      </td>
                    </tr>
                    <tr class="text-xs text-gray-800">
                      <div class="w-30 truncate">
                        <span class="px-1" v-for="tag in item.tag.split(',')">{{ tag }}</span>
                      </div>
                    </tr>
                  </table>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { getStrategy,getHotGameList } from '@/api/request'

export default {
  name: "newsInfo",
  data(){
      return{
        contentId: '',
        pageData:[],
        hotData:[]
      }
  },
  mounted() {
    this.loadData();
  },
  methods:{
    loadData(){
      this.contentId = this.$route.params.contentId;
      getStrategy(this.contentId).then( res => {
        this.pageData = res.data.data;
      })
      getHotGameList().then(res => {
          this.hotData = res.data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>