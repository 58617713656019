<template>
    <div>
        <div class="w-full h-48 relative bg-gradient-to-r from-indigo-400 to-indigo-600 rounded-b-xl px-3"
             style="line-height: 6.5rem">
            <div class="relative" @click="toLogin">
                <van-image
                    width="4.375rem"
                    height="4.375rem"
                    fit="fill"
                    lazy-load
                    :src="userData.length != 0 ? userData.img_url : require('@/static/images/santaClaus.png')"
                    round
                    alt="头像"
                    class="mt-3 float-left"/>
                <span class="ml-3 text-white" v-if="!token">立即登录</span>
                <van-icon name="setting-o" size="25" class="absolute right-0 top-4 text-white"/>
                <div class="text-xl absolute left-20 text-white font-bold" v-if="userData">{{ userData.nick_name }}</div>
            </div>
        </div>
        <div class="px-3 bg-white pt-5">
            <van-grid :column-num="3" :border="true">
                <van-grid-item v-for="(item,index) in grid" :key="index" :icon="item.icon" :text="item.text"
                               @click="clickGrid(index,item.path)"/>
            </van-grid>
        </div>
        <div class="px-3 bg-white pt-5">
            <div>热游推荐</div>
            <ul>
                <li class="py-3 relative" v-for="(item,index) in hotGameData" :key="index"
                    @click="toDetails(item.game_id)">
                    <img :src="item.game_icon" class="w-18 h-18"/>
                    <div class="absolute top-3 left-22">
                        <div>{{ item.game_name }}</div>
                        <div class="text-xs truncate" style="width: 10.25rem">{{ item.game_desc }}</div>
                        <div class="hotTag w-38 truncate">
                            <span v-for="(tag,index) in item.tag.split(',')" :key="index" v-if="tag">{{ tag }}</span>
                        </div>
                    </div>
                    <van-button size="small"
                                class="border-indigo-500 absolute top-8 right-2 text-indigo-500 rounded-md w-16" plain>
                        下载
                    </van-button>
                </li>
            </ul>
        </div>
        <div class="px-3 bg-white py-5 text-center text-sm">
            <van-row type="flex" justify="space-between">
                <van-col span="12">
              <span @click="$dialog.alert({message: '正在开发中……',})">
                  <van-icon name="edit"/>
                  <span class="pl-1">意见反馈</span>
              </span>
                </van-col>
                <van-col span="12">
              <span @click="show = true;">
                  <van-icon name="service-o"/>
                  <span class="pl-1">联系客服</span>
              </span>
                </van-col>
            </van-row>
        </div>
        <van-action-sheet v-model="show" title="客服" class="px-4">
            <div>
                <span>客服QQ：</span>
                <span class="select-text">2982250830</span>
            </div>
            <div class="content">
                <van-image lazy-load src="https://game.7li.cn/img/qili-qrcode.31a32cb1.png"
                           class="w-24 h-24 float-left"/>
                <div class="text-gray-500 pl-28 pt-6">
                    <p>公众号搜索"七里网络"</p>
                    <p>找在线客服</p>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import {getHotGame, getUserInfo} from '@/api/request'

export default {
    name: "mobileAccount",
    data() {
        return {
            show: false,
            imgUrl: "",
            token: null,
            userData: [],
            hotGameData: [],
            grid: [
                {text: '我的礼包', icon: 'point-gift', path: '/mobile/myGift'},
                {text: '我的游戏', icon: 'graphic', path: '/mobile/myGame'},
                {text: '修改密码', icon: 'lock', path: '/mobile/modPwd'},
                {text: '客服', icon: 'service', path: ''},
                {text: '交易', icon: 'gold-coin', path: ''}, /*/mobile/transaction*/
                {text: '关于我们', icon: 'warning', path: '/mobile/matter'}
            ]
        }
    },
    mounted() {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("userInfo"));
        this.token = token;
        this.loadData();
        if(user != null){
            getUserInfo(user.userName).then(res => {
                this.userData = res.data;
            })
        }
    },
    methods: {
        loadData() {
            getHotGame().then(res => {
                if (res.data.success) {
                    this.hotGameData = res.data.data;
                } else {
                    this.$toast('系统繁忙，请稍后再试')
                }
            })
        },
        toLogin() {
            let token = localStorage.getItem("token");
            if (token != null) {
                this.$router.push("/account")
            } else {
                this.$router.push('/mobile/login')
            }

        },
        clickGrid(index, path) {
            if (index === 3) {
                this.show = true;
                return;
            } else if(index === 4){
                this.$dialog.alert({
                    message: '正在开发中……',
                }).then(() => {
                });
                return;
            }else if (index === 5) {
                this.$router.push(path);
                return;
            }

            let token = localStorage.getItem("token");
            if (token != null) {
                this.$router.push(path);
            }else {
                this.$toast.fail("请先登录")
            }
        },
        toDetails(gameId) {
            sessionStorage.setItem('gameId', gameId);
            this.$router.push('/mobile/gameDetails');
        },
    }
}
</script>

<style scoped>

</style>
<style>
.van-button--plain {
    @apply bg-transparent !important;
}
</style>