<template>
    <div>
        <div class="max-w-screen-xl mx-auto my-0">
            <div class="w-64 bg-white float-left rounded-md mt-6">
                <ul class="px-10 pt-6">
                    <li v-for="(item,index) in list" class="w-64 h-15">
                        <span class="block hover:text-indigo-500 cursor-pointer" :class="{ sideMenu: item.path === $route.path }" @click="click(item.path)">
                            <i :class="item.icon"/>
                            <span v-text="item.text" class="px-3"></span>
                        </span>
                    </li>
                </ul>
            </div>
            <div style="width: 728px" class="bg-white float-left rounded-md ml-10 mt-6">
              <router-view />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "matter",
    data(){
        return{
            list:[
                {text: '关于我们',path:'/about'},
                {text: '用户协议',path:'/user'},
                {text: '隐私协议',path:'/privacy'},
            ]
        }
    },
    methods:{
        click(path){
            this.$router.push(path);
        }
    }
}
</script>

<style scoped>

</style>