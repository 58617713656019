import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'tailwindcss/tailwind.css'
import 'vant/lib/index.css';
import './static/css/style.css'

import {
  Button,
  Icon,
  Search,
  Image as VanImage,
  ImagePreview,
  Divider,
  Tag,
  List,
  Cell,
  CellGroup,
  Dialog,
  Toast,
  Picker,
  Popup,
  Field,
  Form,
  CountDown,
  Loading,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Overlay,
  Empty,
  NoticeBar,
  Col,
  Row,
  Lazyload,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  NavBar,
  Sidebar,
  SidebarItem,
  PullRefresh,
  Grid,
  GridItem,
  Progress,
  ActionSheet,
  ShareSheet,
  Uploader
} from 'vant';

Vue.use(ElementUI)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Search)
Vue.use(VanImage)
Vue.use(ImagePreview)
Vue.use(Divider);
Vue.use(Tag);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Form);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Overlay);
Vue.use(Empty);
Vue.use(NoticeBar);
Vue.use(Col);
Vue.use(Row);
Vue.use(Lazyload)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar)
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(PullRefresh)
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Progress)
Vue.use(ActionSheet)
Vue.use(ShareSheet)
Vue.use(Uploader)

Vue.config.productionTip = false

router.beforeEach(( to, from, next ) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
    next();
});

router.afterEach(() => {
  // NProgress.done()
})

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
