<template>
    <div>
        <div>
          <van-nav-bar
              title="开服表"
              fixed
              placeholder
              @click-left="$router.go(-1)"
              left-arrow/>
          <div class="px-3 mt-6 text-center leading-12">
              <span @click="click" class="will" :class="{ active: isCut }">即将开服</span>
              <span @click="click" class="will" :class="{ active: !isCut }">明日开服</span>
          </div>
          <div class="relative" v-show="will">
              <div>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
                  <van-cell v-for="(item,index) in willList" :key="index">
                    <ul>
                      <li>
                        <img :src="item.game_icon" class="w-12 h-12 float-left">
                        <p style="width: 195px" class="text-sm truncate pl-2">{{ item.game_name }}</p>
                        <span class="text-sm pl-2 text-red-500">{{ item.server_name }}</span>
                        <span class="float-right text-sm">{{ item.open_time }}</span>
                      </li>
                    </ul>
                  </van-cell>
                </van-list>
              </div>
          </div>
          <div class="relative" v-show="!will">
              <div>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    loading-text="拼命加载中..."
                    finished-text="没有更多了"
                    @load="onLoad2">
                  <van-cell v-for="(item,index) in tomorrowList" :key="index">
                    <ul>
                      <li>
                        <img :src="item.game_icon" class="w-12 h-12 float-left">
                        <p style="width: 195px" class="text-sm truncate pl-2">{{ item.game_name }}</p>
                        <span class="text-sm pl-2 text-red-500">{{ item.server_name }}</span>
                        <span class="float-right text-sm">{{ item.open_time }}</span>
                      </li>
                    </ul>
                  </van-cell>
                </van-list>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import {queryMobileOpenServer, queryTomorrowOpenServer} from "@/api/request";

export default {
  name: "kaiFu",
  data(){
    return{
      isCut: true,
      willList: [],
      tomorrowList:[],
      loading: false,
      finished: false,
      refreshing: false,
      will: true,
      pageNo: 1,
      pageSize: 10,
    }
  },
  methods:{
    click(e){
      let text = e.target.innerText;
      if(text === '明日开服'){
        this.onRefresh();
        this.isCut = false;
        this.will = false;
      }else {
        this.isCut = true;
        this.will = true
      }
    },
    loadData() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      queryMobileOpenServer(params).then( res => {
        if(res.data.list.length != 0){
          this.loading = false;
          this.willList = this.willList.concat(res.data.list);
          //this.tomorrowList = this.tomorrowList.concat(res.data.data.tomorrow);
          this.pageNo++;
        }
        //如果加载完毕，显示没有更多
        if(res.data.list.length == 0){
          this.finished = true;
        }
      })
    },
    onLoad(){
      setTimeout(() => {
        this.loadData();
        this.loading = true;
      },500)
    },
    loadData2() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      queryTomorrowOpenServer(params).then( res => {
        if(res.data.list.length != 0){
          this.loading = false;
          this.tomorrowList = this.tomorrowList.concat(res.data.list);
          this.pageNo++;
        }
        //如果加载完毕，显示没有更多
        if(res.data.list.length == 0){
          this.finished = true;
        }
      })
    },
    onLoad2(){
      setTimeout(() => {
        this.loadData2();
        this.loading = true;
      },500)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.tomorrowList = [];
      this.pageNo = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData2();
    },
  }
}
</script>

<style scoped>

</style>
<style>
    .will{
      @apply rounded-md h-12 inline-block w-1/2;
    }
    .active{
      @apply bg-indigo-500 text-white;
    }

</style>