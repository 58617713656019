<template>
    <div>
        <div>
          <van-nav-bar
              title="交易记录"
              fixed
              placeholder
              @click-left="$router.go(-1)"
              left-arrow/>
          <div class="px-3 mt-6 text-center leading-12">
              <span @click="click" class="buy" :class="{ active: isCut }">购买</span>
              <span @click="click" class="buy" :class="{ active: !isCut }">出售</span>
          </div>
          <div class="relative" v-show="buy">
            <div class="px-3">
              <div class="w-full rounded-lg px-3 pt-4 my-4 relative" style="background-color: #f5f5f5" v-for="item in 3">
                <div>
                  <van-image
                      width="60"
                      height="60"
                      round
                      lazy-load
                      src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                  <div class="absolute top-4 left-20">
                    <p class="text-md w-56 truncate">斗罗大陆</p>
                    <p class="text-xs">
                      <span>发布于：</span>
                      <span>2022-08-09</span>
                    </p>
                  </div>
                  <p class="float-right absolute top-4 right-4 text-indigo-500">￥12000.00</p>
                  <div>
                    <van-grid :border="false" :column-num="3" class="border-none p-0">
                      <van-grid-item v-for="(item,index) in 3" :key="index">
                        <van-image
                            lazy-load
                            src="http://www.7wan.cn/static/uploads/mobile/2021042909495612224.png" />
                      </van-grid-item>
                    </van-grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="relative" v-show="!buy">
            <div class="px-3">
              <div class="w-full rounded-lg px-3 pt-4 my-4 relative" style="background-color: #f5f5f5" v-for="item in 3">
                <div>
                  <van-image
                      width="60"
                      height="60"
                      round
                      src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                  <div class="absolute top-4 left-20">
                    <p class="text-md w-56 truncate">斗罗大陆</p>
                    <p class="text-xs">
                      <span>发布于：</span>
                      <span>2022-08-10</span>
                    </p>
                  </div>
                  <p class="float-right absolute top-4 right-4 text-indigo-500">￥2000.00</p>
                  <div>
                    <van-grid :border="false" :column-num="3" class="border-none p-0">
                      <van-grid-item v-for="(item,index) in 3" :key="index">
                        <van-image

                            src="http://www.7wan.cn/static/uploads/mobile/2021042909495612224.png" />
                      </van-grid-item>
                    </van-grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "transactionRecord",
  data(){
    return{
      isCut: true,
      buyList: [],
      sellList: [],
      loading: false,
      finished: false,
      refreshing: false,
      buy: true,
    }
  },
  methods:{
    click(e){
      let text = e.target.innerText;
      if(text === '出售'){
        this.isCut = false;
        this.buy = false;
      }else {
        this.isCut = true;
        this.buy = true
      }
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.buyList = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.buyList.push(this.buyList.length + 1);
        }
        this.loading = false;

        if (this.buyList.length >= 40) {
          this.finished = true;
        }
      }, 500);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  }
}
</script>

<style scoped>

</style>
<style>
    .buy{
      @apply rounded-md h-12 inline-block w-1/2;
    }
    .active{
      @apply bg-indigo-500 text-white;
    }

</style>