<template>
    <div v-if="pageData.game" class="my-6">
        <div class="relative max-w-screen-xl mx-auto my-0">
            <div class="bg-white rounded-lg float-left mb-6" style="padding: 40px 25px 40px 25px;width: 940px">
                <div><img :src="pageData.game.game_icon" class="w-36 h-36"></div>
                <table class="float-left absolute top-14 left-48 leading-8 text-sm w-96">
                    <tr>
                        <td colspan="4" class="text-2xl">{{ pageData.game.game_name }}</td>
                    </tr>
                    <tr>
                        <td class="t4">版本：</td>
                        <td class="t2">H5</td>
                    </tr>
                    <tr>
                        <td class="t4">类型：</td>
                        <td class="t2">
                            <span v-for="(tag,index) in pageData.game.tag.split(',')" :key="index" v-if="tag"> {{tag}} </span>
                        </td>
                    </tr>
                </table>
                <!--试玩-->
                <div class="float-right absolute top-16 left" style="left: 789px">
                    <button @click="gameDemos(pageData.game.game_url)" class="block">
                        <div class="bg-indigo-500 py-2 px-4 rounded-md text-white hover:bg-indigo-600">
                            立即试玩
                        </div>
                    </button>
                    <button @click="gameDemos(pageData.game.game_app_url)" class="block mt-4">
                        <div class="bg-indigo-500 py-2 px-8 rounded-md text-white hover:bg-indigo-600">
                            下载
                        </div>
                    </button>
                </div>
                <!--游戏介绍-->
                <p class="text-2xl text-gray-700 font-bold mt-12 border-b border-gray-600">游戏介绍</p>
                <div>
                    <p class="mt-8 text-justify leading-8" :class="{ open: isOpen }" style="text-indent: 2em">
                        {{ pageData.game.game_desc }}
                    </p>
                    <button class="float-right text-blue-400 hover:text-blue-500" @click="showText">
                        {{ isOpen ? '展开' : '收起' }}
                    </button>
                </div>
                <!--游戏视频-->
                <div v-if="pageData.detailVideo">
                    <p class="title-box">游戏视频</p>
                    <div class="mt-2" style="width: 890px;height: 506px">
                        <video  :src="pageData.detailVideo.url" :poster="pageData.detailVideo.view_img_url"
                                style="width: 100%;height: 100%;object-fit: contain"
                                controls disablePictureInPicture controlslist="noplaybackrate" id="video"></video>
                    </div>
                </div>
                <!--游戏礼包-->
                <p class="text-2xl text-gray-700 font-bold mt-12 border-b border-gray-600">游戏礼包</p>
                <div>
                    <ul>
                        <li v-for="(item,index) in pageData.gift" :key="index"
                            class="mt-10 border border-gray-300 border-dashed float-left mx-4 relative"
                            style="width: 410px">
                            <div class="leading-12 px-4">
                                <div>
                                    <span class="text-indigo-500 text-xl font-bold">{{ item.game_name }}</span>
                                    <span class="ml-4">{{ item.gift_name }}</span>
                                </div>
                                <div class="text-sm">
                                    礼包内容
                                </div>
                                <p class="text-xs">{{ item.gift_content }}</p>
                            </div>
                            <a href="javascript:" style="padding: 0 22px 0 22px;"
                               class="gift-bag text-md absolute top-13 right-4">领取礼包</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="ml-6 float-left bg-white rounded-lg" style="width: 310px">
            <div class="px-4 py-4">
                <table>
                    <tr>
                        <td class="w-24 h-24">
                            <img class="w-24 h-24" src="https://game.7li.cn/img/qili-qrcode.31a32cb1.png">
                        </td>
                        <td>
                            <div class="ml-4">
                    <span class="text-gray-500">
                        公众号搜索"七里网络"&nbsp;
                      更多福利等着你
                    </span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {queryH5Game} from '@/api/request'

export default {
    name: "H5Details",
    data() {
        return {
            isOpen: true,
            pageData: [],
            text: '',
            game: {}
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            //let gameId = this.$route.query.id;
            let gameId = sessionStorage.getItem("gameId");
            queryH5Game(gameId).then(res => {
                this.pageData = res.data.data;
                this.game = res.data.data.game;
            })
        },
        showText() {
            if (this.isOpen == true) {
                this.isOpen = false;
            } else if (this.isOpen == false) {
                this.isOpen = true;
            }
        },
        gameDemos(link) {
            let token = localStorage.getItem("token");
            if (token != null) {
                let username = localStorage.getItem("username");
                let password = localStorage.getItem("password");
                //let loginUrl = 'http://192.168.0.9:8081/#/play/1/138?from=qlWebsite&u=' + username + '&p=' + password
                let loginUrl = link + "?from=qlWebsite&u="+ username + "&p=" + password
                window.open(loginUrl, '_blank')
            } else {
                alert("您还未登录，请先登录")
            }
        },
        format(topic) {
            let ret = "";
            if (topic === 1) {
                ret = '卡牌';
            } else if (topic === 2) {
                ret = '传奇';
            } else if (topic === 3) {
                ret = '武侠';
            } else if (topic === 4) {
                ret = '角色';
            } else if (topic === 5) {
                ret = '养成';
            } else if (topic === 6) {
                ret = '三国';
            } else if (topic === 7) {
                ret = '奇迹';
            } else if (topic === 8) {
                ret = 'BT';
            }
            return ret;
        },
    },
}
</script>

<style scoped>
.open {
    @apply h-32 overflow-hidden;
}
</style>