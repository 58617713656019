<template>
    <div class="index">
        <el-container style="background-color: #f5f5f5;" class="xm:w-full w-200">
            <el-header style="height: 90px;padding: 0;">
                <!--顶部菜单-->
                <div class="menuBar" style="min-width: 975px">
                    <div class="inner">
                        <router-link to="/home"><img src="@/static/images/qili.png"
                                                     class="h-22 inline float-left"></router-link>
                        <ul class="header-nav">
                            <li class="fr" v-for="(item,index) in menuBar" :key="index" id="menu">
                                <span class="pitchOn cursor-pointer" :class="{ active: item.path === $route.path }"
                                      @click="clickMenu(item.path)">
                                  {{ item.text }}
                                </span>
                            </li>
                        </ul>
                        <div class="d-search">
                            <i @click="$router.push({name: 'searchResult'})"
                               class="el-icon-search cursor-pointer text-xl"/>
                        </div>
                        <div class="relative">
                            <div class="absolute right-6 top-3.5">
                                <el-avatar @click.native="toLogin" :src="userData.imgUrl" class="cursor-pointer"
                                           alt="头像" title="头像" icon="el-icon-s-custom"></el-avatar>
                            </div>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-main v-cloak style="padding: 0;">
                <router-view/>
            </el-main>
            <el-footer class="p-0" style="height: 165px">
                <div class="w-full bg-white">
                    <div class="mx-auto my-0" style="width: 1232px;">
                        <div class="pt-7 pb-5">
                            <a v-for="item in footer" @click="$router.push(item.path)"
                               class="mr-6 text-sm text-gray-700 hover:text-indigo-500 hover:underline"
                               style="font-size: 15px" href="javascript:" v-text="item.text"></a>
                        </div>
                        <div class="text-gray-500">
                            <p style="font-size: 15px">©2021 7li.cn/ All Rights Resered.
<!--                                <a class="hover:underline hover:text-gray-800" href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备20015231号-2</a>-->
                                <a class="hover:underline hover:text-gray-800" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2021003061号-1</a>
                            </p>
                            <img src="@/static/images/gonganbu.png" class="inline-block mr-1"/>
                            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004716" class="hover:underline hover:text-gray-800">川公网安备
                                51019002004716号</a>
                            <p style="font-size: 15px">
                                抵制不良网页游戏，拒绝盗版游戏。
                                注意自我保护，谨防受骗上当。
                                适度游戏益脑，沉迷游戏伤身。
                                合理安排时间，享受健康生活。</p>
                            <p>战略合作：4000818979 按 1 转接</p>
                        </div>
                    </div>
                </div>
            </el-footer>
        </el-container>
        <!-- 登录弹框 -->
        <el-dialog
            :visible.sync="loginVisible"
            :before-close="handleClose"
            width="480px">
            <div v-show="login">
                <div class="text-3xl font-bold text-gray-700 text-center">登录</div>
                <div class="px-10 py-4">
                    <div class="px-9 w-90">
                        <input type="text" v-model="user.userName" class="input" maxlength="12"
                               placeholder="请输入账号"></input>
                        <span ref="account" class="text-red-500 absolute text-xs"></span>

                        <input type="password" v-model="user.userPwd" v-on:keyup.enter="submit" class="input mt-4"
                               maxlength="12" placeholder="请输入密码"></input>
                        <span ref="pwd" class="text-red-500 absolute text-xs"></span>

                        <!-- 忘记密码 -->
                        <div class="mt-5 text-sm text-gray-600">
                            <button @click="cut" class="hover:text-indigo-500 hover:underline">注册账号</button>
                            <button @click="cut" class="hover:text-indigo-500 float-right hover:underline">忘记密码</button>
                        </div>
                        <div class="pt-4">
                            <button @click="submit" class="login">登录</button>
                        </div>
                        <!-- 用户协议 -->
                        <label class="hover:text-blue-500 text-blue-400 cursor-pointer">
                            <ul>
                                <li class="inline-block mt-2 mr-1">
                                    <input type="checkbox" v-model="consent" neme="deal"/>
                                </li>
                                <li class="inline-block">
                                  <span class="text-xs">
                                    <span class="text-gray-500">我已阅读并同意</span>
                                    <a target="_blank" href="https://game.7li.cn/static/sdk/user.html"
                                       class="text-sm hover:underline">《用户协议》</a>
                                    <span class="text-gray-500">&</span>
                                    <a target="_blank" href="https://game.7li.cn/static/download/privacy.html"
                                       class="text-sm hover:underline">《隐私政策》</a>
                                  </span>
                                </li>
                            </ul>
                        </label>
                    </div>
                </div>
            </div>
            <div v-show="register">
                <div class="absolute top-5">
                    <i class="el-icon-arrow-left text-xl cursor-pointer" @click="login=true;register=false"/>
                </div>
                <div class="text-3xl font-bold text-gray-700 text-center">注册</div>
                <div class="px-10 py-4 relative">
                    <div class="px-9 w-90">
                        <input type="text" v-model="registerAccount.userName" class="input" maxlength="12"
                               placeholder="请输入账号"></input>
                        <span ref="userName" class="text-xs absolute left-20 top-15 text-red-600"></span>

                        <input type="password" v-model="registerAccount.userPwd" class="input mt-5" maxlength="12"
                               placeholder="请输入密码"></input>
                        <span ref="userPwd" class="text-xs absolute left-20 text-red-600" style="top: 125px"></span>

                        <input type="password" v-model="registerAccount.confirmPwd" class="input mt-5" maxlength="12"
                               placeholder="确认密码"></input>
                        <span ref="confirmPwd" class="text-xs text-red-600 absolute left-20" style="top: 189px"></span>

                        <div class="pt-5">
                            <button @click="toRegister" class="login">注册</button>
                        </div>
                        <!-- 用户协议 -->
                        <label class="text-blue-500 cursor-pointer">
                            <ul>
                                <li class="inline-block mt-2 mr-1">
                                    <input type="checkbox" v-model="consent" neme="deal"/>
                                </li>
                                <li class="inline-block">
                              <span class="text-xs">
                                <span class="text-gray-500">我已阅读并同意</span>
                                <a target="_blank" href="https://game.7li.cn/static/sdk/user.html"
                                   class="text-sm hover:underline">《用户协议》</a>
                                <span class="text-gray-500">&</span>
                                <a target="_blank" href="https://game.7li.cn/static/download/privacy.html"
                                   class="text-sm hover:underline">《隐私政策》</a>
                              </span>
                                </li>
                            </ul>
                        </label>
                    </div>
                </div>
            </div>
            <div v-show="reset">
                <div class="absolute top-5">
                    <i class="el-icon-arrow-left text-xl cursor-pointer" @click="login=true;reset=false"/>
                </div>
                <div class="text-3xl font-bold text-gray-700 text-center">找回密码</div>
                <div class="px-10 py-4">
                    <div class="px-9 w-90">
                        <input v-model="resetPwd.userName" type="text" class="input" maxlength="12"
                               placeholder="请输入账号"></input>
                        <p class="text-xs absolute text-red-600" ref="resetUserName"></p>

                        <input v-model="resetPwd.userPwd" type="password" class="input mt-5" maxlength="12"
                               placeholder="请输入密码"></input>
                        <p class="text-xs absolute text-red-600" ref="resetUserPwd"></p>

                        <input v-model="resetPwd.userPwd2" type="password" class="input mt-5" maxlength="12"
                               placeholder="确认密码"></input>
                        <p class="text-xs absolute text-red-600" ref="resetUserPwd2"></p>

                        <input v-model="resetPwd.phone" type="text" maxlength="11" class="input mt-5"
                               placeholder="请输入手机号"/>
                        <span ref="phone" class="text-red-600 text-xs absolute bottom-48 left-24"></span>

                        <input v-model="resetPwd.verifyCode" type="text" maxlength="6" class="input mt-5"
                               placeholder="请输入验证码"/>
                        <button @click="sendVerifyCode" v-show="btnBool" type="button" class="absolute text-blue-500"
                                style="bottom: 155px;right: 110px">获取验证码
                        </button>
                        <span class="absolute right-28 text-blue-500" style="bottom: 155px" v-if="!btnBool">{{countDown}}</span>
                        <div class="pt-5">
                            <button @click="toReset" class="login">确认重置</button>
                        </div>
                        <div class="text-center text-sm mt-2">
                            <span>如有疑问，请</span>
                            <a href="javascript:"
                               @click="$router.push('/service');reset=false;loginVisible=false;login=true"
                               class="text-blue-500">联系客服</a>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {doLogin, doRegister, doReset, sendResetPwdVerifyCode} from "@/api/request"
import crypto from "@/utils/crypto";

export default {
    name: "index",
    data() {
        return {
            loginVisible: false,
            input: '',
            current: 0,
            btnCurrent: 0,
            hotGame: true,
            walkthrough: false,
            recommend: true,
            foreshow: false,
            consent: false,
            login: true,
            register: false,
            reset: false,
            btnBool: true,
            countDown: '',
            user: {
                userName: '',
                userPwd: ''
            },
            registerAccount: {
                userName: '',
                userPwd: '',
                confirmPwd: ''
            },
            resetPwd: {
                userName: '',
                userPwd: '',
                userPwd2: '',
                verifyCode: '',
                phone: ''
            },
            menuBar: [
                {text: '首页', path: '/home'},
                {text: '手游中心', path: '/mobileGame'},
                {text: 'H5游戏', path: '/h5'},
                {text: '礼包', path: '/gift'},
                {text: 'App下载', path: '/app'},
                {text: '客服', path: '/service'},
            ],
            footer: [
                {text: '关于我们', path: '/about'},
                {text: '用户协议', path: '/user'},
                {text: '隐私政策', path: '/privacy'}
            ],
            userData: [],
            regPhone: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
        }
    },
    mounted() {
      this.$root.$on("not_log_in",() => {
          this.loginVisible = true;
      });
    },
    methods: {
        clickMenu(path) {
            this.$router.push(path);
        },
        submit() {
            if (!/^[A-Za-z0-9]{6,12}$/.test(this.user.userName)) {
                this.$refs.account.style.display = 'block';
                this.$refs.account.innerText = '账号为6-12位字母数字组成';
            } else if (!/^.{6,12}$/.test(this.user.userPwd)) {
                this.$refs.pwd.style.display = 'block';
                this.$refs.pwd.innerText = '密码为6-12位';
            } else if (this.consent) {
                let params = {
                    userName: this.user.userName,
                    userPwd: crypto.encrypt(this.user.userPwd)
                }
                doLogin(params).then(res => {
                    if (res.data.success) {
                        localStorage.setItem("token", res.data.data.token);
                        localStorage.setItem("userInfo", JSON.stringify(res.data.data));
                        localStorage.setItem("username", this.user.userName);
                        localStorage.setItem("password", crypto.encrypt(this.user.userPwd));
                        this.userData = res.data.data;
                        this.loginVisible = false;
                        this.user = {
                            userName: '',
                            userPwd: '',
                        }
                    } else {
                        alert(res.data.error.message)
                    }
                })
            } else {
                alert("请阅读并勾选底部的协议")
            }
        },
        toLogin() {
            let token = localStorage.getItem("token");
            if (token == null) {
                this.loginVisible = true;
            } else {
                this.$router.push("/my")
            }
        },
        toRegister() {
            if (!/^[A-Za-z0-9]{6,12}$/.test(this.registerAccount.userName)) {
                this.$refs.userName.style.display = 'block';
                this.$refs.userName.innerText = '账号为6-12位字母数字组成';
            } else if (!/^.{6,12}$/.test(this.registerAccount.userPwd)) {
                this.$refs.userPwd.style.display = 'block';
                this.$refs.userPwd.innerText = '密码为6-12位';
            } else if (!/^.{6,12}$/.test(this.registerAccount.confirmPwd)) {
                this.$refs.confirmPwd.style.display = 'block';
                this.$refs.confirmPwd.innerText = '密码长度为6-12位';
            } else if (this.registerAccount.confirmPwd !== this.registerAccount.userPwd) {
                this.$refs.confirmPwd.style.display = 'block';
                this.$refs.confirmPwd.innerText = '两次密码不一致';
            } else if (this.consent == false) {
                alert("请阅读并勾选底部的协议")
            } else {
                this.$refs.userName.style.display = 'none';
                this.$refs.userPwd.style.display = 'none';
                this.$refs.confirmPwd.style.display = 'none';

                let params = {
                    userName: this.registerAccount.userName,
                    userPwd: crypto.encrypt(this.registerAccount.userPwd),
                }
                doRegister(params).then(res => {
                    if (res.data.success) {
                        alert(res.data.data)
                        this.loginVisible = false;
                        this.register = false;
                        this.login = true;
                        this.registerAccount = {
                            userName: '',
                            userPwd: '',
                            confirmPwd: ''
                        }
                    } else {
                        alert(res.data.error.message)
                    }
                })
            }
        },
        toReset() {
            if (!/^[A-Za-z0-9]{6,12}$/.test(this.resetPwd.userName)) {
                this.$refs.resetUserName.style.display = 'block';
                this.$refs.resetUserName.innerText = '账号为6-12位字母数字组成';
            } else if (!/^.{6,12}$/.test(this.resetPwd.userPwd)) {
                this.$refs.resetUserPwd.style.display = 'block';
                this.$refs.resetUserPwd.innerText = '密码为6-12位';
            } else if (!/^.{6,12}$/.test(this.resetPwd.userPwd2)) {
                this.$refs.resetUserPwd2.style.display = 'block';
                this.$refs.resetUserPwd2.innerText = '密码长度为6-12位';
            } else if (this.resetPwd.userPwd2 !== this.resetPwd.userPwd) {
                this.$refs.resetUserPwd2.style.display = 'block';
                this.$refs.resetUserPwd2.innerText = '两次密码不一致';
            } else if (!this.regPhone.test(this.resetPwd.phone)) {
                this.$refs.phone.style.display = 'block';
                this.$refs.phone.innerText = '请输入正确的手机号';
            } else {
                this.$refs.resetUserName.style.display = 'none';
                this.$refs.resetUserPwd.style.display = 'none';
                this.$refs.resetUserPwd2.style.display = 'none';
                this.$refs.phone.style.display = 'none';
                let params = {
                    userName: this.resetPwd.userName,
                    userPwd: crypto.encrypt(this.resetPwd.userPwd),
                    verifyCode: this.resetPwd.verifyCode,
                    phone: this.resetPwd.phone
                }
                doReset(params).then(res => {
                    if (res.data.success) {
                        alert("修改成功");
                        this.loginVisible = false;
                        this.reset = false;
                        this.login = true;
                        this.resetPwd = {
                            userName: '',
                            userPwd: '',
                            userPwd2: '',
                            verifyCode: '',
                            phone: ''
                        }
                    } else {
                        alert(res.data.error.message);
                    }
                })
            }
        },
        sendVerifyCode() {
            let phone = this.regPhone.test(this.resetPwd.phone);
            if (phone) {
                let params = {
                    phone: this.resetPwd.phone,
                    userName: this.resetPwd.userName
                }
                sendResetPwdVerifyCode(params).then(res => {
                    if (res.data.success) {
                        let time = 120;
                        let timer = setInterval(() => {
                            this.btnBool = false;
                            this.countDown = time + "s";
                            time--;
                            if (time < 0) {
                                this.btnBool = true;
                                clearInterval(timer);
                            }
                        }, 1000);
                    } else {
                        alert(res.data.error.message)
                    }
                }).catch(() => {
                })
                this.$refs.phone.style.display = 'none';
            } else {
                this.$refs.phone.style.display = 'block';
            }
        },
        cut(e) {
            let text = e.target.innerText
            if (text === '注册账号') {
                this.login = false;
                this.register = true;
            }
            if (text === '忘记密码') {
                this.login = false;
                this.reset = true;
            }
        },
        handleClose() {
            this.loginVisible = false;
            this.login = true;
            this.register = false;
            this.reset = false;
        },
    },
}
</script>
<style>
.el-dialog {
    @apply rounded-xl;
}

[v-cloak] {
    display: none;
}


.index {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.el-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}
.el-main {
    height: 100%;
    overflow: hidden;
}
.el-footer {
    display: flex;
    width: 100%;
    align-items: center;
}
</style>