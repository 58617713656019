<template>
    <div>
        <div class="my-6">
            <!--导航栏-->
            <div class="nav" style="height: 144px">
                <div class="py-6 px-10 text-md">
                    <div class="my-4">
                        <span>游戏类型：</span>
                        <span class="pl-8" v-for="(type,index) in gameType">
                            <a href="javascript:" @click="clickType(type,index)" class="condition" :class="{ active:index == typeCurrent }" name="type">{{type}}</a>
                        </span>
                    </div>
                    <div class="letter">
                        <span>游戏首字母：</span>
                        <span class="pl-4" v-for="(zm,index) in letter">
                            <a href="javascript:" @click="clickLetter(zm,index)" class="condition" :class="{ active:index == letterCurrent }" name="letter">{{zm}}</a>
                        </span>
                    </div>
                </div>
                <div class="search top-2 right-16">
                    <input type="text" v-model="query.game_name" v-on:keyup.enter="loadData(1)" placeholder="搜索游戏"/>
                    <i @click="loadData(1)" class="el-icon-search cursor-pointer absolute right-2" style="top: 33px"/>
                </div>
            </div>
            <!--内容区-->
            <div class="phone">
                <div v-show="show" class="text-3xl font-bold text-center my-38">暂无游戏</div>
                <ul class="phone-games">
                    <li v-for="(item,index) in pageData" :key="index" class="phoneList">
                        <a href="javascript:" @click="toDetail(item.game_id)" class="float-left absolute">
                            <img :src="item.game_icon" class="w-30 h-30">
                        </a>
                        <a href="javascript:" @click="toDetail(item.game_id)" class="ml-6 float-left absolute left-30">{{ item.game_name }}</a>
                        <table class="phone-table left-36 top-14">
                            <tr>
                                <td class="t1">版本：</td>
                                <td class="t2">H5</td>
                            </tr>
                            <tr>
                                <td class="t1">类型：</td>
                                <td class="t2">
                                  <span v-for="(tag,index) in item.tag.split(',')" :key="index" v-if="tag"> {{ tag }} </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="text-gray-700 ">
                                    <p class="overflow-hidden truncate" style="width: 230px" v-text="item.game_desc"></p>
                                </td>
                            </tr>
                        </table>
                        <div class="w-33 h-26 float-left absolute right-11 top-12 z-30">
                            <a href="javascript:" @click="toDetail(item.game_id)">
                                <span class="game-info">游戏详情</span>
                            </a>
                            <div class="mt-4">
                              <button @click="download(item.game_url)" class="download-game" type="button">立即试玩</button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="clear"></div>
            </div>
            <!--分页区-->
            <div class="paging">
                <div class="mx-auto my-7 w-1/3">
                  <el-pagination
                      @current-change="loadData"
                      background
                      :page-size= "16"
                      :current-page="currentIndex"
                      layout="total, prev, pager, next"
                      :total="total">
                  </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getH5List } from '@/api/request'
    import crypto from "@/utils/crypto";

    export default {
        name: "H5",
        data(){
            return{
                show: false,
                typeCurrent: 0,
                letterCurrent: 0,
                currentIndex: 1,
                total: 0,
                gameType: ['不限','卡牌','传奇','武侠','角色','养成','三国','奇迹','BT'],
                letter: ['不限','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
                query: {
                    game_name: '',
                    game_topic: '',
                    game_short_name: ''
                },
                pageData:[],
            }
        },
      mounted() {
          this.loadData(1);
      },
      methods:{
            loadData(index){
                let params = {
                  params: this.query,
                  pageNo: index,
                  pageSize: 16,
                }

                getH5List(params).then( res => {
                    this.pageData = res.data.list;
                    if(this.pageData.length == 0){
                        this.show = true;
                    }else {
                        this.show = false;
                    }
                    this.total = res.data.total;
                    this.currentIndex = index;
                })
            },
            clickType(topic,index){
                this.typeCurrent = index;
                this.query.game_topic = this.formatTopic(topic);
                this.loadData(1);
            },
            clickLetter(zm,index){
                if(zm == '不限'){
                  zm = '';
                }
                this.letterCurrent = index;
                this.query.game_short_name = zm.toLowerCase()
                this.loadData(1);
            },
            download(link){
              let token = localStorage.getItem("token");
              if(token != null){
                let username = localStorage.getItem("username");
                let password = localStorage.getItem("password");
                //let loginUrl = 'http://192.168.0.9:8081/#/play/1/253?from=qlWebsite&u=' + username + '&p=' + password
                let loginUrl = link + '?from=qlWebsite&u=' + username + '&p=' + password
                window.open(loginUrl,'_blank')
              }else {
                alert("您还未登录，请先登录")
              }
            },
            toDetail(id) {
                this.$router.push({name: 'H5Details',query:{id: id}});
                sessionStorage.setItem("gameId",id)
            },
            formatTopic(type){
              let ret = "";
              if (type === '卡牌') {
                ret = 1;
              } else if (type === '传奇') {
                ret = 2;
              } else if (type === '武侠') {
                ret = 3;
              }else if(type === '角色'){
                ret = 4
              }else if(type === '养成'){
                ret = 5;
              }else if(type === '三国'){
                ret = 6;
              }else if(type === '奇迹'){
                ret = 7;
              }else if(type === 'BT'){
                ret = 8;
              }else if(type === '不限'){
                ret = '';
              }
              return ret;
            },
            format(topic){
              let ret = "";
              if (topic === 1) {
                ret = '卡牌';
              } else if (topic === 2) {
                ret = '传奇';
              } else if (topic === 3) {
                ret = '武侠';
              }else if(topic === 4){
                ret = '角色';
              }else if(topic === 5){
                ret = '养成';
              }else if(topic === 6){
                ret = '三国';
              }else if(topic === 7){
                ret = '奇迹';
              }else if(topic === 8){
                ret = 'BT';
              }
              return ret;
            },
        }
    }
</script>

<style scoped>

</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  @apply bg-indigo-500 text-white !important;
}

.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  @apply rounded-md !important;
  @apply hover:text-indigo-500 !important;
}
</style>