<template>
    <div>
        <div>
          <van-nav-bar
              title="我的礼包"
              @click-left="$router.go(-1)"
              fixed
              :placeholder="true"
              left-arrow/>

          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                loading-text="拼命加载中..."
                finished-text="没有更多了"
                @load="onLoad">
              <van-cell v-for="(item,index) in pageData" :key="index">
                <img :src="item.game_icon" class="w-16 h-16 float-left">
                <div class="absolute left-20 leading-normal">
                  <div class="text-md w-38 truncate">{{ item.game_name }}</div>
                  <div class="text-xs w-38 truncate text-gray-500">
                    <span>礼包码：</span>
                    <span id="span">{{ item.gift_code }}</span>
                  </div>
                  <div class="text-gray-500 text-xs">
                    <span>领取时间：</span>
                    <span>{{ item.gift_collection_time }}</span>
                  </div>
                </div>
                <div>
                  <van-button size="small" class="border-indigo-500 absolute top-5 right-2 text-indigo-500 rounded-md w-14" plain @click="copy">复制</van-button>
                </div>
              </van-cell>
            </van-list>
          </van-pull-refresh>


        </div>
    </div>
</template>

<script>

import { getMyGift } from "@/api/request";

export default {
  name: "mobileMyGift",
  data(){
    return{
        userId: '',
        pageData: [],
        pageNo: 1,
        pageSize: 10,
        loading: false, // 当loading为true时，转圈圈
        finished: false, // 数据是否请求结束，结束会先显示-我也是我底线的
        refreshing: false,
    }
  },
  mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.userId = user.userId;
  },
  methods:{
      loadData() {
        if (this.refreshing) {
          this.pageData = [];
          this.refreshing = false;
        }
        let params = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          params: {
            userId: this.userId,
          }
        }
        getMyGift(params).then( res => {
          if(res.data.list.length != 0){
            this.loading = false;
            this.pageData = this.pageData.concat(res.data.list);
            this.pageNo++;
          }
          //如果加载完毕，显示没有更多
          if(res.data.list.length == 0){
            this.finished = true;
          }
        })
      },
      onLoad(){
        setTimeout(() => {
          this.loadData();
          this.loading = true;
        },500)
      },
      onRefresh() {
        // 清空列表数据
        this.finished = false;
        this.pageData = [];
        this.pageNo = 1;
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.loadData();
      },
      copy(){
          let text = document.getElementById('span').innerText;
          let element = document.createElement('input');
          element.value = text;
          document.body.appendChild(element);
          element.select();//选择对象
          document.execCommand('copy');//执行浏览器复制命令
          element.className = 'element';
          element.style.display = 'none';
          document.body.removeChild(element);
          this.$toast.success('复制成功');
      }
  }
}
</script>

<style scoped>

</style>