<template>
    <div>
        <div class="py-5 px-7 relative" v-cloak>
            <div class="text-lg text-gray-700 pb-3 border-b border-gray-500">账号安全</div>
            <table style="width: 672px">
                <tr>
                    <td>
                        <span>绑定手机</span>
                    </td>
                    <td>
                      <div v-if="!isBind">
                        <span>未绑定</span>
                      </div>
                    </td>
                    <td>
                        <div v-if="!isBind" style="width: 300px">
                            <button @click="bindVisible = true" class="float-right text-red-600 font-bold">去绑定</button>
                        </div>
                        <div v-if="isBind" style="width: 300px">
                          <button @click="bindVisible = true" class="float-right text-blue-500">已绑定</button>
                        </div>
                    </td>
                </tr>
                <tr class="h-10">
                    <td>
                        <span>实名认证</span>
                    </td>
                    <td>
                        <div v-if="!isAuth">
                            <span>未认证</span>
                        </div>
                    </td>
                    <td >
                      <div v-if="!isAuth" style="width: 300px">
                        <button @click="authVisible = true" class="float-right text-red-600 font-bold">去认证</button>
                      </div>
                      <div v-if="isAuth" style="width: 300px">
                        <button @click="authVisible = true" class="float-right text-blue-500">已认证</button>
                      </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button @click="resetVisible = true" class="text-indigo-500 hover:text-indigo-400">修改密码</button>
                    </td>
                </tr>
            </table>
        </div>
        <!-- 绑定手机弹框 -->
        <el-dialog
          title="绑定手机"
          :visible.sync="bindVisible"
          width="480px"
          :before-close="handleClose">
        <p class="mb-4 text-center">{{ bindPhoneText }}</p>
        <table class="relative">
          <tr>
            <td>
              <span>手机号码:</span>
            </td>
            <td>
              <input maxlength="11" placeholder="请输入手机号，必填项" class="edit text-black" v-model="phone"/>
              <span ref="phone" class="text-red-600 text-xs absolute" style="display: none">请输入正确的手机号</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>验证码:</span>
            </td>
            <td class="py-4 relative">
              <input placeholder="请输入验证码，必填项" maxlength="6" class="edit text-black" v-model="verifyCode"/>
              <span ref="verify" class="text-red-600 text-xs absolute" style="display: none">请输入正确的验证码</span>
              <button type="button" v-show="btnBool" @click="cutFun ? sendVerificationCode() : sendUnbindVerifyCode()" class="absolute right-3 top-7">发送验证码</button>
              <span class="absolute right-3 top-7" v-if="!btnBool">{{ countDown }}</span>
            </td>
          </tr>
        </table>
        <span slot="footer" class="dialog-footer" style="display: block">
            <button class="border border-gray-500 px-4 py-2 mr-2 rounded-md active:text-indigo-600 active:border-indigo-500" @click="bindVisible = false">取 消</button>
            <button class="bg-indigo-500 text-white px-4 py-2 rounded-md active:bg-indigo-600" @click="cutFun ? handleBind() : handleUnbind()">确 定</button>
        </span>
      </el-dialog>
        <!-- 实名认证弹框-->
        <el-dialog
          title="实名认证"
          :visible.sync="authVisible"
          width="480px"
          :before-close="handleClose">
        <p class="mb-4 text-center">为响应国家法律法规相关要求，您需要进行实名认证</p>
        <table>
          <tr>
            <td>
              <span>真实姓名:</span>
            </td>
            <td>
              <input placeholder="请输真实入姓名，必填项" class="edit text-black" v-model="realName"/>
              <p ref="real" class="text-red-600 text-xs absolute" style="display: none">请输入正确的姓名</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>身份证号:</span>
            </td>
            <td class="py-4">
              <input placeholder="请输入身份证号，必填项" maxlength="18" class="edit text-black" v-model="cardNo"/>
              <p ref="card" class="text-red-600 text-xs absolute" style="display: none">请输入正确的身份证号</p>
            </td>
          </tr>
        </table>
        <p class="text-center">实名认证仅作为防沉迷功能，不做其他用途</p>
        <span ref="btn" slot="footer" class="dialog-footer">
            <button class="border border-gray-500 px-4 py-2 mr-2 rounded-md active:text-indigo-600 active:border-indigo-500" @click="authVisible = false">取 消</button>
            <button class="bg-indigo-500 text-white px-4 py-2 rounded-md active:bg-indigo-600" @click="handleUpdateAuth">确 定</button>
        </span>
      </el-dialog>
        <!-- 修改密码弹窗 -->
        <el-dialog
            title="修改密码"
            :visible.sync="resetVisible"
            width="480px"
            :before-close="handleClose">
            <table class="relative">
                <tr>
                    <td>
                        <span style="letter-spacing:7px">原密码:</span>
                    </td>
                    <td>
                        <input type="password" v-model="oldPwd" placeholder="密码长度为6-12位" maxlength="12" class="edit"/>
                        <span class="absolute text-red-600 text-xs" style="left: 100px;top: 45px" ref="oldText"></span>
                    </td>
                </tr>
                <tr>
                    <td >
                        <span style="letter-spacing:7px">新密码:</span>
                    </td>
                    <td class="py-4">
                        <input type="password" v-model="newPwd" placeholder="密码长度为6-12位" maxlength="12" class="edit"/>
                        <span class="absolute text-red-600 text-xs" style="left: 100px;top: 107px" ref="newPwd"></span>
                    </td>
                </tr>
                <tr>
                  <td>
                    <span>确认新密码:</span>
                  </td>
                  <td>
                    <input type="password" v-model="newPwd2" placeholder="密码长度为6-12位" maxlength="12" class="edit"/>
                    <span class="absolute text-red-600 text-xs" style="left: 100px;bottom: -15px" ref="newPwd2"></span>
                  </td>
                </tr>
            </table>

            <span slot="footer" class="dialog-footer">
                <button class="border border-gray-500 px-4 py-2 mr-2 rounded-md active:text-indigo-600 active:border-indigo-500" @click="resetVisible = false">取 消</button>
                <button class="bg-indigo-500 text-white px-4 py-2 rounded-md active:bg-indigo-600" @click="editPwd">确 定</button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { updateAuth, sendBindPhoneVerifyCode , bindPhone, getUserInfo, sendUnbindPhoneVerifyCode,unBindPhone,changePwd } from '@/api/request'
import crypto from "@/utils/crypto";

export default {
    name: "security",
    data(){
        return{
            resetVisible: false,
            authVisible: false,
            bindVisible: false,
            isAuth: false,
            isBind: false,
            btnBool: true,
            cutFun: true,
            userId: '',
            userName: '',
            cardNo: '',
            realName: '',
            phone: '',
            verifyCode: '',
            oldPwd: '',
            newPwd: '',
            newPwd2: '',
            countDown: '',
            regPhone: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
            bindPhoneText: '',
            userData:[],
        }
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem("userInfo"));
        this.userId = user.userId;
        this.userName = user.userName;
        this.loadData();
    },
    methods:{
          loadData(){
            getUserInfo(this.userName).then( res => {
              this.userData = res.data;
              this.phone = this.userData.phone;
              this.cardNo = this.userData.card_no;
              if(this.phone != null && this.phone != ""){
                this.isBind = true;
                this.cutFun = false;
                this.phone = this.phone.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2");
                this.bindPhoneText = "如果当前手机号码不再使用，请及时解绑并绑定新手机号码";
              }else {
                this.isBind = false;
                this.bindPhoneText = "暂时只支持中国大陆手机号码";
              }

              if(this.cardNo != null && this.cardNo != ""){
                this.isAuth = true;
                this.realName = this.firstName(this.userData.real_name);
                const idCard = (data) => {
                  return data.replace(/(.{6}).*(.{4})/, "$1********$2")
                }
                this.cardNo = idCard(this.cardNo);
                this.$refs.btn.style.display = 'none';
              }else {
                this.isAuth = false;
              }
            })
          },
          //解绑手机号
          handleUnbind(){
            let phone = this.regPhone.test(this.phone);
            if(phone){
              let params = {
                userId: this.userId,
                phone: this.phone,
                verifyCode:this.verifyCode
              }
              unBindPhone(params).then( res => {
                if(res.data.success){
                  alert("解绑成功");
                  this.bindVisible = false
                  this.verifyCode = '';
                  this.phone = '';
                  this.bindPhoneText = "暂时只支持中国大陆手机号"
                  location.reload();
                }else {
                  alert(res.data.error.message);
                  this.bindPhoneText = "如果当前手机号码不再使用，请及时解绑并绑定新手机号码";
                }
              })
            }else {
              alert("请输入正确的手机号码")
            }
          },
          handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                  done();
                })
                .catch(_ => {});
          },
          //绑定手机号
          handleBind(){
            let phone = this.regPhone.test(this.phone);
            if(phone){
                let params = {
                  userId: this.userId,
                  phone: this.phone,
                  verifyCode:this.verifyCode
                }
                bindPhone(params).then( res => {
                    if(res.data.success){
                      alert("绑定成功");
                      this.bindVisible = false
                      this.phone = this.userData.phone.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2");
                      this.verifyCode = '';
                      this.btnBool = true;
                      location.reload();
                    }else {
                      alert(res.data.error.message);
                    }
                })
            }else {
              alert("请输入正确的手机号码")
            }
          },
          //发送手机绑定验证码
          sendVerificationCode(){
            let phone = this.regPhone.test(this.phone);
            if(phone){
                let time = 120;
                let timer = setInterval(() => {
                  this.btnBool = false;
                  this.countDown = time + "s";
                  time--;
                  if (time < 0) {
                    this.btnBool = true;
                    clearInterval(timer);
                  }
                }, 1000);

                sendBindPhoneVerifyCode(this.phone).then( res => {
                  if(!res.data.success) {
                    alert(res.data.error.message)
                  }
                }).catch( () => {
                })
              this.$refs.phone.style.display = 'none';
            }else {
                this.$refs.phone.style.display = 'block';
            }
          },
          //发送手机解绑验证码
          sendUnbindVerifyCode(){
            let phone = this.regPhone.test(this.phone);
            if(phone){
              let time = 120;
              let timer = setInterval(() => {
                this.btnBool = false;
                this.countDown = time + "s";
                time--;
                if (time < 0) {
                  this.btnBool = true;
                  clearInterval(timer);
                }
              }, 1000);

              sendUnbindPhoneVerifyCode(this.phone).then( res => {
                if(!res.data.success) {
                  alert(res.data.error.message)
                }
              }).catch( () => {
              })
              this.$refs.phone.style.display = 'none';
            }else {
              this.$refs.phone.style.display = 'block';
            }
          },
          //处理实名认证
          handleUpdateAuth(){
            let regCard = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[0-9Xx]$/;
            let regName = /^[\u4E00-\u9FA5·]{2,16}$/;
            let card = regCard.test(this.cardNo);
            let real = regName.test(this.realName);
            if(card && real){
              let params = {
                userId: this.userId,
                realName: this.realName,
                cardNo: this.cardNo,
              }
              updateAuth(params).then( res => {
                if(res.data.success){
                  alert(res.data.data)
                  location.reload();
                  this.$refs.btn.style.display = 'none'
                }else {
                  alert(res.data.error.message)
                }
                this.authVisible = false;
              })
              this.$refs.card.style.display = 'none';
              this.$refs.real.style.display = 'none';
            }else {
              this.$refs.card.style.display = 'block';
              this.$refs.real.style.display = 'block';
            }
          },
          //修改密码
          editPwd(){
            let reg = /^.{6,12}$/;
            if(!reg.test(this.oldPwd)){
              this.$refs.oldText.style.display = 'block';
              this.$refs.oldText.innerText = "密码长度为6-12位";
            }else if(!reg.test(this.newPwd)){
              this.$refs.newPwd.style.display = 'block';
              this.$refs.newPwd.innerText = "密码长度为6-12位";
            }else if(!reg.test(this.newPwd2)){
              this.$refs.newPwd2.style.display = 'block';
              this.$refs.newPwd2.innerText = "密码长度为6-12位";
            }else if(this.newPwd2 !== this.newPwd){
              this.$refs.newPwd2.style.display = 'block';
              this.$refs.newPwd2.innerText = "两次密码不一致";
            }else {
              this.$refs.oldText.style.display = 'none';
              this.$refs.newPwd.style.display = 'none';
              this.$refs.newPwd2.style.display = 'none';

              let params = {
                userId: this.userId,
                userPwd: crypto.encrypt(this.oldPwd),
                newPwd: crypto.encrypt(this.newPwd)
              }
              changePwd(params).then( res => {
                if(res.data.success){
                  alert(res.data.data)
                  localStorage.removeItem("token")
                  localStorage.removeItem("userInfo")
                  this.$router.replace("/home")
                }else {
                  alert(res.data.error.message)
                }
              })
            }
          },
          //如果实名认证了就把姓名后几位变成*
          firstName(name){
              let newStr;
              if (name.length === 2) {
                newStr = name.substr(0, 1) + '*';
              } else if (name.length > 2) {
                let char = '';
                for (let i = 0, len = name.length - 1; i < len; i++) {
                  char += '*';
                }
                newStr = name.substr(0, 1) + char;
              } else {
                newStr = name;
              }
              return newStr;
          },
    }
}
</script>

<style scoped>
    table tr td:nth-child(even){
        @apply pl-6 text-blue-500;
    }
</style>
<style>
  [v-clock]{
    display: none;
  }
</style>