<template>
    <div v-cloak>
        <div class="max-w-screen-lg mx-auto my-0 relative">
            <div class="bg-white h-40 px-10 py-8 rounded-lg">
                <div class="float-left">
                    <img class="rounded-full h-24 w-24" :src="userData.img_url"/>
                    <!--<img class="rounded-full h-24 w-24" :src=""/>-->
                </div>
                <div>
                    <div class="mt-6">
                        <span class="ml-4 text-xl font-bold">{{ userData.nick_name }}</span>
                    </div>
                    <div class="text-sm text-gray-400">
                        <span class="ml-4">账号:</span>
                        <span>{{ userData.user_name }}</span>
                    </div>
                    <div class="float-right absolute top-7 right-10">
                        <button type="button" @click="toLogout" class="personage-center-edit">退出</button>
                    </div>
                </div>
            </div>
            <div class="w-64 bg-white float-left rounded-md mt-6">
                <ul class="px-10 pt-6">
                    <li v-for="(item,index) in list" :key="index" class="w-64 h-15">
                        <span class="block hover:text-indigo-500 cursor-pointer"
                              :class="{ sideMenu: item.path === $route.path }" @click="clickSideMenu(item.path)">
                            <i :class="item.icon"/>
                            <span v-text="item.text" class="px-3"></span>
                        </span>
                    </li>
                </ul>
            </div>
            <div style="width: 728px" class="bg-white float-left rounded-md ml-10 mt-6">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
import {logout, getUserInfo} from '@/api/request'

export default {
    name: "personageCenter",
    data() {
        return {
            list: [
                {text: '我的游戏', icon: 'el-icon-monitor', path: '/my/myGame'},
                {text: '编辑资料', icon: 'el-icon-edit', path: '/my/edit'},
                {text: '账号安全', icon: 'el-icon-lock', path: '/my/security'},
                {text: '我的礼包', icon: 'el-icon-present', path: '/my/myGift'}
            ],
            userData: [],
            userName: '',
        }
    },
    created() {
        let token = localStorage.getItem("token")
        if (token == null) {
            this.$router.replace("/home")
        }
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem("userInfo"));
        this.userName = user.userName;
        this.loadData();
    },
    methods: {
        loadData() {
            getUserInfo(this.userName).then(res => {
                this.userData = res.data;
            })
        },
        clickSideMenu(path) {
            this.$router.push(path).catch(err => err);
        },
        toLogout() {
            logout().then(res => {
                localStorage.removeItem("token")
                localStorage.removeItem("userInfo")
                localStorage.removeItem("username")
                localStorage.removeItem("password")
            })
            this.$router.replace('/home')
        }
    }
}
</script>

<style scoped>

</style>
<style>
[v-cloak] {
    display: none;
}
</style>