<template>
  <div class="all-panel">
    <div class="header-panel">
      <div class="logo-panel">
        <van-image :src="qiliLogo" width="3rem"></van-image>
        <span style="color: white;margin-left: 10px;font-size: 18px;font-weight: 700">七里币自助充值</span>
      </div>
    </div>
    <div class="header-info">
      <p>充值信息</p>
    </div>
    <div class="main-page">
      <van-form ref="form" class="form-panel" validate-first @submit="onSubmit">
        <van-field
                v-model="account"
                label="帐号"
                placeholder="请输入七里帐号"
                autocomplete="off"
                required
                :rules="[{ required: true, message: ''},
                { pattern : accountPattern, message: '帐号为6-12位字母数字或下划线组成'}]"
                class="file-van"
        />

        <van-field
                v-model="confirmAccount"
                label="确认帐号"
                placeholder="请再次输入七里帐号"
                autocomplete="off"
                required
                :rules="[{ required: true, message: ''},
                { pattern : accountPattern, message: '帐号为6-12位字母数字或下划线组成'},
                { validator : validateSameAccount, message: '两次输入的账号不一致' },
                { validator : validateAccount, message: '玩家账号不存在' }]"
                class="file-van"
        />
        <van-field
                v-model="amount"
                type="number"
                required
                label="充值金额"
                placeholder="请输入充值金额"
                :rules="[{ required: true, message: '' },
                { pattern: /^-?\d+([.]\d{1,2})?$/, message: '充值金额格式错误' }]"
        />
        <van-field
                v-model="game_name"
                required
                right-icon="arrow"
                label="充值游戏"
                placeholder="请选择充值游戏"
                :rules="[{ required: true, message: ''}]"
                readonly
                @click="showPicker"
        />
        <van-popup v-model="show" position="bottom" >
          <van-picker
                  title="请选择充值游戏"
                  show-toolbar
                  :columns="gameNameList"
                  @confirm="onConfirm"
                  @cancel="show = false"
          />
        </van-popup>
        <van-divider :style="{ color: '#7456ea', borderColor: '#7456ea', 'font-size': '.8rem', margin: '.2rem 16px' }">
          选择支付方式
        </van-divider>
        <div class="pay-class">
          <van-radio-group v-model="payType">
            <van-cell :icon="zfbPayIcon" v-if="showAli" title="支付宝" clickable @click="payType = '2'">
              <template #title>
                <span>支付宝</span>
              </template>
              <template #right-icon>
                <van-radio name="2" shape="square" icon-size=".9rem"/>
              </template>
            </van-cell>
            <van-cell v-if="false" :icon="wxPayIcon" title="微信" style="margin-top: .3rem" clickable @click="payType = '3'">
              <template #title>
                <span>微信</span>
              </template>
              <template #right-icon>
                <van-radio name="3" shape="square" icon-size=".9rem"/>
              </template>
            </van-cell>
          </van-radio-group>
        </div>


        <div class="tips-panel">
          <van-icon name="warning" color="#049945">温馨提示</van-icon>
          <span>充值成功后将在该游戏中直接增加七里币</span>
          <span>微信支付请前往七里网络公众号充值</span>
        </div>

        <div class="button-panel">
          <van-button block type="info" loading-text="充值中" :loading="submitting" native-type="submit">确认充值</van-button>
        </div>
      </van-form>

      <div class="diy-dialog" v-show="diyDialogShow">
        <div style="text-align: center">提示</div>
        <van-divider/>
        <div style="height: 80px">您将打开支付页面，请问是否继续？<br><br>
          <span style="color: #b3b3b6; font-size: 13px;">注：支付成功后请关闭新打开的支付窗口</span>
        </div>
        <van-divider/>
        <div class="diy-dialog-buttons">
          <div>
            <a @click="diyDialogShow = false">取消</a>
          </div>
          <div>
            <a target="_blank" :href="payUrl" @click="diyDialogShow = false">继续支付</a>
          </div>
        </div>
      </div>
    </div>

    <van-dialog class="pay-code-dialog" v-model="payCodeShow" confirm-button-text="支付完成点击关闭">
      <pay-code ref="payCode"></pay-code>
    </van-dialog>

    <van-dialog class="pay-code-dialog" v-model="payCodeImgShow" confirm-button-text="支付完成点击关闭">
      <pay-code-img ref="payCodeImg"></pay-code-img>
    </van-dialog>

    <van-overlay :show="diyDialogShow"/>

    <van-overlay :show="overlayShow">
      <div class="overlay-panel">
        <div>
          <van-image :src="wjtImg" width="80px" height="80px"></van-image>
        </div>
        <div class="overlay-info">
          <p>
            请点击右上角 ... 在默认浏览器中打开
          </p>
        </div>
      </div>
    </van-overlay>

  </div>
</template>

<script>
  import { getPlayedGameList, qlOrder } from '@/api/request'
  import qiliLogo from '@/static/images/ql_logo.png'
  import wxPayIcon from '@/static/images/pay_wx.png'
  import zfbPayIcon from '@/static/images/pay_zfb.png'
  import payCode from "@/components/pay-code.vue";
  import payCodeImg from "@/components/pay-code-img.vue";
  import wjtImg from '@/static/images/wjt.png'
  import { Toast } from 'vant'
  import '@vant/touch-emulator';

  export default {
    name: 'UserRecharge',
    components: {
      'pay-code': payCode,
      'pay-code-img': payCodeImg,
    },
    data() {
      return {
        qiliLogo,
        zfbPayIcon,
        wxPayIcon,
        wjtImg,
        showAli: true,
        accountPattern: /^[a-zA-Z0-9_]{6,20}$/,
        submitting: false,
        gameList: [],
        gameNameList: [],
        show: false,
        payType: '',
        account: '',
        confirmAccount: '',
        game_name: '',
        game_id: '',
        amount: '',
        diyDialogShow: false,
        payCodeShow: false,
        payCodeImgShow: false,
        payUrl: '',
        overlayShow: false,
      }
    },
    mounted() {
      let ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        this.showAli=false;
        this.overlayShow = true;
      }
    },
    methods: {
      reset() {
        this.amount = '';
      },
      onSubmit() {
        if (this.amount < 1) {
          Toast('充值金额不能小于1');
          return ;
        }
        if (!this.payType) {
          Toast('请选择支付方式');
          return ;
        }
        this.submitting = true;
        let params = {
          userName: this.account,
          gameId: this.game_id,
          money: this.amount,
          paymentType: this.handPayType(this.payType)
        };
        qlOrder(params).then(res => {
          this.submitting = false;
          if (res.data.success) {
            console.log('支付成功');
            this.payUrl = res.data.data.payString;
            this.openPayUrl(this.payUrl);
          }
          else {
            Toast(res.data.error);
          }
        });
      },
      openPayUrl(url) {
        if (this.isMobile()) {
          let ua = navigator.userAgent.toLowerCase();
          if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
            this.diyDialogShow = true;
          }else{
            window.open(url, '');
          }
        } else {
          if (url.indexOf('data:image') > -1) {
            this.payCodeImgShow = true;
            this.$nextTick(function () {
              this.$refs.payCodeImg.payImg = url;
              this.$refs.payCodeImg.payType = this.handPayType(this.payType);
              this.$refs.payCodeImg.payPrice = this.amount;
              this.$refs.payCodeImg.init();
            });
          } else {
            this.payCodeShow = true;
            this.$nextTick(function () {
              this.$refs.payCode.payCode = url;
              this.$refs.payCode.payType = this.handPayType(this.payType);
              this.$refs.payCode.payPrice = this.amount;
              this.$refs.payCode.init();
            });
          }
        }
      },
      onConfirm(value, index) {
        this.game_name = value;
        this.game_id = this.gameList[index].game_id;
        this.show = false;
      },
      showPicker() {
        if (!this.account || !this.confirmAccount) {
          Toast('请先完善帐号信息');
          return ;
        }
        if (this.gameList.length === 0) {
          Toast('帐号信息不存在');
          return;
        }
        this.show = true;
      },
      validateSameAccount() {
        return new Promise((resolve) => {
          if (!this.account || !this.confirmAccount) {
            resolve(true);
          }
          if (this.account !== this.confirmAccount) {
            resolve(false);
          }
          resolve(true);

        });
      },
      validateAccount(value) {
        return new Promise((resolve) => {
          let param = {
            userName: value,
          };
          getPlayedGameList(param).then(res => {
            if (!res.data.success) {
              this.gameList = [];
              this.game_name = '';
              resolve(false);
            } else {
              this.gameList = res.data.data;
              if (this.gameList.length > 0 && !this.game_id) {
                let first = this.gameList[0];
                this.game_name = first.game_name;
                this.game_id = first.game_id;
              }
              this.gameNameList = [];
              let me = this;
              this.gameList.forEach(function(e, i){
                me.gameNameList.push(e.game_name);
              });
              resolve(true);
            }
          });
        });
      },
      isMobile() {
        return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      },
      handPayType(payType) {
        let isMobile = this.isMobile();
        if (isMobile) {
          return payType;
        }
        if (payType === '1') {
          return '1'
        }
        if (payType === '2') {
          return '4'
        }
        if (payType === '3') {
          return '5'
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .all-panel {
    width: 100%;
    background-color: #f2f2f2;
    overflow-y: auto;
    height: 100vh;
    .header-panel {
      width: 100%;
      height: 70px;
      background-color: #37414d;
      .logo-panel{
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        height: 100%;
        align-content: center;
        align-items: center;
      }
    }
    .header-info{
      max-width: 600px;
      margin: 0 auto;
      text-align: left;
      padding-left: 20px;
      font-weight: 600;
    }
    .main-page{
      width: 100%;
      max-width: 600px;
      margin: 10px auto;
      background-color: white;
      border-radius: 5px;
      padding: 8px 0 50px 0;
      .form-panel{
        .van-cell::after {
        }
        .pay-class{
          text-align: left;
          padding: 0 8px;
          .van-cell{
            padding: 3px 16px;
            .van-cell__title{
              font-size: .8rem;
              font-weight: 600;
            }
          }
        }
        .tips-panel{
          margin: 20px 15px 0 15px;
          padding: 8px 8px;
          text-align: left;
          background-color: #d4ecdc;
          font-size: 12px;
          color: #049945;
          line-height: 25px;
          span {
            display: block;
          }
        }
        .button-panel{
          max-width: 200px;
          margin-top: 50px;
          margin-left: 20px;
          font-weight: 700;
          span {
            font-size: 14px;
          }

        }
      }

      .diy-dialog {
        z-index: 9;
        top: 35%;
        background-color: white;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 8px 1px 8px;
        text-align: left;
        font-size: 14px;
        width: 70%;
        border-radius: 10px;
        left: 14.5%;
        .diy-dialog-buttons {
          padding: 0 15px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          height: 30px;
          a {
            color: #1783d6;
          }
          div {
            width: 100%;
            text-align: center;
          }
        }
        .van-divider--hairline {
          margin: 8px 0;
        }
      }
    }

    .overlay-panel{
      text-align: right;
      margin-right: 15px;
      margin-top: 10px;
    }

    .overlay-info {
      text-align: center;
      width: 80%;
      margin: 20px auto;
      border-radius: 60px;
      border: 2px dashed white;
      color: white;
      font-weight: 600;
    }

  }
</style>
