<template>
    <div style="min-width: 100%">
        <div style="position: relative;height: 85vh;">
            <!--轮播图-->
            <div class="ql-swipe" @mouseleave="startAuto" @mouseenter="stopAuto">
                <ul>
                    <transition-group name="ql-fade">
                        <li v-for="(item,index) in list"
                            v-model="currentImg"
                            :key="item.gameId"
                            v-show="item.show">
                            <a href="javascript:" @click="clickHot(item.gameId)">
                                <img :src="item.src" class="ql-swipe"/>
                            </a>
                        </li>
                    </transition-group>
                </ul>
            </div>
            <!--轮播图指示器-->
            <div class="ql-indicator" ref="ind" @mouseenter="navShow = true;" @mouseleave="navShow = false;">
                <ul class="ql-nav">
                    <li @mouseenter="mouseenter(index)"
                        @mouseleave="mouseleave(index)"
                        v-for="(item,index) in list"
                        :key="index"
                        :class="{'ql-active': index === currentImg }"
                        style="padding: 3.3vmin 0 3.3vmin 20px;">
                        <a href="javascript:" @click="clickHot(item.gameId)">
                            <div class="text-white relative">
                                <img :src="item.icon" style="width: 9vmin;height:9vmin;justify-items: center;"/>
                                <transition name="ql-fade">
                                    <div class="ql-nav-game-name" v-show="navShow">
                                        <em class="text-xl">{{ item.name }}</em><br/>
                                        <span class="text-sm">{{ item.tag }}</span>
                                    </div>
                                </transition>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="clear"></div>
        </div>
        <div class="bg-white">
            <ul style="margin: -70px auto 0;height: 540px" class="text-center">
                <li v-for="(item,index) in gameList" :key="index"
                    class="ql-item"
                    :class="{'ql-item-translate':item.show}"
                    style="height: 465px;"
                    @mouseenter="showIcon(index)"
                    @mouseleave="unShowIcon(index)">
                    <div class="relative" style="width: 298px;height: 465px" @click="clickHot(item.gameId)">
                        <img :src="item.icon" />
                    </div>
                    <div class="ql-item-button" :class="{'ql-item-translate':item.show}">
                        <button @click="clickHot(item.gameId)">开始游戏</button>
                    </div>
                </li>
            </ul>
            <div style="height: 100px;">
                <button @click="$router.push('/mobileGame')" class="ql-item-more-button bg-transparent border-2 border-orange-500 text-orange-500 font-bold">更多</button>
            </div>
        </div>
        <!--新闻公告-->
        <div class="bg-gradient-to-br from-orange-400 to-orange-500">
            <div class="text-4xl text-center py-10">游戏攻略</div>
            <div class="news">
                <div v-for="item in contentData.slice(0,3)" class="news-content cursor-pointer" @click="toStrategy(item.content_id)">
                    <div style="height: 60%">
                        <img :src="item.view_img_url" style="height:100%;border-radius: 0.5rem 0.5rem 0 0;object-fit: fill;"/>
                    </div>
                    <div class="p-5 relative" style="height: 40%">
                        {{ item.content_name }}
                        <p class="text-sm absolute right-5 bottom-5">{{ item.add_time.substring(0,10) }}</p>
                    </div>
                </div>
                <div style="height: 100px;">
                    <button @click="$router.push('/news')" class="ql-item-more-button bg-white">更多</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryList } from '@/api/request';
export default {
    name: "home2",
    data(){
        return{
            list:[
                {gameId: 336,name:'新斗罗大陆',icon:'https://cdn.7li.club/image/jhgifnpr/logo.png',tag:'卡牌',src:require('@/static/images/xindouluo/pcSwiper.jpg'),show:true},
                {gameId: 206,name:'斗罗大陆H5-天使',icon:'https://cdn.7li.club/image/zryptjgp/logo.png',tag:'角色扮演',src:require('@/static/images/douluo/pcSwiper.png'),show:false},
                {gameId: 309,name:'斗罗大陆-武魂觉醒',icon:'https://cdn.7li.club/image/cdhayzpp/logo.png',tag:'卡牌',src:require('@/static/images/whjx/pcSwiper.png'),show:false},
            ],
            gameList:[
                {gameId: 206,name: '斗罗大陆H5-天使',tag: '角色扮演',icon:require('@/static/images/douluo/exhibition.png'),show:false},
                {gameId: 309,name: '斗罗大陆武魂觉醒',tag: '策略',icon:require('@/static/images/whjx/exhibition.png'),show:false},
                {gameId: 336,name: '新斗罗大陆',tag: '卡牌',icon:require('@/static/images/xindouluo/swipe.jpg'),show:false},
            ],
            contentData:[],
            currentImg: 0, //当前轮播图的索引
            playTimer:null, //轮播图定时器
            timeOut: 3000, //定时器延迟时间
            navShow: false,
            crossSlipTimer: null, //横向滚动条定时器
        }
    },
    mounted() {
        this.loadData();
        //开启轮播
        this.startAuto();
    },
    methods:{
        loadData(){
            queryList().then(res => {
                this.contentData = res.data;
            });
        },
        clickHot(gameId) {
            this.$router.push({name: 'MgDetails'})
            sessionStorage.setItem("gameId", gameId)
        },
        toStrategy(contentId) {
            this.$router.push({name: 'NewsInfo', params: {contentId: contentId}})
        },
        //鼠标离开,开启轮播
        startAuto() {
            clearInterval(this.playTimer)
            this.playTimer = setInterval(() => {
                if(this.list[this.currentImg].show && this.currentImg < this.list.length){
                    //把当前下标对象设为false
                    this.list[this.currentImg].show = false;
                    //下标越位为当前currentImg，否则为(currentImg+1)
                    this.currentImg == this.list.length-1 ? this.list[this.currentImg].show = true : this.list[this.currentImg+1].show = true;
                    this.currentImg++;
                }
                if(this.currentImg == this.list.length){
                    //把list最后一个对象设为false
                    this.list[this.list.length-1].show = false;
                    this.currentImg = 0;
                    this.list[this.currentImg].show = true;
                }
            }, this.timeOut)

        },
        //鼠标悬浮停止轮播
        stopAuto(){
            clearInterval(this.playTimer);
        },
        //进入轮播指示器
        mouseenter(index){
            //清除轮播图定时器
            this.stopAuto();
            //清除横向滚动条定时器
            clearInterval(this.crossSlipTimer);
            document.body.style.overflowX = "hidden";
            //把上一个鼠标悬浮设为false
            this.list[this.currentImg].show = false;
            //当前鼠标悬浮下标
            this.currentImg = index;
            //把当前鼠标悬浮设为true
            this.list[this.currentImg].show = true;
            this.navShow = true;
        },
        //离开轮播指示器
        mouseleave(){
            this.$refs.ind.addEventListener('mouseleave',() => {
                this.navShow = false;
                this.crossSlipTimer = setInterval(() => {
                    document.body.style.overflowX = "initial";
                },2000);
            });
            this.$refs.ind.removeEventListener('mouseleave',() => {
                this.navShow = false;
                this.crossSlipTimer = setInterval(() => {
                    document.body.style.overflowX = "initial";
                },2000);
            });
        },
        unShowIcon(index){
            //离开
            //this.isShowIcon = false;
            this.gameList[index].show = false;
        },
        showIcon(index){
            //进入
            //this.isShowIcon = true;
            this.gameList[index].show = true;
        }
    }
}
</script>

<style scoped>
</style>
<style>
    .ql-swipe{
        width: 100%;
        /*min-width: 1450px;*/
        height: 85vh;
        @apply absolute left-0 top-0;
    }
    /*****淡出淡入****/
    .ql-fade-enter,
    .ql-fade-leave-to {
        opacity: 0;
    }
    .ql-fade-enter-to,
    .ql-fade-leave {
        opacity: 1;
    }
    .ql-fade-enter-active,
    .ql-fade-leave-active {
        transition: all 2s;
    }
    .ql-indicator{
        @apply bg-black bg-opacity-30 absolute right-0 z-10 hover:w-1/5;
        @apply transition-all duration-1000;
        width: 7vw;
        height: 85vh;
    }
    .ql-nav{
        @apply py-10;
        height: 85vh;
    }
    .ql-nav-game-name{
        @apply absolute top-5 xx:pl-5 truncate;
        left: 6vw;
        width: 13vw;
        height: 9vh;
    }
    .ql-active{
        @apply bg-black bg-opacity-80;
    }
    .ql-item-text{
        @apply absolute bottom-0 py-5 pl-5 text-left bg-black bg-opacity-10;
        width: 298px;
    }
    .ql-item{
        @apply inline-block mx-4 overflow-hidden cursor-pointer;
        @apply transition duration-500 ease-in-out;
    }
    .ql-item-translate{
        @apply transition duration-300 ease-in-out;
        @apply transform -translate-y-16;
    }
    .ql-item-button{
        @apply bg-gradient-to-tr from-orange-400 to-orange-500 text-center text-white font-bold;
        @apply transition duration-500 ease-in-out;
        width: 298px;
        height: 67px;
        line-height: 67px
    }
    .ql-item-more-button{
        @apply inline-block absolute; left: 47%;
        @apply my-6 w-32 h-12 rounded-full text-center;
        @apply text-black shadow-lg;
        @apply transition duration-300 ease-in-out transform hover:scale-105;
    }
    .news{
        width: 1280px;
        @apply mx-auto my-0 py-10;
        @apply grid grid-rows-1 grid-cols-3 gap-6;
    }
    .news-content{
        @apply bg-white rounded-lg;
        @apply hover:shadow-xl transition duration-300 ease-in-out transform hover:scale-105;
        height: 350px;
    }
</style>