<template>
    <div style="height: 490px">
        <div class="py-5 px-7 relative">
            <div class="text-lg text-gray-700 pb-3 border-b border-gray-500">编辑资料</div>
            <el-form :model="form">
                <!-- 上传头像 -->
                <el-upload
                    :headers="headerData"
                    ref="upload"
                    action="#"
                    class="avatar-uploader"
                    :class="{avatar: !userData.img_url}"
                    :limit="1"
                    accept="image/jpg, image/jpeg ,image/png"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="userData.img_url" :src="userData.img_url" style="width: 144px;height: 144px"/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
<!--                <div class="absolute top-48 text-blue-500" style="left: 385px">
                    <i class="el-icon-camera-solid" style="font-size: 30px"/>
                </div>-->
                <div class="edit-content">
                    <table class="mx-30">
                        <tr>
                            <td>
                                <span>账号：</span>
                            </td>
                            <td>
                                <span>{{ userData.user_name }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>昵称：</span>
                            </td>
                            <td>
                                <input class="edit" maxlength="12" v-model="nickName"></input>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="mx-72">
                    <button type="button" @click="save" class="edit-btn bg-indigo-500 hover:bg-indigo-600">保存</button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import {getUserInfo, saveEdit, saveHeaderIcon} from '@/api/request'
  export default {
      name: "editData",
      data(){
          return{
              radio: '3',
              userName: '',
              nickName: '',
              userData:[],
              form:{},
              headerData:{}
          }
      },
      mounted() {
        let user = JSON.parse(localStorage.getItem("userInfo"));
        this.userName = user.userName;
        this.loadData();
    },
    methods: {
        loadData() {
            getUserInfo(this.userName).then(res => {
                this.userData = res.data;
                this.nickName = this.userData.nick_name;
            })
        },
        save() {
            let params = {
                userId: this.userData.user_id,
                nickName: this.nickName,
            }
            saveEdit(params).then(res => {
                if (res.data.success) {
                    alert(res.data.data)
                    //window.location.reload();
                } else {
                    alert(res.data.error.message)
                }
            })

        },
/*        handleAvatarSuccess(res, file) {
            this.userData.imgUrl = URL.createObjectURL(file.raw);
            this.$message({
                message: '修改成成功',
                type: "success"
            });
            setTimeout(() => {

            },500)
        },*/
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
                return false;
            }
            /*return isJPG && isLt2M;*/
            const fd = new FormData();
            fd.append('file',file);
            saveHeaderIcon(fd).then( res => {
                if(res.data.success){
                    location.reload();
                }
            })
        },
    }
}
</script>

<style>
.edit-content table tr td:nth-child(odd) {
    @apply text-gray-600;
}

.edit-content table tr td:nth-child(even) {
    @apply text-gray-800;
}

.edit-content table tr td {
    @apply py-4;
}

.avatar .el-upload {
    @apply border border-gray-400 border-dashed;
}

.avatar-uploader .el-upload {
    @apply rounded-full w-36 h-36 mx-64 mt-5;
    @apply cursor-pointer relative overflow-hidden;
}

.avatar-uploader-icon {
    font-size: 28px;
    line-height: 140px !important;
    @apply w-28 h-28 text-gray-400;
}

/*文字颜色*/
.el-radio__input.is-checked + .el-radio__label {
    color: #667eea !important;
}

/*单选按钮颜色*/
.el-radio__input.is-checked .el-radio__inner {
    background: #667eea !important;
    border-color: #667eea !important;
}
</style>