<template>
    <div v-if="pageData">
        <div>
          <van-nav-bar fixed placeholder :border="false" left-arrow @click-left="$router.go(-1)">
              <template #title>
                  <van-search
                      v-model="result"
                      class="py-0"
                      shape="round"
                      placeholder="请输入搜索关键词"/>
              </template>
              <template #right>
                <div @click="onRefresh">搜索</div>
              </template>
          </van-nav-bar>
        </div>
        <div>
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad">
            <van-cell v-for="(item,index) in pageData" :key="index" @click="toDetails(item.game_id)">
              <img :src="item.game_icon" class="w-16 h-16 float-left">
              <div class="absolute left-20 leading-normal">
                <div class="text-base font-bold">{{ item.game_name }}</div>
                <div class="text-xs w-28 truncate text-gray-800">{{ item.game_desc }}</div>
                <div class="text-gray-500">
                  <span v-for="(tag,index) in item.tag.split(',')" :key="index" v-if="tag" class="bg-gray-300 mr-1 rounded-sm text-gray-700 text-xs px-1 py-0.25">{{ tag }}</span>
                </div>
              </div>
              <div>
                <van-button size="small" class="border-indigo-500 absolute top-5 right-2 text-indigo-500 rounded-md w-14" plain>下载</van-button>
              </div>
            </van-cell>
          </van-list>
        </div>
    </div>
</template>

<script>
import { getSearchResult } from "@/api/request";

export default {
  name: "mobileSearchResult",
  data(){
    return{
      result: '',
      pageNo: 1,
      pageSize: 10,
      pageData: [],
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  methods:{
    loadData() {
      if (this.refreshing) {
        this.pageData = [];
        this.refreshing = false;
      }
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        params: {
          game_name: this.result,
        }
      }
      getSearchResult(params).then( res => {
        if(res.data.list.length != 0){
          this.loading = false;
          this.pageData = this.pageData.concat(res.data.list);
          this.pageNo++;
        }
        //如果加载完毕，显示没有更多
        if(res.data.list.length == 0){
          this.finished = true;
        }
      })
    },
    onLoad(){
      setTimeout(() => {
        this.loadData();
        this.loading = true;
      },500)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageData = [];
      this.pageNo = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    toDetails(gameId){
      sessionStorage.setItem('gameId',gameId);
      this.$router.push('/mobile/gameDetails');
    }
  }
}
</script>

<style scoped>

</style>