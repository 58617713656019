<template>
    <div>
        <div class="max-w-screen-lg mx-auto my-0 relative flex">
            <!-- 左侧时间 -->
            <div class="bg-white w-25" style="height: 475px">
                <ul class="text-center relative">
                    <li class="pt-4 pb-1 border-b border-solid">
                        <span>正在开服</span>
                    </li>
                    <li v-for="(item,index) in StartTime"
                        class="py-1 border-b border-solid cursor-pointer hover:bg-gray-500 relative"
                        :class="{ a: index === current}" name="time" @click="clickTime(index,item)">
                        <span>{{ item }}</span>
                    </li>
                </ul>
            </div>
            <!-- 开服详情 -->
            <div class="bg-white ml-8 relative" style="width: 908px;">
                <div class=" px-6 py-3 text-indigo-500 font-bold">
                    <el-date-picker
                        v-model="openTime"
                        :clearable="false"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </div>
                <div id="wrap-table">
                    <table class="w-full">
                        <tr>
                            <td v-for="item in text" class="text-gray-700 text-sm">
                                <span>{{ item }}</span>
                            </td>
                        </tr>
                        <tr v-for="(item,index) in tableData" :key="index" class="text-gray-900 text-sm">
                            <td>
                                <span>{{ item.game_name }}</span>
                            </td>
                            <td>
                                <span>{{ item.server_name }}</span>
                            </td>
                            <td>
                                <span>{{ item.open_time.substring(11, 16) }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <!--分页区-->
        <div class="h-24 bg-white my-0 mx-auto mt-6 rounded-lg relative left-16"
             style="width:894px;padding: 5px 25px 20px 25px;">
            <div class="mx-auto my-7 w-1/3">
                <el-pagination
                    @current-change="loadData"
                    background
                    :page-size="16"
                    :current-page="currentIndex"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import {queryGameServer} from '@/api/request'

export default {
    name: "openService",
    data() {
        return {
            StartTime: [],
            current: 0,
            currentIndex: 0,
            total: 0,
            pageSize: 15,
            time1: '09:00',
            openTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + (new Date().getDate()).toString().padStart(2,'0'),
            tableData: [],
            text: ['游戏名', '服务器', '开服时间']
        }
    },
    mounted() {
        this.time()
        this.loadData(1)
    },
    methods: {
        loadData(index) {
            let params = {
                pageNo: index,
                pageSize: this.pageSize,
                params: {
                    openTime: this.openTime + " " + this.time1
                },
            }
            queryGameServer(params).then(res => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.currentIndex = index;
            })
        },
        time() {
            for (let i = 9; i <= 21; i++) {
                this.StartTime.push(i < 10 ? '0' + i + ':00' : i + ':00');
            }
        },
        clickTime(index, time) {
            this.current = index;
            this.time1 = time;
            this.loadData(1);
        },
    }
}
</script>

<style>
.a {
    @apply bg-gray-500;
}

#wrap-table table tr {
    @apply border border-solid text-center;
}

#wrap-table table tr td {
    @apply border border-solid;
    width: 227px;
    height: 40px;
}

.el-input__inner {
    border: none !important;
    caret-color: transparent !important;
    @apply cursor-pointer !important;
}

.el-date-table td.current:not(.disabled) span {
    @apply bg-indigo-500 text-white !important;
}

.el-date-table td.today span {
    @apply text-indigo-500 !important;
}
</style>