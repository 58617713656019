<template>
    <div>
        <van-nav-bar title="交易" fixed placeholder left-arrow @click-left="$router.go(-1)"/>
        <div class="px-3">
          <van-search v-model="value" placeholder="请输入搜索关键词" class="px-0" />
          <van-grid class="py-0 border-none"  :column-num="3">
            <van-grid-item v-for="(item,index) in g" :key="index" :icon="item.icon" :text="item.text" :to="item.path"/>
          </van-grid>
        <div>
          <select class="mt-3 bg-white">
              <option v-for="option in options" v-text="option"></option>
          </select>
        </div>
        <div>
            <div @click="$router.push('/mobile/goodsDetails')" class="w-full rounded-lg px-3 pt-4 my-4 relative" style="background-color: #f5f5f5" v-for="item in 3">
                <div class="relative">
                  <van-image
                      width="60"
                      height="60"
                      round
                      lazy-load
                      src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                  <div class="absolute top-2 left-20">
                      <p class="text-md w-56 truncate">斗罗大陆</p>
                      <p class="text-xs">
                        <span>发布于：</span>
                        <span>2022-08-09</span>
                      </p>
                  </div>
                  <p class="float-right absolute -top-px right-1 text-indigo-500">￥12000.00</p>
                  <div>
                    <van-grid :border="false" :column-num="3" class="border-none p-0">
                      <van-grid-item v-for="(item,index) in 3" :key="index">
                        <van-image
                            lazy-load
                            src="http://www.7wan.cn/static/uploads/mobile/2021042909495612224.png" />
                      </van-grid-item>
                    </van-grid>
                  </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "transaction",
  data(){
    return{
      value: '',
      options:['最新发布','价格降序','价格升序'],
      g:[
        {text: '我要卖',path: '', icon: 'add-o'},
        {text: '交易记录',path: '/mobile/record', icon: 'gold-coin-o'},
        {text: '交易须知',path: '', icon: 'comment-o'}
      ]
    }
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
<style>
    select:focus-visible {
      @apply outline-none;
    }
</style>