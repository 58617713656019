<template>
    <div>
        <div>
          <van-nav-bar
              title="关于我们"
              @click-left="$router.go(-1)"
              fixed
              :placeholder="true"
              left-arrow/>
          <van-image :src="require('@/static/images/qili.png')" class="w-24 h-24 block" style="margin: 10px auto"/>
          <div class="px-3 text-justify" style="text-indent: 2em">
            长沙七里互娱网络科技有限公司
            于2019年成立（简称“七里网络”）， 公司以"聚焦玩家，重拾快乐"为理念，
            依托七里网络自身强大的研发能力、丰富的运营经验和专业的服务能力三大核心竞争力。
            主营游戏领域的技术开发、技术转让、技术服务以及广告发布等，服务于各类大型网游“斗罗大陆”“怒火一刀”等知名H5，
            通过各大直播平台对新手玩家进行游戏指导与游戏攻略输出，同时本公司提供账号管理、充值、客服等服务，致力于网聚精品游戏的同时，
            打造轻松和谐的在线娱乐王国。
          </div>

          <div class="text-indigo-500 text-center absolute bottom-0 w-screen">
                <a target="_blank" href="https://game.7li.cn/static/sdk/user.html" class="text-sm hover:underline">《用户协议》</a>
                <span class="text-gray-500">&</span>
                <a target="_blank" href="https://game.7li.cn/static/download/privacy.html" class="text-sm hover:underline">《隐私政策》</a>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "mobileMatter"
}
</script>

<style scoped>

</style>