<template>
    <div>
<!--        <header class="ql-header">
            <div class="ql-more">
                <div class="ql-x2">
                    <img src="@/static/images/ql_logo.png" class="ql-logo"/>
                </div>
                <div @click="unfold" class="ql-x" v-show="isShowIcon">
                    <i class="el-icon-minus ql-i"/>
                </div>
                <div @click="unfold" class="ql-x" v-show="!isShowIcon">
                    <i class="el-icon-close ql-i"/>
                </div>
            </div>
            <el-collapse-transition>
                <div v-show="!isShowIcon">
                    <div class="ql-collapse-transition">
                        <div v-for="(item,index) in menu" :key="index" @click="forward(item.path)">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
            </el-collapse-transition>
        </header>-->
        <el-main class="ql-main">
            <!--轮播图-->
            <van-swipe :autoplay="3000" @change="onChange" class="swiper-container">
                <van-swipe-item v-for="(item,index) in imgList" :key="index">
                    <a href="javascript:" @click="toDetails(item.gameId)">
                        <van-image :src="item.url" class="block ql-public w-full h-full"/>
                    </a>
                </van-swipe-item>
                <template #indicator>
                    <div v-for="index in imgList.length" :key="index"
                         style="text-align: center;margin: 0 auto;width: 50%">
                        <div class="unselected-circle" :class="{'circle':index === current}"></div>
                    </div>
                </template>
            </van-swipe>
            <!-- 精选游戏 -->
            <div class="bg-gradient-to-bl from-orange-400 to-orange-500">
                <div style="width: 100vw;height: 100px;">
                    <span class="ql-game-font">精选游戏</span>
                </div>
                <div style="width: 100vw;">
                    <ul class="ql-ul-game" ref="ql-ul-game">
                        <li v-for="(item,index) in gameList" :key="index" :class="'li' + (index+1)">
                            <a href="javascript:" @click="toDetails(item.id)">
                                <div class="relative">
                                    <img :src="item.img" class="inline-block"
                                         style="width: 100%;height: 50vmin;border-radius: 0.5rem"/>
                                    <div class="absolute top-14 left-5">
                                        <img :src="item.icon" class="ql-game-img"/>
                                        <div style="font-size: 20px;" class="mt-2 text-gray-200">{{item.gameName}}</div>
                                        <div style="font-size: 1rem" class="text-gray-200">{{ item.tag }}</div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!--新闻公告-->
            <div class="ql-news-box">
                <div class="text-xl font-bold">
                    新闻公告
                </div>
                <div class="ql-news">
                    <div v-for="item in contentData" class="ql-news-content" @click="toNewsDetail(item.content_id)">
                        <span style="margin-left: 0;display: block">
                            <img :src="item.view_img_url" style="border-radius: 0.5rem 0.5rem 0 0"/>
                        </span>
                        <span class="relative block px-2">
                            {{ item.content_name }}
                            <span class="absolute top-18 right-2 sm:top-10 me:top-6">{{ item.add_time.substring(0,10) }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </el-main>
        <footer>
            <div style="height: 50vmin;background-color: #2a2626">
                <!--关于我们-->
                <div class="ql-about">
                    <div v-for="(item,index) in explain" :key="index">
                        <a :href="item.path">{{ item.name }}</a>
                    </div>
                </div>
                <!--备案-->
                <div class="text-gray-500 px-5 text-center leading-5" style="font-size: 10px;">
                    <p>©2021 7li.cn/ All Rights Resered.
<!--                        <a class="underline" href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备20015231号-2</a>-->
                        <a class="underline" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2021003061号-1</a>
                    </p>
                    <img src="@/static/images/gonganbu.png" class="inline-block mr-1"/>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004716"
                       class="underline">
                        川公网安备51019002004716号
                    </a><br/>
                    <span class="text-justify" style="white-space: pre-wrap;">
                        抵制不良网页游戏，拒绝盗版游戏。<br>
                        注意自我保护，谨防受骗上当。<br>
                        适度游戏益脑，沉迷游戏伤身。<br>
                        合理安排时间，享受健康生活。</span>
                    <p>战略合作：4000818979 按 1 转接</p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import {queryList} from "@/api/request";

export default {
    name: "mobileHome",
    data() {
        return {
            menu: [
                {text: '搜索', path: '/mobile/result', icon: 'search'},
                {text: '首页', path: '/mobile/home', icon: 'wap-home'},
                {text: '游戏', path: '/mobile/game', icon: 'graphic'},
                {text: '福利', path: '/mobile/weifare', icon: 'gift'},
                {text: '我的', path: '/mobile/account', icon: 'manager'},
            ],
            isShowIcon: true,
            imgList: [   //图片数组
                {gameId: 206, url: require('@/static/images/douluo/swipe.png')},
                {gameId: 309, url: require('@/static/images/whjx/swipe.png')},
                {gameId: 336, url: require('@/static/images/xindouluo/swipe.jpg')},
            ],
            current: 1,
            gameList: [
                {
                  id: 336,
                  gameName: '新斗罗大陆',
                  tag: '卡牌',
                  icon: 'https://cdn.7li.club/image/jhgifnpr/logo.png',
                  img: require('@/static/images/xindouluo/hyh.png')
                },
                {
                    id: 206,
                    gameName: '斗罗大陆H5-天使',
                    tag: '角色扮演',
                    icon: 'https://cdn.7li.club/image/zryptjgp/logo.png',
                    img: require('@/static/images/douluo/2.png')
                },
                {
                  id: 309,
                  gameName: '斗罗大陆-武魂觉醒',
                  tag: '卡牌',
                  icon: 'https://cdn.7li.club/image/cdhayzpp/logo.png',
                  img: require('@/static/images/whjx/rolexw.png')
                },
            ],
            explain: [
                {name: "关于我们", path: location.protocol+'//' + location.host +"/#/mobile/matter"},
                {name: "用户协议", path: "https://game.7li.cn/static/sdk/user.html"},
                {name: "隐私政策", path: "https://game.7li.cn/static/download/privacy.html"},
            ],
            contentData: [],
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData(){
            queryList().then(res => {
                this.contentData = res.data;
            });
        },
        toDetails(gameId) {
            sessionStorage.setItem('gameId', gameId);
            this.$router.push('/mobile/gameDetails');
        },
        toNewsDetail(contentId){
            this.$router.push({name: 'MobileNews',params: { contentId: contentId }})
        },
        unfold() {
            if (this.isShowIcon) {
                this.isShowIcon = false;
                document.body.style.overflowY = "hidden"
            } else {
                this.isShowIcon = true;
                document.body.style.overflowY = "scroll"
            }
        },
        forward(path) {
            this.isShowIcon = true;
            document.body.style.overflowY = "scroll"
            this.$router.push(path);
        },
        onChange(index) {
            this.current = index + 1;
        },
    }
}
</script>
<style scoped></style>
<style>
.el-main {
    padding: 0px !important;
}

.ql-public {
    width: 100vw;
    height: 100vh
}

/*.ql-header {
    @apply bg-gradient-to-tl from-orange-500 to-orange-400 w-screen !*sticky*! left-0 top-0 z-50;
    height: 15.5vmin;
}

.ql-header:after {
    @apply clear-both block hidden;
    content: "*";
}

.ql-more {
    !*width: 15.5vmin;*!
    width: 100vw;
    height: 15.5vmin;
    display: grid;
    grid-template-rows: 15.5vmin;
    grid-template-columns:84.5vw 15.5vw;
}

.ql-i {
    height: 15.5vmin !important;
    line-height: 15.5vmin !important;
}

.ql-x {
    width: 15.5vw;
    height: 15.5vmin;
    !*background-color: white;*!
    line-height: 15.5vmin;
    @apply text-4xl text-white md:text-7xl text-center float-right;
    line-height: 15.5vmin;
    grid-row: 1/2;
    grid-column: 2/3;
}

.ql-x2 {
    width: calc(100vw - 15.5vmin);
    height: 15.5vmin;
    grid-row: 1/2;
    grid-column: 1/2;
    !*text-align: center;*!
    line-height: 15vmin;
    padding-left: 10px;
}

.ql-logo {
    width: 12vmin;
    height: 12vmin;
    @apply inline-block;
}

.ql-collapse-transition {
    @apply bg-black opacity-90 relative left-0 top-0;
    @apply divide-y divide-gray-200;
    width: 100vw;
    height: 100vh;
    !*top: 15.5vmin;*!
}*/

.ql-collapse-transition div {
    @apply /*border-b-1 border-solid border-gray-100*/
    border-opacity-75 text-gray-200;
    @apply mx-10 py-2;
    font-size: 16px;
}

.ql-main {
    @apply relative top-0 left-0;
}

.swiper-container {
    width: 100vw;
    height: calc(100vh - 15.5vmin);
}

.swiper-wrapper {
    width: 100vw;
    height: 100%;
    display: block;
}

.swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*border: 1px solid;*/
}

.unselected-circle {
    @apply bg-white opacity-50 w-3 h-3 mx-2 rounded-full relative bottom-6 left-10 float-left;
    @apply transition duration-500 ease-in-out;
}

.circle {
    @apply relative bottom-6 left-10;
    @apply bg-white opacity-100 w-3 h-3 px-3;
    @apply transition duration-500 ease-in-out;
}

/*.ql-ul-game .li1 div:nth-child(1) {
    margin: 0px 1.25rem 1.375rem 1.25rem;
    !*padding-left: 1.25rem;*!
    !*@apply rounded-lg bg-gradient-to-bl from-purple-500 to-purple-600 shadow-xl;*!
}

.ql-ul-game .li2 div:nth-child(1) {
    margin: 1.375rem 1.25rem 0px 1.25rem;
    !*padding-left: 1.25rem;*!
    !*@apply shadow-xl;*!
    !*@apply rounded-lg bg-gradient-to-bl from-red-500 to-red-600 ;*!
}

.ql-ul-game .li3 div:nth-child(1){
    margin: 0 1.25rem;
    padding-bottom: 1rem;
}

.ql-ul-game .li4 div:nth-child(1){
  margin: 0 1.25rem;
  padding-bottom: 1rem;
}*/

.ql-ul-game li div:nth-child(1){
  margin: 0 1.25rem;
  padding-bottom: 1rem;
}

.ql-ul-game .li4 {
  padding-bottom: 1.25rem;
}

.ql-news-box {
    @apply text-center py-5;
    background-color: #f5f5f5;
}

.ql-news {
    @apply grid grid-rows-2 grid-cols-2 gap-3;
    @apply text-justify /*border border-black*/ my-5;
}

.ql-news div:nth-child(odd) {
    margin-left: 20px;
    @apply shadow-lg;
}

.ql-news div:nth-child(even) {
    margin-right: 20px;
    @apply shadow-lg;
}

.ql-news-content {
    @apply bg-white h-44 rounded-md text-xs;
    @apply sm:h-54 me:h-60;
}

.ql-news-more {
    @apply w-32 h-10;
    @apply bg-gradient-to-bl from-orange-400 to-orange-500 rounded-full;
}

.ql-game-img {
    width: 16vmin;
    height: 16vmin;
    @apply rounded-2xl inline-block;
}

.ql-game-font {
    @apply text-3xl text-gray-200 float-right;
    line-height: 100px;
    padding-right: 25px
}

.ql-about {
    @apply grid grid-cols-3 divide-x divide-gray-300;
    @apply text-center pt-2 pb-5 text-sm text-white;
}
</style>