<template>
    <div class="mb-6">
        <div>
            <van-image :src="require('@/static/images/service.png')" />
        </div>
        <div class="max-w-screen-xl h-80 mx-auto my-0 relative mt-10">
            <div class="service-box">
                <div class="service-box-text">游戏客服</div>
                <div class="text-gray-500 pl-28">
                    <span>客服QQ：</span>
                    <span class="text-indigo-500 select-text">2982250830</span>
                </div>
            </div>
            <div class="service-box mx-10">
                <div class="py-6 px-10">
                    <div class="service-box-text">微信公众号</div>
                    <div class="float-left">
                        <img class="w-24 h-24" src="https://game.7li.cn/img/qili-qrcode.31a32cb1.png">
                    </div>
                    <div class="text-gray-500 pl-28">
                        <p>
                            公众号搜索"七里网络"
                        </p>
                        <p>找在线客服</p>
                    </div>
                </div>
            </div>
            <div class="service-box">
                <div class="service-box-text"></div>
            </div>
        </div>
        <div class="common-box">
            <ul class="grid grid-cols-3">
                <li v-for="item in query" class="float-left px-26">
                    <a href="javascript:" :title="item.text" @click="$router.push(item.path)">
                        <img :src="item.img"/>
                        <p class="px-6 py-4" v-text="item.text"></p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "service",
    data() {
        return {
            query: [
                {img: require('@/static/images/reset.png'), text: '忘记密码', path: '/reset'},
            ]
        }
    }
}
</script>

<style scoped>

</style>