<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
        let path = this.$router.currentRoute.fullPath
        if(path.includes("tu") || path === '/jiabi'){
          return;
        }
        if(this.isMobile()){
            this.$router.replace('/mobile/index')
        }else {
            let path = this.$route.path;
            if(path === '/mobile/home'){
                this.$router.replace('/');
            }else {
                this.$router.replace(path);
            }
        }
  },
  methods:{
    // 添加判断方法
    isMobile() {  /*pad|pod|iPod|iPad|Mobile|ios|*/
      let flag = navigator.userAgent.match(/(phone|iPhone|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  }
}
</script>

<style>
#app {
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    height: 100%;
    user-select: none;
}
/*html{
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(1);
}*/
</style>
