<template>
    <div>
        <div id="container" style="background-color: #f5f5f5;">
            <div style="height: 15.5vmin">
                <header class="ql-header">
                <div class="ql-more">
                    <div class="ql-x2">
                        <img src="@/static/images/ql_logo.png" class="ql-logo" @click="$router.push('/mobile/home')"/>
                    </div>
                    <div class="ql-title">
                        {{ $route.meta.title }}
                    </div>
                    <div @click="unfold" class="ql-x" v-show="isShowIcon">
                        <i class="el-icon-minus ql-i"/>
                    </div>
                    <div @click="unfold" class="ql-x" v-show="!isShowIcon">
                        <i class="el-icon-close ql-i"/>
                    </div>
                </div>
                <el-collapse-transition>
                    <div v-show="!isShowIcon">
                        <div class="ql-collapse-transition">
                            <div v-for="(item,index) in menu" :key="index" @click="forward(item.path)" class="py-4">
                                <van-icon :name="item.icon" />
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </el-collapse-transition>
            </header>
            </div>
            <div id="main">
                <div>
                    <router-view/>
                </div>
            </div>
            <!--        <div id="footer">
                        <van-tabbar route v-model="active" active-color="#667eea" inactive-color="#4a5568" fixed placeholder>
                            <van-tabbar-item v-for="(item,index) in menu" :key="index" :icon="item.icon" :to="item.path">
                              <span>{{ item.text }}</span>
                            </van-tabbar-item>
                        </van-tabbar>
                    </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "mobileIndex",
    data() {
        return {
            active: 0,
            icon: {
                active: 'wap-home-o',
                inactive: 'wap-home',
            },
            menu: [
                {text: '搜索', path: '/mobile/result', icon: 'search'},
                {text: '首页', path: '/mobile/home', icon: 'wap-home'},
                {text: '游戏', path: '/mobile/game', icon: 'graphic'},
                {text: '福利', path: '/mobile/weifare', icon: 'gift'},
                {text: '我的', path: '/mobile/account', icon: 'manager'},
            ],
            isShowIcon: true,
        };
    },
    methods: {
        unfold() {
            if (this.isShowIcon) {
                this.isShowIcon = false;
                document.body.style.overflowY = "hidden"
            } else {
                this.isShowIcon = true;
                document.body.style.overflowY = "scroll"
            }
        },
        forward(path) {
            this.isShowIcon = true;
            document.body.style.overflowY = "scroll"
            this.$router.push(path);
        },
    }
}
</script>

<style scoped>

</style>
<style>
.ql-header {
    @apply bg-gradient-to-tl from-orange-500 to-orange-400 w-screen fixed left-0 top-0 z-50;
    height: 15.5vmin;
}

.ql-header:after {
    @apply clear-both block hidden;
    content: "*";
}

.ql-more {
    /*width: 15.5vmin;*/
    width: 100vw;
    height: 15.5vmin;
    display: grid;
    grid-template-rows: 15.5vmin;
    grid-template-columns:15.5vw 69vw 15.5vw;
}

.ql-i {
    height: 15.5vmin !important;
    line-height: 15.5vmin !important;
}

.ql-x {
    width: 15.5vw;
    height: 15.5vmin;
    /*background-color: white;*/
    line-height: 15.5vmin;
    @apply text-4xl text-white md:text-7xl text-center float-right;
    line-height: 15.5vmin;
    grid-row: 1/2;
    grid-column: 3/4;
}

.ql-x2 {
    width: calc(100vw - 15.5vmin);
    height: 15.5vmin;
    grid-row: 1/2;
    grid-column: 1/2;
    /*text-align: center;*/
    line-height: 15vmin;
    padding-left: 10px;
}

.ql-title{
    height: 15.5vmin;
    line-height: 15.5vmin;
    text-align: center;
    color: white;
    grid-row: 1/2;
    grid-column: 2/3;
}

.ql-logo {
    width: 12vmin;
    height: 12vmin;
    @apply inline-block;
}

.ql-collapse-transition {
    @apply bg-black opacity-90 relative left-0 top-0;
    @apply divide-y divide-gray-200;
    width: 100vw;
    height: 100vh;
    /*top: 15.5vmin;*/
}

.ql-collapse-transition div {
    @apply /*border-b-1 border-solid border-gray-100*/
    border-opacity-75 text-gray-200;
    @apply mx-10 py-2;
    font-size: 16px;
}
</style>